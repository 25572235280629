.s-activity {

  &__body {
    width: 100%;
    max-width: 1130px;
    margin: 0 auto;
  }



  &__inner {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: left;
    position: relative;
  }


  &__area {
    text-align: left;
    color: $color_white;
    list-style: none;
    display: inline-block;
    margin-top: 4rem;

    li {
      padding: 0 .5rem;
      display: inline-block;
      //font-size: 0.9rem;
      font-size: 0.8rem;
      font-weight: normal;
      margin-bottom: 0;
      //margin-bottom: 0.5rem;
      //margin-right: .5rem;
      margin-right: .2rem;
      //border-width: 1px;
      //border-style: solid;
    }
  }

  &__genre {
    text-align: left;
    color: $color_text_black;
    list-style: none;
    display: inline-block;

    li {
      //background-color: $color_bg_black;
      //margin-right: .5rem;
      //margin-bottom: .5rem;
      background-color: $color_white;
      margin-right: .2rem;
      margin-bottom: 0;
      display: inline-block;
      font-size: 0.8rem;
      font-weight: normal;
      border: 1px solid gainsboro;
      border-top-right-radius: 10px;

      @include mq-up(md) {
        margin-bottom: 0;
      }

      a {
        padding: 0 .5rem;
        color: $color_text_black;
      }
    }
  }

  &__title {
    text-align: left;
    margin: 1rem 0;
  }

  &__tag {
    text-align: left;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      font-size: .8rem;
      font-weight: normal;
      margin-right: .5rem;
      //margin-right: 1rem;

    }
  }

  &__pics {

    position: relative;
    padding: 0 3rem;
    margin-top: 30px;
    margin-bottom: 2rem;

    @include mq-up(md) {
      padding: 0 5rem;
      margin-bottom: 105px;
      margin-top: 50px;
    }

    &--swiper {
      //width: calc(100% - 100px);

      box-sizing: content-box;
      margin: 0 58px;
      overflow: hidden;
      position: relative;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (646 / 1148) * 100%;
      }

      .swiper-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
      }

      .swiper-slide {
        text-align: center;
        width: auto;
        max-width: calc(100% - 40px - 20px);
        @include mq-up(md) {
          max-width: calc(100% - 80px - 40px);
        }

        img {
          //max-width: 100%;
          width: auto;
          //width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .swiper-pagination-bullets {

      bottom: -28px!important;

      @include mq-up(md) {
        bottom: -50px!important;
      }

      .swiper-pagination-bullet {
        background-color: $color_white;
        opacity: 1;
        border: 1px solid #787878;
      }
      .swiper-pagination-bullet-active {
        background-color: #15b7ff;
        border: none;
      }

    }


    .swiper-button-prev,
    .swiper-button-next {
      height: 30px;
      width: 30px;
      margin-top: 0;

      @include mq-up(md) {
        height: 50px;
        width: 50px;
      }
    }
    /* 前へ次への矢印カスタマイズ */
    .swiper-button-prev,
    .swiper-button-next {

      top: 50%;
      transform: translateY(-50%);

      &:after {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        margin: auto;
        height: 30px;
        width: 30px;

        @include mq-up(md) {
          height: 50px;
          width: 50px;
        }
      }
    }
    .swiper-button-disabled {
      visibility: hidden;
    }

    /* 前への矢印カスタマイズ */
    .swiper-button-prev:after {
      background-image: url('/images/arrow_gray.png');
    }
    /* 次への矢印カスタマイズ */
    .swiper-button-next:after {
      background-image: url('/images/arrow_gray.png');
      transform: scaleX(-1);
    }

  }

  &__list {
    background-color: $color_white;
    //border-radius: 20px;
    position: relative;

    @include mq-down(md) {
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }

    &--inner {
      //border-radius: 20px;
      //padding:  1rem 1rem;
      padding:  1rem 0rem;
      //border: 1px solid gray;
      width: 100%;
      margin: 0 auto;

      @include mq-up(md) {
        //padding: 3rem 5rem;
        padding: 3rem 0rem;
        max-width: 850px;
      }
    }

    dl {
      border-top: 1px solid #CDCDCD;
      //border-bottom: 1px solid gray;

      //padding: 1rem 0;
      //@include mq-up(md) {
      //  padding: 2rem 0;
     // }

      &:last-child() {
        //border-bottom: none;
        border-bottom: 1px solid #CDCDCD;
      }

      /*
      @include mq-down(md) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
      }
      */

      @include mq-up(md) {
        display: flex;
      }
    }

    dt {
      font-weight: bold;
      background-color: #E6E6E6;

      padding: 1rem;
      @include mq-up(md) {
        padding: 30px 0;
      }

      @include mq-up(md) {
        //position: absolute;
        font-weight: normal;
        width: 185px;
        padding-left: 40px;
        flex-shrink: 0;
      }
    }

    dd {
      position: relative;
      text-align: left;
      min-height: 1rem;

      padding: 1rem;
      @include mq-up(md) {
        padding: 30px 0;
      }
      @include mq-up(md) {
        //margin-left: 8rem;
        margin-left: 40px;
        margin-right: 40px;
        width: calc(100% - 185px);
      }
    }

  }


  /*
  &__body {

    position: relative;
    margin: 2rem;
    padding-bottom: 4rem;

    @include mq-up('md') {
      width: 780px;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }
  */


  &__title {
    @include font-size(24);
    font-weight: bold;
    //font size 24
    // font-weight 700
  }

  &__detail {
    //margin-top: 2rem;
    //margin-bottom: 2rem;
    position: relative;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 850px;

    &--inner {
      display: inline-block;
      text-align: left;

      /*
      line-height: 1.7;

      address,
      blockquote,
      dl,
      fieldset,
      figure,
      h1, h2, h3, h4, h5, h6, hr, ol, p, pre, table, ul {
        margin: 1rem 0;
      }
      */

      h1, h2, h3, h4, h5, h6 {
        &:not(:first-child) {
          margin-top: 2rem;
        }
      }

    }
  }

  /*
  &__date {
    text-align: right;
  }
  */

  /*
  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
  }

  &__previous {
    position: absolute;
    bottom: .2rem;
    left: 1rem;
    @include mq-up('md') {
      left: 0;
    }
  }

  &__next {
    position: absolute;
    bottom: .2rem;
    right: 1rem;
    @include mq-up('md') {
      right: 0;
    }
  }
  */

  &__link {
    text-align: center;
    margin: 4rem 0;

    a {
      position: relative;
      z-index: 0;
      display: inline-block;
      color: $color_text_black;

      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $color_bg_black;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        z-index: -1;
      }

      span {
        position: relative;
        z-index: 2;
        display: block;
        padding: 1rem 3rem;
        background-color: $color_white;
        border-radius: 35px;
        border: 2px solid $color_bg_black;
        transition: all 0.2s ease;

        &:hover {
          color: $color_text_white;
          background-color: #505050;
          border-color: #505050;
          transform: translate(5px, 5px);
        }
      }

    }
  }


  /* 周辺で人気のアクティビティ*/
  &__related {
    margin-top: 60px;
    padding-top: 60px;
    text-align: center;
    border-top: 1px solid #322e2e;

    h3 {
      font-weight: bold;
      @include font-size(20)
    }

  }

}