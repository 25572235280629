.s-snow-lives {

  .l-main__body {
    position: relative;
    //background-image: url(/images/guide_bg.jpg);
    //background-repeat: repeat;
    overflow: hidden;
    margin-top: 0;
    padding-top: 1rem;

    @include mq-up(sm) {
      padding-bottom: 0;
    }
  }

  &__logo {

    position: absolute;

    @include mq-up(sm) {
      position: fixed;
      top: 1rem;
      left: 1rem;
      /* filter: drop-shadow(3px 3px 1px gainsboro); */
    }

    img {
      height: auto;
      max-width: 100%;
      vertical-align: bottom;
      width: 110px;
      height: 75px;
    }

  }

  &__inner {
    width: 100%;
    //max-width: 1252px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;

    h1 {
      position: relative;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 2rem;

      font-weight: 400;
      display: inline-block;
      margin-top: 1rem;
      font-family: 'Anton', sans-serif;

      @include mq-down(sm) {
        margin-top: 100px;
      }

      &::before {
        @include mq-up(md) {
          content: '';
          position: absolute;
          top: -9px;
          display: inline-block;
          background-image: url('/images/snow-live_left.png');
          background-size: contain;
          //height: 74px;
          height: 60px;
          width: 130px;
          left: -136px;
        }

        @include mq-up(lg) {
          height: 60px;
          width: 196px;
          left: -172px;
        }

      }

      &::after {
        @include mq-up(md) {
          content: '';
          position: absolute;
          top: -6px;
          display: inline-block;
          background-image: url('/images/snow-live_right.png');
          background-size: contain;
          //height: 72px;
          height: 60px;
          width: 120px;
          right: -131px;
        }

        @include mq-up(lg) {
          height: 60px;
          width: 164px;
          right: -182px;
        }
      }

    }
  }

  &__intro {

  }

  &__list {

    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: left;
    //justify-content: space-between;
    justify-content: center;

    li {
      width: 100%;
      //margin-top: 90px;

      text-align: left;
      //flex-basis: calc(100%);

      // 追加
      //height: calc(50% - 1rem);
      //background-color: aqua;

      @include mq-up(sm) {
        //flex-basis: calc(100% / 2 - 0.5rem);
        max-width: calc(100% / 2 - 0.5rem);
        min-width: calc(100% / 3 + 1px);
        width: auto;
        //display: flex;
        //flex-direction: column;

        //background-color: purple;
        //height: 100%;
        //max-height: 20vh;
      }

      @include mq-up(md) {
        //flex-basis: calc(100% / 3 - 0.5rem);
        max-width: calc(100% / 3 - 0.5rem);
        min-width: calc(100% / 4 + 1px);
        width: auto;
      }

      margin-bottom: 1rem;
      position: relative;
      transition: all 0.3s linear;
      border: solid 1px #333;
      border-radius: 5px;
      box-shadow: 5px 5px 3px gainsboro;
      padding: 1rem 0.5rem;

      /*
      &:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none;
      }
      */

      /*
      >a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
      }
      */


      h2 {
        //margin: 30px 0;
        margin-bottom: 0.5rem;
        color: $color_black;
      }

      div {
        text-align: left;
      }


    }
  }

  &__detail {
    gap: 0;
    margin: 0;
    align-items: normal!important;

    display: flex;

    position: relative;
    width: 100%;
    height: calc(100% - 2rem);

    &--pic {
      overflow: hidden;
      position: relative;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
      }

      figure {
        margin: 0;
        position: relative;
        max-width: 100%;
        max-height: 100%;

        >img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-height: 100%;
          max-width: 100%;
          object-fit: cover;
        }
      }


    }

    &--iframe {
        position: relative;
        overflow: hidden;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          z-index: 2;

          >figure {

            margin: 0;
            position: relative;
            max-width: 100%;
            max-height: 100%;

            >img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              max-height: 100%;
              max-width: 100%;
              object-fit: cover;
              object-position: top;
            }
          }
        }

        .fancybox-iframe {
          padding-top: 1rem;
        }
    }





  }


  .underconstruction {

    position: relative;

    //position: absolute;
    //top: 0;
    //left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans JP";
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;

/*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */

  }
  .underconstruction:before {
      content: '';
      background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
      background-size: contain;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 150px;
      background-position: center;
      opacity: 0.1;
  }


  .l-footer {
    padding-bottom: 0;
  }

  .p-footer__main {

    @include mq-up(sm) {
      padding: 0;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 50px;
      //background-color: white;
      z-index: 0;

      &:before {
        top: 0;
        @include mq-up(md) {
          top: -50px;
        }
      }
    }
  }


  .p-footer__copyright,
  .p-footer__main--menu,
  .p-footer__main--logo
  {
    display: none;
  }

}


