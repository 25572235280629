.l-footer {
  text-align: center;
  position: relative;
  background-color: #1e1e1e;
  color: $color_text_white;
  padding-bottom: 55px;

  @include mq-down(md) {
    display: block;
    padding-bottom: 30px;
  }
}
