.s-columns {

  &__body {
    width: 100%;
    max-width: 1130px;
    margin: 0 auto;
  }


  &__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    h1 {
      position: relative;

      @include mq-up(md) {
        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/column_left.png');
          width: 232px;
          height: 88px;
          left: -239px;
          bottom: 31px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/column_right.png');
          width: 240px;
          height: 93px;
          right: -247px;
          bottom: 29px;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    margin: 0 auto;
    //justify-content: space-between;
    margin-top: 60px;

    li {
      //width: calc((100% / 3) - 10px);
      width: 100%;
      margin-bottom: 2rem;
      text-align: left;

      @include mq-up(sm) {
        width: calc((100% - 20px) / 2);
        margin-left: 20px;

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }

      @include mq-up(md) {
        width: calc((100% - 40px) / 3);
        margin-left: 20px;

        &:nth-child(2n + 1) {
          margin-left: 20px;
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }

      a {
        color: $color_text_black;

        h2 {
          font-size: 1rem;
          font-weight: normal;
        }

        p {
          font-size: .9rem;
          font-weight: normal;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      figure {
        position: relative;
        width: 100%;

        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (734 / 850) * 100%;
        }

        img {
          //width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

    }
  }
}