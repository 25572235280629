.s-privacy {

  &__body {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 12rem;
  }

  &__inner {
    width: 100%;
    position: relative;
    text-align: center;
    word-wrap: break-word;
  }

  &__detail {
    margin-top: 60px;
    text-align: left;

    h2:nth-child(n + 1) {
      margin-top: 2rem;
    }
  }
}