.p-footer {



  &__main {

    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      padding-top: 52px;
      top: -50px;
      left: 0;
      background-image: url('/images/footer_top.png');
      background-repeat: repeat-x;
      background-size: contain;
      background-position-x: center;
      background-position-y: bottom;

      @include mq-up(md) {
        //background-size: cover;
        //padding-top: 85px;
        //top: -80px;
        //background-position-y: top;
        //background-size: 2000px auto;
        padding-top: 125px;
        top: -120px;

      }

    }

    background-color: #1E1E1E;

    /*
    background-image: url('/images/footer.png');
    background-repeat: no-repeat;
    background-size: cover;
    */

    .s-home & {
      //margin-top: -170px;
    }

    color: $color_white;

    display: flex;
    //flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1rem;
    padding-bottom: 30px;

    @include mq-up(md) {
      flex-direction: row-reverse;
      align-items: center;
      padding: 4rem 45px;
      padding-bottom: calc(90px - 55px - 24px);
    }

    @include mq-up(lg) {
      padding: 4rem 97px;
    }

    &--menu {
      ul {
        display: flex;
        justify-content: center;
        list-style: none;
        flex-wrap: wrap;
        max-width: 600px;

        @include mq-up(md) {
          justify-content: flex-end;
        }

        li {
          margin: 5px;



          @include mq-up(md) {
            //margin: 5px 35px;
            margin: 0.2rem 0.5rem;
            margin-right: 0;

            &:first-of-type() {
              margin-left: 0;
            }
          }

          a {
            color: $color_white;
            display: block;
            padding: 0.5rem;

            @include mq-up(md) {
              padding: 0.5rem 1rem;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    &--logo {
      margin-top: 2rem;
      text-align: center;

      @include mq-up(md) {
        margin-top: 0;
        text-align: left;
        min-width: 380px;
      }

      img {
        max-width: 300px;
      }

      address {
        margin-top: 32px;
        text-align: center;
        font-size: 0.9rem;

        @include mq-up(md) {
          font-size: 1rem;
          text-align: left;
        }

        >span {
          text-align: left;
          display: flex;
          justify-content: center;

          @include mq-up(md) {
            display: block;
          }
        }
      }
    }
  }



  /* トップへ戻る */
  &__pagetop {
    z-index: 2;
    position: absolute;
    //bottom: 30px;
    margin-top: -125px;
    right: 1rem;

    @include mq-up(md) {
      margin-top: -230px;
      right: 47px;
    }

    span {
      width: 50px;
      //height: 95px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq-up(md) {
        width: 74px;
        height: 95px;
      }

      img {
        //width: 50px;
        //padding: 5px;
        //background-color: $color_white;
      }

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    };
  }


  /* フッターメイン */
  /*
  &__main {


    >div {
      width: 100%;
      margin: 0 auto;
      padding: 2rem;

      @include mq-up('md') {
        width: $box-width_md;
        display: flex;
        justify-content: space-between;
        padding: 2rem 0;
        white-space: nowrap;
      }

      >img {
        object-fit: contain;
        width: 125px;
        object-position: bottom;

        @include mq-down('md') {
          display: block;
          margin: 0 auto;
          margin-top: 2rem;
        }
      }
    }
  }
  */

  &__copyright {
    position: relative;
    display: inline-block;
    //position: absolute;
    //bottom: 40px;
    text-align: center;
    //width: 100%;
    color: $color_text_white;
    //background-color: #1E1E1E;
    @include font-size(13);

    @include mq-up(md) {
      @include font-size(14);
    }
  }

}
