.c-cursor {



  &__follower {

    border-radius: 50%;
    position: absolute; /*bodyの一番左上になるように配置する*/
    top: 0;
    left: 0;
    pointer-events: none; /*他の要素がクリックできなくならないようにする*/
    opacity: 0.8;

    display: flex;
    justify-content: center;
    align-items: center;
    //width: 0px;            /*ストーカーの大きさを0にする*/
    //height: 0px;           /*ストーカーの大きさを0にする*/
    //background-color: rgba(253, 254, 0, 0.5);
    z-index: 1000;        /*カーソル要素よりも一つ下になるようにする*/
    //transition: transform ease 0.1s;
    //transition: transform ease 0.5s;
    transition: transform ease-out 0.2s;
    text-align: center;

    width: 100px;
    height: 100px;

    @include mq-down(md) {
      display: none;
    }
  }
}