.s-guides {

  .l-main__body {
    position: relative;
    background-image: url(/images/guide_bg.jpg);
    background-repeat: repeat;
    overflow: hidden;


    &::before,
    &::after {
      content: '';
      background-image: url('/images/guide_bg_obj.png');
      background-size: contain;
      background-repeat: repeat-y;
      top: 0;
      width: calc(525px * 0.5);
      height: 100%;
      position: absolute;
      display: none;
      opacity: 0.5;

      @include mq-up(sm) {
        display: block;
      }

      @include mq-up(md) {
        width: 525px;
      }
    }

    &::after {
      background-position: 0 calc(40px * 0.5);
      left: calc(-100px * 0.5);

      @include mq-up(md) {
        background-position: 0 40px;
        left: -100px;
      }
    }

    &::before {
      background-position: 0 calc(292px * 0.5);
      right: calc(-100px * 0.5);

      @include mq-up(md) {
        background-position: 0 292px;
        right: -100px;
      }
    }
  }

  /*
  &__bg {
    background-image: url('/images/guide_bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: repeat-y;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  */

  &__inner {
    width: 100%;
    max-width: 1130px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;

    h1 {
      position: relative;

      @include mq-up(md) {
        //border-top: 8px solid $color_text_black;

        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/guide_left.png');
          width: 242px;
          height: 94px;
          left: -247px;
          bottom: 28px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/guide_right.png');
          width: 191px;
          height: 107px;
          right: -197px;
          bottom: 27px;
        }
      }
    }
  }



  &__list {

    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: -2rem;
    text-align: left;
    //justify-content: space-between;

    li {
      width: 100%;
      //display: flex;
      margin-top: 90px;
      //margin-bottom: 2rem;
      //padding: 0 1rem;
      text-align: center;


      @include mq-up(sm) {
        //width: calc((100% - 30px) / 2);
        width: calc((100% - 30px) / 2);
        margin-left: 30px;

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }

      @include mq-up(md) {
        //width: calc((100% - 60px) / 3);
        //margin-left: 30px;
        width: calc((100% - (138px * 2)) / 3);
        margin-left: 138px;

        &:nth-child(2n + 1) {
            margin-left: 138px;
        }

        // 左側
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }

      /*
      >img {
        width: 100%;
        background-color: gray;
        //border-radius: 100%;
      }
      */

      h2 {
        //margin: 30px 0;
        //margin-bottom: 16px;
        display: inline;

        @include font-size(24);
        font-weight: bold;

        &::before {
          content: '';
          padding-top: 30px;
          display: block;
        }

        &::after {
          content: '';
          padding-bottom: 16px;
          display: block;
        }


      }

      div {
        text-align: left;
      }

      a {

        &:hover {
          opacity: 0.7;
        }

        h2 {
            color: $color_black;
            border-bottom: 1px solid $color_black;
        }
      }


    }
  }
  /*
  &__intro {
    text-align: left;
    margin: 2rem 0;
    padding: 0 1rem;

    @include mq-up(md) {
      text-align: center;
      padding: 0;
    }
  }
  */


  &__img {
    width: 100%;
    max-width: 337px;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-size: cover;

    &:before {
      display: block;
      content: '';
      padding-top: 100%;
    }

    &.c-noimage {
      position: relative;
      width: auto;
      height: auto;
    }
  }

  &__license {
    @include font-size(16);
    text-align: center;
    margin-bottom: 1rem;
  }

  &__message {
    @include font-size(14);
  }
}


