.s-goods {

  &__body {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
  }

  &__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;


    h1 {
      position: relative;
    }

    >section {
      margin-top: 60px;
      //margin-bottom: 60px;
      margin-bottom: calc(140px - 127px);

      >h2 {
        margin-bottom: 46px;
        @include font-size(24);
        font-weight: bold;
        display: inline-block;
        text-align: left;
      }
    }
  }


  &__intro {

    margin-bottom: 50px;

    @include mq-up(md) {
      margin-bottom: calc(140px - 25px);
    }

    dt {
      border: 1px solid $color_black;
      padding: .3rem 1rem;
      margin-bottom: 1rem;
      display: inline-block;
      font-weight: bold;
      @include font-size(18);
    }

    dd {
      margin-bottom: 25px;
      display: inline-block;
      text-align: left;

      @include mq-up(md) {
        display: block;
        text-align: center;
      }
    }

  }


  &__list {
    position: relative;
    list-style: none;
    width: 100%;

    @include mq-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      /* 1商品のみ */
      &.single_item {
        justify-content: center;

        >li {
          width: 100%;
          max-width: 500px;

          figure {
            width: 100%;
          }
        }
      }

    }

    &--li {
      position: relative;
      //display: flex;
      //justify-content: center;
      //align-items: center;

      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid #000;

      @include mq-up(md) {
        width: 50%;
        //padding: 50px;
        padding-top: 0;
        //margin-bottom: 50px;
        border-bottom: none;
        padding-bottom: calc(127px / 2);
        margin-bottom: calc(127px / 2);


        // 右側
        &:nth-child(even) {

          padding-left: 0;

          >div {
            border-left: 1px solid #000;
            //padding-left: 50px;
            text-align: right;
          }
        }

        /* 下線 */
        &:nth-child(even):not(:last-of-type) {
          &::before{
            content: '';
            position: absolute;
            bottom: 0;
            left: -100%;
            width: 200%;
            border-bottom: 1px solid #000;
          }
        }
      }
    }

    &--inner {
      //display: flex;
      //justify-content: center;
      //align-items: center;
      height: 100%;
      width: 100%;
      text-align: center;
      @include mq-up(md) {
        text-align: left;
      }

      /* title price detail*/
      >div {
        width: 100%;
        max-width: 500px;
        text-align: left;
        display: inline-block;

        @include mq-up(md) {
          width: calc(100% - 1rem);
        }
      }

    }

    &--pic {
      width: 100%;
      max-width: 500px;
      position: relative;
      display: inline-block;

      @include mq-up(md) {
        width: calc(100% - 1rem);
      }

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (500 / 500) * 100%;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      .c-noimage {
        //position: relative;
        //height: 298px;
      }
    }

    &--title {
      font-weight: bold;
      display: inline-block;
      margin-right: 1rem;
      @include font-size(18);

      @include mq-up(md) {
        @include font-size(20);
      }
    }

    &--price {
      font-weight: bold;
      display: inline-block;
      @include font-size(18);

      @include mq-up(md) {
        @include font-size(20);
      }
    }

    &--detail {
      margin-bottom: 1rem;
    }

    &--button {
      text-align: center;
    }


  }

  /*
  &__list {

    width: 100%;
    //max-width: 780px;
    margin: 0 auto;
    list-style: none;
    padding: 0 1rem;
    background-color: #f0f0f0;
    padding-top: 60px;
    padding-bottom: 60px;

    @include mq-up(md) {
      //display: flex;
      list-style: none;

      padding-top: 140px;
      padding-bottom: 140px;
    }

    li {
      width: 100%;
      max-width: 1105px;
      margin: 0 auto;
      margin-bottom: 4rem;
      //padding: 1rem;
      display: block;
      text-align: center;

      &:last-of-type() {
        margin-bottom: 0;
      }

      @include mq-up(md) {
        display: flex;
        text-align: left;
        margin-bottom: 2rem;

        &:last-of-type() {
          margin-bottom: 0;
        }
      }

      >figure {
        position: relative;
        width: 100%;

        @include mq-up(md) {
          width: 30%;
        }

        &:before {
          display: block;
          content: "";
          width: 100%;
          //padding-top: (279 / 337) * 100%;
          padding-top: (313 / 426) * 100%;
        }
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      >div {
        text-align: left;
        width: 100%;

        @include mq-up(md) {
          width: 70%;
        }

        p {
          font-weight: bold;
          @include font-size(18);
          margin-bottom: 1rem;

          @include mq-up(md) {
            @include font-size(22);
          }
        }


        @include mq-up(md) {
          padding-left: 2rem;
          font-weight: bold;
        }
      }
    }
  }

  */



}