.s-ticket {

  &__body {
    width: 100%;
    max-width: 850px;
    text-align: center;
    margin: 0 auto;
  }


  &__inner {
    width: 100%;
    //max-width: 780px;
    //margin: 0 auto;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 5rem;
    text-align: left;
  }

  &__title {
    @include font-size(24);
    font-weight: bold;
    margin: 2rem 0;
    margin-top: 60px;
  }

  &__thumb {
    text-align: center;
  }

  &__detail {
    /*
    line-height: 1.7;

    address, blockquote,
    dl, fieldset, figure,
    h1, h2, h3, h4, h5, h6,
    hr, ol, p, pre, table, ul {
      margin: 1rem 0;
    }
    */

    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }
}