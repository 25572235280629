.s-snow-lives_taihi {

  .l-main__body {
    position: relative;
    //background-image: url(/images/guide_bg.jpg);
    //background-repeat: repeat;
    overflow: hidden;
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  &__logo {
    position: fixed;
    top: 1rem;
    left: 1rem;
    /* filter: drop-shadow(3px 3px 1px gainsboro); */

    img {
      height: auto;
      max-width: 100%;
      vertical-align: bottom;
      width: 110px;
      height: 75px;
    }

  }

  &__inner {
    width: 100%;
    //max-width: 1252px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;

    h1 {
      position: relative;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 2rem;

      //font-weight: 400;
      //display: inline-block;
      //margin-top: 1rem;
      //font-family: 'Anton', sans-serif;

      &::before {
        @include mq-up(md) {
          content: '';
          position: absolute;
          top: -9px;
          display: inline-block;
          background-image: url('/images/snow-live_left.png');
          background-size: contain;
          //height: 74px;
          height: 60px;
          width: 196px;
          left: -172px;
        }
      }

      &::after {
        @include mq-up(md) {
          content: '';
          position: absolute;
          top: -6px;
          display: inline-block;
          background-image: url('/images/snow-live_right.png');
          background-size: contain;
          //height: 72px;
          height: 60px;
          width: 164px;
          right: -182px;
        }
      }

    }
  }

  &__intro {

  }

  &__list {

    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 1rem;
    text-align: left;
    justify-content: space-between;

    li {
      width: 100%;
      //margin-top: 90px;

      text-align: left;
      flex-basis: calc(100%);

      @include mq-up(sm) {
        flex-basis: calc(100% / 2 - 0.5rem);
        display: flex;
        flex-direction: column;

        background-color: purple;
        //height: 100%;
        //max-height: 20vh;
      }

      @include mq-up(md) {
        flex-basis: calc(100% / 3 - 0.5rem);
      }

      @include mq-up(xl) {
        //max-height: 30vh;
        height: 30vh;
      }

      margin-bottom: 1rem;
      position: relative;
      transition: all 0.3s linear;
      border: solid 1px #333;
      border-radius: 5px;
      box-shadow: 5px 5px 3px gainsboro;
      padding: 1rem 0.5rem;

      /*
      &:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none;
      }
      */

      /*
      >a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
      }
      */


      h2 {
        //margin: 30px 0;
        margin-bottom: 0.5rem;
        color: $color_black;
      }

      div {
        text-align: left;
      }


    }
  }

  &__detail {
    display: flex;
    gap: 0;
    margin: 0;
    align-items: normal!important;
    //height: calc(100% - 3vw);

    height: 100%;
    overflow: hidden;

    &--pic {
      flex-basis: 55%;
      overflow: hidden;
      position: relative;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      >figure {
        height: 100%;
        margin: 0;


        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          //max-height: 100%;
          //height: 20vh;
        }
      }


    }

    &--iframe {
        flex-basis: 45%;
        position: relative;
        overflow: hidden;
        &:hover {
          opacity: 0.7;
        }

        >div {
          //padding-top:calc(100% - 2vw);
          //padding-top:calc(20vh - 1rem);
          //position: relative;
         // height: 100%;

          position: relative;
          width: 100%;
          padding: calc(315 / 560 * 100%) 0 0;
        }

        iframe {
          border: none;
          width: calc(100% + 20px);
          //height: 100%;
          position: absolute;
          //position: relative;
          top: 0;
          left: -10px;

          //max-height: 100%;
          //height: 20vh;

          height: calc(100% + 42px);

          &:after {
            content: '';
            position: absolute;
            top: 0;
          }


        }

        a {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }

        .fancybox-iframe {
          padding-top: 1rem;
        }
    }





  }


  .underconstruction {

    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans JP";
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;

/*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */

  }
  .underconstruction:before {
      content: '';
      background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
      background-size: contain;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 150px;
      background-position: center;
      opacity: 0.1;
  }


  .l-footer {
    padding-bottom: 0;
  }

  .p-footer__main {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    //background-color: white;
    z-index: 0;

    &:before {
      top: 0;
      @include mq-up(md) {
        top: -50px;
      }
    }
  }


  .p-footer__copyright,
  .p-footer__main--menu,
  .p-footer__main--logo
  {
    display: none;
  }

}


