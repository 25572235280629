.s-area {

  .l-main__body {
    position: relative;
    background-image: url(/images/guide_bg.jpg);
    background-repeat: repeat;
  }

  &__body {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
  }

  &__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  &__text {
    margin-bottom: 1rem;
  }

  &__intro {
    display: inline-block;
    text-align: left;
    margin: 0;
    margin-bottom: 60px;
    max-width: 940px;
  }

  /*
  &__search {
    margin: 2rem auto;
    text-align: center;

    select {
      background-color: $color_white;
      color: $color_black;
    }
  }
  */

  &__map {
    //margin-top: 60px;
    width: 100%;
    position: relative;
    display: none;
    /*
    background-size: contain;
    background-image: url('/images/area_top_sp.png');

    @include mq-up(md) {
      background-image: url('/images/area_top.png');
    }

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (590 / 665) * 100%;

      @include mq-up(md) {
        padding-top: (746 / 1381) * 100%;
      }
    }
    */

    &--coords {
      @include mq-down(md) {
        display: none;

        area {
          display: none;
        }
      }
    }


  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-start;
    margin-top: 30px;

    @include mq-up(md) {
      //margin-top: 60px;
      display: none;
    }

    li {
      width: calc((100% - (5px * 1)) / 2);
      margin-bottom: 5px;
      margin-left: 5px;

      /* 左端にくるもののmargin-leftを消す */
      &:nth-child(odd) {
        margin-left: 0;
      }

      @include mq-up(sm) {
        width: calc((100% - (5px * 2)) / 3);
        margin-bottom: 5px;

        /* margin-leftリセット */
        &:nth-child(odd) {
          margin-left: 5px;
        }

        /* 左端にくるもののmargin-leftを消す */
        &:nth-child(3n + 1) {
          margin-left: 0;
        }

      }

      /*
      @include mq-up(md) {
        width: calc((100% - (2rem * 3)) / 4);
      }

      @include mq-up(lg) {
        //width: calc((100% - (2rem * 7)) / 8);
      }
      */

      &:hover {
        opacity: 0.7;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        color: $color_text_black;

        padding: 11px 5px;

        div {
          margin: 0 auto;
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.6;
          display: inline-block;

          /*
          @include mq-up(md) {
            font-size: 2rem;
          }
          */

        }

        svg {
          width: .8rem;

          /*
          @include mq-up(md) {
            width: 2rem;
          }
          */
        }
      }


    }
  }


  &__center {
    list-style: none;
    //margin-top: 60px;

    > li {
      border-bottom: 1px solid gray;
      padding: 30px 0;

      @include mq-up(md) {
        padding: 125px 0;
      }

      &:last-child() {
        border-bottom: none;
        padding-bottom: 0;
      }

      h2 {
        font-weight: 900;
        @include font-size(22);
        margin-bottom: 30px;

        @include mq-up(md) {
          @include font-size(31);
          margin-bottom: 50px;
        }
      }

    }

  }

  &__pics {

    position: relative;
    padding: 0 3rem;
    margin-bottom: 25px;

    @include mq-up(md) {
      padding: 0 5rem;
      margin-bottom: 105px;
    }

    &--swiper {
      margin: 0 58px;
      overflow: hidden;

      position: relative;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (646 / 1148) * 100%;
      }

      .swiper-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
      }
      .swiper-slide {
        width: auto;
        max-width: calc(100% - 40px - 20px);
        @include mq-up(md) {
          max-width: calc(100% - 80px - 40px);
        }

        img {
          //max-width: 100%;
          width: auto;
          //width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

    }

    /*
    .swiper-pagination-bullets {

      bottom: -28px!important;

      @include mq-up(md) {
        bottom: -50px!important;
      }

      .swiper-pagination-bullet {
        background-color: $color_white;
        opacity: 1;
        border: 1px solid #787878;
      }
      .swiper-pagination-bullet-active {
        background-color: #15b7ff;
        border: none;
      }
    }
    */


    .swiper-button-prev,
    .swiper-button-next {
      height: 30px;
      width: 30px;
      margin-top: 0;

      @include mq-up(md) {
        height: 50px;
        width: 50px;
      }
    }
    /* 前へ次への矢印カスタマイズ */
    .swiper-button-prev,
    .swiper-button-next {

      top: 50%;
      transform: translateY(-50%);

      &:after {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        margin: auto;
        height: 30px;
        width: 30px;

        @include mq-up(md) {
          height: 50px;
          width: 50px;
        }
      }
    }
    .swiper-button-disabled {
      visibility: hidden;
    }

    /* 前への矢印カスタマイズ */
    .swiper-button-prev {
      left: 0;
      &::after {
        background-image: url('/images/arrow.png');
      }
    }
    /* 次への矢印カスタマイズ */
    .swiper-button-next {
      right: 0;

      &:after {
        background-image: url('/images/arrow.png');
        transform: scaleX(-1);
      }
    }

  }



  &__can {
    width: 100%;
    margin: 0 auto;
    text-align: left;

    @include mq-up(md) {
      max-width: 850px;
      margin-bottom: 25px;
    }

    dt {
      font-weight: bold;
      border-left: 2px solid #323232;
      padding-left: 0.5rem;
      color: #323232;
      @include font-size(18);
      margin-bottom: .5rem;

      @include mq-up(md) {
        border-left: 5px solid #323232;
        @include font-size(24);
        margin-bottom: 1.5rem;
      }
    }
  }

  &__sns {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;

    @include mq-up(md) {
      max-width: 850px;
      margin-bottom: 25px;
    }

    li {
      display: inline-block;
      margin-right: 1rem;
      width: 30px;

      @include mq-up(md) {
        width: auto;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__googlemap {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    @include mq-up(md) {
      max-width: 1130px;
      margin-bottom: 25px;
    }
  }

}