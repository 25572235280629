.c-label {

  /* カテゴリ表示 */
  &__category {
    width: 5rem;
    display: inline-block;
    text-align: center;

  }
}
