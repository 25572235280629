.s-guide {

  .l-main__body {
    position: relative;
    background-image: url(/images/guide_bg.jpg);
    background-repeat: repeat;
    overflow: hidden;


    &::before,
    &::after {
      content: '';
      background-image: url('/images/guide_bg_obj.png');
      background-size: contain;
      background-repeat: repeat-y;
      top: 0;
      width: calc(525px * 0.5);
      height: 100%;
      position: absolute;
      display: none;
      opacity: 0.3;

      @include mq-up(sm) {
        display: block;
      }

      @include mq-up(md) {
        width: 525px;
      }
    }

    &::after {
      background-position: 0 calc(40px * 0.5);
      left: calc(-100px * 0.5);

      @include mq-up(md) {
        background-position: 0 40px;
        left: -100px;
      }
    }

    &::before {
      background-position: 0 calc(292px * 0.5);
      right: calc(-100px * 0.5);

      @include mq-up(md) {
        background-position: 0 292px;
        right: -100px;
      }
    }
  }


  &__inner {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;
    //padding-bottom: 4rem;
    padding-bottom: 170px;

    /*
    h1 {
      position: relative;
      margin-bottom: 1rem;
    }
    */
  }

  &__top {

    display: block;
    margin: 2rem 2rem;

    @include mq-up(md) {
      display: flex;
      flex-direction: row-reverse;
      margin: 2rem 3rem;
      margin-bottom: 3.5rem;
    }


    &--img {

      max-width: 400px;
      margin: 0 auto;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      background-size: cover;
      margin-bottom: 2rem;

      &:before {
        display: block;
        content: '';
        padding-top: 100%;
      }

      @include mq-up(md) {
        width: 54%;
        margin-bottom: 1rem;
      }

      /*
      &.c-noimage {
        position: relative;
        width: auto;
        height: auto;
      }
      */
    }

    &--text {
      text-align: left;
      display: inline-block;
      margin: 0 auto;

      @include mq-up(sm) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
      }

      @include mq-up(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 0;
        //padding-left: 1rem;
        padding-right: 2rem;
        width: 46%;
      }

      >div {
        min-width: 170px;
      }
    }
    &--position {
      margin-bottom: 1rem;
      line-height: 1.4;
    }
    &--title {
      letter-spacing: 2px;
      @include font-size(32);
      font-weight: bold;
      margin-bottom: 1rem;

      @include mq-up(md) {
        @include font-size(43);
        letter-spacing: 6px;
      }
    }
    &--illust {
      width: 100%;
      //max-width: 240px;
      max-width: 185px;
      min-width: 0;

      @include mq-up(sm) {
        //max-width: 240px;
        max-width: 185px;
        margin-right: 2rem;
      }
    }
  }


  //&__topintro,
  &__content {

    position: relative;
    text-align: left;

    h1, h2, h3, h4, h5, h6 {
      font-family: 'Noto Serif JP', serif;
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    h2 {
      @include font-size(22);
      margin: 2rem 0;
      text-align: left;
      display: flex;
      justify-content: center;
    }

    /* 最初に表示する画像のmargin-topを0に */
    > figure:first-child(),
    > .wp-block-image:first-child() > figure {
      margin-top: 0rem!important;
    }

    figure,
    .wp-block-image > figure {

      margin-top: 3rem;

      @include mq-up(md) {
        margin-top: 6rem;
      }
    }

    p {
      @include mq-up(md) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
  }

  &__topintro {
    position: relative;
    text-align: left;
    margin-bottom: 3rem;
    font-family: 'Noto Serif JP', serif;
    font-weight: 600;

    h2 {
      //text-shadow: 0 0 1px gray;
      @include font-size(27);
      margin: 2rem 0;
      text-align: left;
      display: flex;
      justify-content: center;
    }

    p {
      @include font-size(18);
      font-weight: 600;
      line-height: 1.9;

      @include mq-up(md) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }

  }

  &__content {
    position: relative;
    text-align: left;
    //margin-top: 2rem;
    //margin-bottom: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 2.5rem;

    @include mq-up(md) {
      padding-top: 5rem;
      padding-bottom: 5rem;
      margin-bottom: 5rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('/images/guide_hr.png');
      background-size: contain;
      background-repeat: repeat-x;
      width: 100%;
      height: 5px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url('/images/guide_hr.png');
      background-size: contain;
      background-repeat: repeat-x;
      width: 100%;
      height: 5px;
    }

    &--tour {

      position: relative;
      margin-top: 3rem;
      padding-top: 55px;
      color: #631a00;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('/images/guide_linkhead.png');
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 60px;
      }

      a {
        color: #631a00;
        border-bottom: #631a00 solid 1px;

        @include mq-up(md) {
          letter-spacing: 5px;
        }

      }

    }

  }

  &__bottom {
    width: 100%;
    display: block;

    @include mq-up(md) {
      display: flex;
    }

    &--img {
      @include mq-up(md) {
        width: 44%;
      }

      img {
        width: 100%;
        border-top-left-radius: 70px;
      }
    }

    &--text {
      text-align: left;

      @include mq-up(md) {
        width: 56%;
        padding-left: 2rem;
      }
    }
    &--position {
      font-weight: bold;
      line-height: 1.4;
    }
    &--name {
      font-weight: bold;
      margin-bottom: 1rem;
      @include font-size(18);
      letter-spacing: 2px;
    }
    &--lisence {
      margin-bottom: 1rem;
      line-height: 1.4;
      @include font-size(14);
    }
    &--intro {
      @include font-size(14);
    }

  }

}


