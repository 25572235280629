html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  //font-size: $font-size_base + px;
  //@include font-size($font-size_base);
  //font-size: ($font-size_base - 2) + px;
  //@include mq-up('md') {
  //  font-size: $font-size_base + px;
  //}
  font-size: $font-size_base + px;
  line-height: $line-height-base;
}

body {
  /* メトリクスカーニングを指定する */
  height: 100%;
  //font-family: -apple-system, BlinkMacSystemFont, $font-family-base;
  font-family: $font-family-base;
  font-size: 400;
  color: $color_text_black;

  /* letter-spacing: .2rem; */
  background-color: $color_white;
  font-kerning: normal;

  /* アンチエイリアスを指定する */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.35;
  color: inherit;
}

ul,
ol {
  padding: 0;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

dd {
  margin-left: 0;
}

table {
  width: 100%;
}

th {
  text-align: left;
}

a {
  color: $color_link;
  text-decoration: none;
  transition: .2s;

  &:visited {
    color: $color_link--visited;
  }

  &:hover {
    color: $color_link--hover;
  }
}

/**
 * フルードイメージにします。
 */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * IEでSVGがフルードイメージにならない不具合があります。
 * 属性値が`.svg`で終わる要素に適応します。
 */
[src$='.svg'],
[data$='.svg'] {
  width: 100%;
}

/**
 * 日本語では斜体を使用しないためリセットします。
 */
i,
cite,
em,
address,
dfn {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
  margin-top: 0;
  margin-bottom: 0;
}

figure {
  margin: 0;
}
