.s-rental {

  &__image {
    width: 100%;
    //margin-left: calc((-100vw + 100%) /2);
    margin-bottom: 0;

    >img {
      width: 100%;
    }
  }

  &__body {
    //max-width: 1105px;
    width: 100%;
    margin: 0 auto;

    .breadcrumbs {
      max-width: 1130px;
    }
  }

  &__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;


    h1 {
      position: relative;

      @include mq-up(md) {
        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/rental_left.png');
          width: 261px;
          height: 103px;
          left: -276px;
          bottom: 4px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/rental_right.png');
          width: 280px;
          height: 89px;
          right: -291px;
          bottom: 14px;
        }
      }
    }
  }

  &__cycle,
  &__ski {
    margin-top: 60px;
    //width: 100vw;
    //margin-left: calc((-100vw + 100%) /2);
    //width: calc(100% + 2rem);
    //margin-left: -1rem;

    width: calc(100% + 2rem);
    margin-left: -1rem;

    h2 {
      //color: #1e9c9d;
      font-weight: 900;
      text-align: left;
      @include font-size(25);
      margin: 0 auto;
      margin-bottom: 2rem;
      padding: 0 1rem;
      max-width: calc(1130px + 2rem);

      @include mq-up(md) {
        @include font-size(30);
      }
    }
    h3 {
      //background-color: #1e9c9d;
      border-radius: 10px;
      color: $color_text_white;
      padding: 0.5rem 1rem;
      display: inline-block;
      @include font-size(18);
      margin-bottom: 1rem;

      @include mq-up(md) {
        @include font-size(22);
      }

    }
  }
  &__cycle {
    margin-bottom: 60px;

    @include mq-up(md) {
      margin-bottom: 140px;
    }

    h2 {
      color: #1e9c9d;
    }
    h3 {
      background-color: #1e9c9d;
    }
  }

  &__ski {

    h2 {
      color: #466496;
    }
    h3 {
      background-color: #466496;
    }

    ul {
      padding-bottom: 260px;
      margin-bottom: -260px;
    }
  }

  /*
  &__ski {
    h2 {
      color: #466496;
      font-weight: 900;
      text-align: left;
      margin-bottom: 2rem;
      @include mq-up(30);
    }
    h3 {
      background-color: #466496;
      border-radius: 10px;
      color: $color_text_white;
      padding: 0.5rem 1rem;
    }
  }
  */


  &__list {

    width: 100%;
    //max-width: 780px;
    margin: 0 auto;
    list-style: none;
    padding: 0 1rem;
    background-color: #f0f0f0;
    padding-top: 60px;
    padding-bottom: 60px;

    @include mq-up(md) {
      //display: flex;
      list-style: none;

      padding-top: 140px;
      padding-bottom: 140px;
    }

    li {
      width: 100%;
      max-width: 1130px;
      margin: 0 auto;
      margin-bottom: 4rem;
      //padding: 1rem;
      display: block;
      text-align: center;

      &:last-of-type() {
        margin-bottom: 0;
      }

      @include mq-up(md) {
        display: flex;
        text-align: left;
        margin-bottom: 2rem;

        &:last-of-type() {
          margin-bottom: 0;
        }
      }

      >figure {
        position: relative;
        width: 100%;

        @include mq-up(md) {
          width: 30%;
        }

        &:before {
          display: block;
          content: "";
          width: 100%;
          //padding-top: (313 / 426) * 100%;
        }
        img {
          object-fit: contain;
          width: 100%;

          /*
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          */
        }
      }

      >div {
        text-align: left;
        width: 100%;

        @include mq-up(md) {
          width: 70%;
        }

        p {
          font-weight: bold;
          @include font-size(18);
          margin-bottom: 1rem;

          @include mq-up(md) {
            @include font-size(22);
          }
        }


        @include mq-up(md) {
          padding-left: 2rem;
          font-weight: bold;
        }
      }
    }
  }
  /*
  &__intro {
    text-align: left;
    margin: 2rem 0;
    padding: 0 1rem;

    @include mq-up(md) {
      text-align: center;
      padding: 0;
    }
  }
  */




}