.u-mt0 {
  margin-top: 0!important;
}

.u-mt05 {
  margin-top: .5rem!important;
}

.u-mt1 {
  margin-top: 1rem!important;
}

.u-mt2 {
  margin-top: 2rem!important;
}

.u-mt3 {
  margin-top: 3rem!important;
}

.u-mt4 {
  margin-top: 4rem!important;
}

.u-mt5 {
  margin-top: 5rem!important;
}

.u-mr0 {
  margin-right: 0!important;
}

.u-mr05 {
  margin-right: .5rem!important;
}

.u-mr1 {
  margin-right: 1rem!important;
}

.u-mr2 {
  margin-right: 2rem!important;
}

.u-mr3 {
  margin-right: 3rem!important;
}

.u-mb0 {
  margin-bottom: 0!important;
}

.u-mb05 {
  margin-bottom: .5rem!important;
}

.u-mb1 {
  margin-bottom: 1rem!important;
}

.u-mb2 {
  margin-bottom: 2rem!important;
}

.u-mb3 {
  margin-bottom: 3rem!important;
}

.u-mb4 {
  margin-bottom: 4rem!important;
}

.u-mb5 {
  margin-bottom: 5rem!important;
}

.u-ml0 {
  margin-left: 0!important;
}

.u-ml05 {
  margin-left: .5rem!important;
}

.u-ml1 {
  margin-left: 1rem!important;
}

.u-ml2 {
  margin-left: 2rem!important;
}

.u-ml3 {
  margin-left: 3rem!important;
}
