.s-menu {

  &__head {
    position: relative;
    height: 150px;

    @include mq-up(md) {
      height: auto;
      min-height: 360px;
    }

    >img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;

      @include mq-up(md) {
        height: auto;
        min-height: 360px;
      }

    }

    >h1 {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);

      @include mq-up(md) {
        bottom: 20px;
      }
    }
  }

  &__body {

    margin-bottom: 2rem;

    @include mq-up('md') {
      max-width: 1028px;
      margin: 4rem auto;
      /*margin-: 2rem;*/
    }
  }

  &__date {
    text-align: center;
    margin-top: 2rem;

    @include mq-up(md) {
      margin-top: 0;
    }

  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;

    >li {
      width: 100%;
      padding: 1rem;
      position: relative;

      @include mq-up(sm) {
        width: calc(100% / 2);
      }

      @include mq-up(md) {
        width: calc(100% / 3);
      }

      >a {
        display: block;
        background-color: pink;
        height: 100%;
        //padding: 1rem;
        //padding-right: 3rem;
        position: relative;
        padding-bottom: 3rem;
        text-align: center;

        &:hover {
          background-color: $color_white;
          filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.3));
        }

        >figure {

        }

        >div {
          margin-top: 1rem;
        }

      }

      &:after {
        content: '▼';
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &--eng {
      @include font-size(24);
      font-family: $font-family-ebgramond;
    }
  }


  /* fancybox data-caption */
  .fancybox-caption__body {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100%);
    min-height: 44px;
    display: flex;
    padding: 0 88px;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
  }


}