/* 文字関連のもの */

/* 子ページ上部タイトル */
%headings1 {
  display: inline-block;
  //width: 100%;
  text-align: center;
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  padding: 1rem;
  @include font-size(50);

  @include mq-up('md') {
    @include font-size(65);
  }

}


/* 見出し(黒文字) */
%headings2 {
  display: block;
  width: 100%;
  text-align: center;
  color: $color_text_black;
  font-family: 'EB Garamond', serif;
  font-weight: 600;

  @include font-size(40);
  margin: 2rem 0;

  @include mq-up('md') {
    @include font-size($font-size_h2);
  }
}



/* 見出し サブタイトル */
%headings3 {
  display: inline-block;
  padding: 0 2rem;
  color: $color_black;
  @include font-size(24);

  /* 一部の大きい文字 */
  span {
    @include font-size(40);
    vertical-align: sub;
  }

  @include mq-up('md') {

    text-align: center;
    @include font-size($font-size_h3);

    span {
      @include font-size($font-size_h3_strong)
    }

  }

}

/* 説明文 */
%intro {
  width: 100%;
  text-align: center;
  //margin-bottom: 2rem;
  @include font-size(18);
  font-weight: bold;
}

/* HOME 見出し */
%yellow {
  @include font-size(45);
  font-family: 'Anton', sans-serif;
  font-weight: normal;

  position: relative;
  display: inline-block;
  padding:0 0 0 32px;
  //line-height: 55px;
  line-height: 66px;
  outline: none;
  z-index: 0;
  margin-left: -32px;

  @include mq-down(md) {
    @include font-size(30);
    padding:0 0 0 20px;
    line-height: 42px;
    margin-left: -20px;
  }

  @include mq-up(md) {
    @include font-size(60);
  }


  &:before {
    content:'';
    position:absolute;
    left:0;
    z-index: -1;
      /*丸の形状*/
    //width:55px;
    //height:55px;
    //border-radius:27.5px;
    width: 64px;
    height: 64px;
    border-radius: calc(64px / 2);
    /*アニメーションの指定*/
    transition:.3s ease-out;

    @include mq-down(md) {
      background:#ffff00;
      width: 40px;
      height: 40px;
      border-radius: calc(40px / 2);
    }
  }

  &.c-typo__yellow--long:before {
    width: calc(100% + 32px);

    @include mq-down(md) {
      width: calc(100% + 20px);
    }
  }
}

.c-typo {
  &__headings1 {
    @extend %headings1;
  }
  /*

  &__headings2 {
    @extend %headings2;
  }

  &__headings3 {
    @extend %headings3;
  }
  */

  &__intro {
    @extend %intro;
  }

  &__yellow {
    @extend %yellow;
  }

  /* 戻るリンク */
  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
    color: $color_text_black;
  }

  /* 前へリンク */
  &__previous {
    position: absolute;
    bottom: 1px;
    left: 1rem;
    color: $color_text_black;
    @include mq-up('md') {
      left: 0;
    }
  }

  /* 次へリンク */
  &__next {
    position: absolute;
    bottom: 1px;
    right: 1rem;
    color: $color_text_black;
    @include mq-up('md') {
      right: 0;
    }
  }

  $color_asahikawa : #386990;
  $color_aibetsu : #38bdc9;
  $color_pippu : #79b453;
  $color_takasu : #c8ab45;
  $color_higashikagura : #b86fad;
  $color_tohma : #1d955c;
  $color_higashikawa : #f1a0b6;
  $color_kamikawa : #f59942;
  $color_biei : #d87e87;

  &__asahikawa {
    color: $color_asahikawa;
    &--bg {
      background-color: $color_asahikawa;
      color: $color_text_white;
      svg path {
        fill: $color_text_white;
      }
    }
    &--bg04 {
      background-color: rgba($color_asahikawa, 0.4);
    }
    &--border {
      border-color: $color_asahikawa!important;
    }
  }
  &__aibetsu {
    color: $color_aibetsu;
    &--bg {
      background-color: $color_aibetsu;
      svg path {
        fill: $color_text_black;
      }
    }
    &--bg04 {
      background-color: rgba($color_aibetsu, 0.4);
    }
    &--border {
      border-color: $color_aibetsu!important;
    }
  }
  &__pippu {
    color: $color_pippu;
    &--bg {
      background-color: $color_pippu;
      svg path {
        fill: $color_text_black;
      }
    }
    &--bg04 {
      background-color: rgba($color_pippu, 0.4);
    }
    &--border {
      border-color: $color_pippu!important;
    }
  }
  &__takasu {
    color: $color_takasu;
    &--bg {
      background-color: $color_takasu;
      svg path {
        fill: $color_text_black;
      }
    }
    &--bg04 {
      background-color: rgba($color_takasu, 0.4);
    }
    &--border {
      border-color: $color_takasu!important;
    }
  }
  &__higashikagura {
    color: $color_higashikagura;
    &--bg {
      background-color: $color_higashikagura;
      color: $color_text_white;
      svg path {
        fill: $color_text_white;
      }
    }
    &--bg04 {
      background-color: rgba($color_higashikagura, 0.4);
    }
    &--border {
      border-color: $color_higashikagura!important;
    }
  }
  &__tohma {
    color: $color_tohma;
    &--bg {
      background-color: $color_tohma;
      color: $color_text_white;
      svg path {
        fill: $color_text_white;
      }
    }
    &--bg04 {
      background-color: rgba($color_tohma, 0.4);
    }
    &--border {
      border-color: $color_tohma!important;
    }
  }
  &__higashikawa {
    color: $color_higashikawa;
    &--bg {
      background-color: $color_higashikawa;
      svg path {
        fill: $color_text_black;
      }
    }
    &--bg04 {
      background-color: rgba($color_higashikawa, 0.4);
    }
    &--border {
      border-color: $color_higashikawa!important;
    }
  }
  &__kamikawa {
    color: $color_kamikawa;
    &--bg {
      background-color: $color_kamikawa;
      svg path {
        fill: $color_text_black;
      }
    }
    &--bg04 {
      background-color: rgba($color_kamikawa, 0.4);
    }
    &--border {
      border-color: $color_kamikawa!important;
    }
  }
  &__biei {
    color: $color_biei;
    &--bg {
      background-color: $color_biei;
      svg path {
        fill: $color_text_black;
      }
    }
    &--bg04 {
      background-color: rgba($color_biei, 0.4);
    }
    &--border {
      border-color: $color_biei!important;
    }
  }

}
