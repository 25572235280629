.c-button {
  display: inline-block;
  padding: .75em 1em;
  margin: 0 0;
  font-size: .9rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #68684d;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: background-color .25s ease-out, color .25s ease-out;
  transition: .3s linear;
  -webkit-appearance: none;

  &:hover {
    background-color: #1c1814;
  }

  /* VIEW MORE */
  &__more {
    display: inline-block;
    position: relative;
    overflow: hidden;
    color: $color_text_black;

    border: 1px solid $color_bg_black;
    //padding: 10px 30px;
    padding: 10px 0;
    text-align: center;
    transition: ease .2s;
    width: 100%;
    max-width: 140px;
    height: 44px;
    border-radius: 22px;
    background-color: $color_white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @include mq-up(md) {
      max-width: 185px;
      height: 60px;
      border-radius: 35px;
    }

    >span {
      position: relative;
      z-index: 3;/*z-indexの数値をあげて文字を背景よりも手前に表示*/
      @include font-size(14);
      font-weight: bold;

      @include mq-up(md) {
        @include font-size(18);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      background-color: $color_bg_black;
      width: 100%;
      height: 100%;

      /* animation */
      transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
    }

    &:hover >span{
      color: $color_white;
    }

    /*hoverした際の形状*/
    &:hover::before{
      transform-origin:left top;
      transform:scale(1, 1.1);
    }
  }









  /* 黒文字黒枠白背景 */
  /*
  &__normal {
    display: inline-block;
    border: 1px solid $color_black;
    background-color: $color_white;
    color: $color_black;
    padding: 0.5rem 2rem;
    transition: background-color .5s;

    &:hover {
      background-color: $color_black;
      color: $color_white;
      cursor: pointer;
    }
  }
  */

  /* 黒文字黒枠白背景 */
  &__normal {
    display: inline-block;
    border: 1px solid #F2F2F2;
    color: $color_black;
    padding: 0.5rem 2rem;
    transition: background-color .5s;

    background-color: #F2F2F2;
    font-family: $font-family-robotoslab;
    @include font-size(20);
    border-radius: 25px;

    &:hover {
      background-color: unset;
      color: $color_black;
      border: 1px solid $color_black;
      cursor: pointer;
    }
  }

  /* グレー背景黒文字 */
  &__reserve {
    display: inline-block;
    border: 1px solid rgba(255,255,255,0.2);
    color: $color_white!important;
    padding: 0.5rem 2rem;
    transition: background-color .5s;

    background-color: rgba(255,255,255,0.2);
    //font-family: 'Roboto Slab', serif;
    @include font-size(20);
    border-radius: 25px;

    width: 360px;
    text-align: center;

    &:hover {
      background-color: unset;
      border: 1px solid $color_white;
      cursor: pointer;
    }
  }




  /* 白文字白枠背景無し */
  &__white {
    display: inline-block;
    border: 1px solid $color_white;
    color: $color_white!important;
    padding: 0.5rem 2rem;
    transition: background-color .5s;

    &:hover {
      background-color: $color_white;
      color: $color_black!important;
      border-color: $color_black;
    }
  }

  /* 浮かびあがっているボタン */
  &__3d {
    position: relative;
    background-color: $color_white;
    color: $color_black;
    border: 1px solid $color_black;
    padding: 0.5rem 1rem;
    display: inline-block;

    &:before {
      top: 5px;
      right: -10px;
      width: 10px;
      height: calc(100%  + 0px);
      background-color: $color_black;
      transform: skewY(45deg);
      position: absolute;
      content: '';
    }

    &:after {
      left: 5px;
      bottom: -10px;
      width: calc(100%  + 0px);
      height: 10px;
      background-color: $color_black;
      transform: skewX(45deg);
      position: absolute;
      content: '';
    }
  }

  /* 枠線アニメーションベース */
  @mixin enclose(
    $speed: .05s,
    $background_color: $color_white,
    $border_color: $color_black,
    $color: $color_text_black,
    $btn_color: #F2F2F2
    ) {

    width: 184px;
    height: 50px;

    position: relative;
    display: inline-block;

    &--border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $border_color;
      border-radius: 25px;


      &:before {
        display: block;
        position: absolute;
        background-color: $background_color;
        content: '';

        top: 0;
        left: 0;
        width: calc(100%);
        height: calc(100%);
      }

      &:after {
        display: block;
        position: absolute;
        background-color: $background_color;
        content: '';

        top: 5px;
        left: -2px;
        width: calc(100% - 20px);
        height: calc(100%);

        //border-radius: 10px;
      }

      &:hover::before {
        top: calc(100% + 2px);
        left: calc(100% - 20px);
        width: 20px;
        height: 0;

        transition:
        width $speed ease-out,
        left $speed ease-out,
        height $speed ease-out $speed,
        top $speed ease-out $speed;
      }

      &:hover::after {
        //left: -20px;
        //width: 20px;
        left: 0;
        width: 20px;
        height: 0;

        transition:
        width $speed ease-out $speed * 2,
        height $speed ease-out $speed * 3;
      }
    }

    &--content {
      z-index: 2;
      position: relative;
      display: flex;
      border-radius: 25px;
      color: $color;
      background-color: $btn_color;

      width: calc(100% - 2px);
      height: calc(100% - 2px);
      margin: 1px;
      font-family: $font-family-robotoslab;
      @include font-size(20);
      border-radius: 25px;

      justify-content: center;
      align-items: center;

      transition: background-color $speed * 3;

      &:hover {
        background-color: $background_color;
        cursor: pointer;
      }

    }
  }

  /* 背景白(デフォルト) */
  &__enclose {
    @include enclose();
  }


   /* 背景グレー */
   &__enclose3 {
    @include enclose(
      $color: $color_text_white,
      $border_color: $color_white,
      $background_color: $color_black,
      $btn_color: #6F6D6D
    );

    width: 360px;

    &--content {
      font-family: $font-family-base!important;
    }

  }

}
