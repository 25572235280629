.c-loader,
.c-loader::after {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.c-loader {
  position: relative;
  margin: 1rem auto;

  @include font-size(10);

  text-indent: -9999em;
  border-top: .3rem solid rgba(#999, .2);
  border-right: .3rem solid rgba(#999, .2);
  border-bottom: .3rem solid rgba(#999, .2);
  border-left: .3rem solid #999;
  transform: translateZ(0);
  animation: load8 .8s infinite linear;

  &--small {
    margin: 0 auto;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
