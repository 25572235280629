.s-information {

  &__body {
    width: 100%;
    margin: 0 auto;

    @include mq-up('md') {
      max-width: 1130px;
      padding: 0;
    }
  }

  &__inner {

    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 4rem;

    // アイキャッチ中央寄せ
    .attachment-post-thumbnail {
      display: block;
      margin: 0 auto 2rem;
    }

  }

  &__date {
    margin-bottom: 1rem;
    font-size: .9rem;
    display: inline-block;
  }

  &__title {
    margin-top: 60px;
    margin-bottom: 2rem;
    @include font-size(24);
    font-weight: bold;

    @include mq-up('md') {
      //@include font-size($font-size_news_title);
    }
  }

  &__pics {

    position: relative;
    padding: 0 3rem;
    margin-top: 30px;
    margin-bottom: 2rem;

    @include mq-up(md) {
      padding: 0 5rem;
      margin-bottom: 105px;
      margin-top: 50px;
    }

    &--swiper {
      //width: calc(100% - 100px);

      box-sizing: content-box;
      margin: 0 58px;
      overflow: hidden;
      position: relative;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (646 / 1148) * 100%;
      }

      .swiper-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
      }

      .swiper-slide {
        text-align: center;
        width: auto;
        max-width: calc(100% - 40px - 20px);
        @include mq-up(md) {
          max-width: calc(100% - 80px - 40px);
        }

        img {
          //max-width: 100%;
          width: auto;
          //width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .swiper-pagination-bullets {

      bottom: -28px!important;

      @include mq-up(md) {
        bottom: -50px!important;
      }

      .swiper-pagination-bullet {
        background-color: $color_white;
        opacity: 1;
        border: 1px solid #787878;
      }
      .swiper-pagination-bullet-active {
        background-color: #15b7ff;
        border: none;
      }

    }


    .swiper-button-prev,
    .swiper-button-next {
      height: 30px;
      width: 30px;
      margin-top: 0;

      @include mq-up(md) {
        height: 50px;
        width: 50px;
      }
    }
    /* 前へ次への矢印カスタマイズ */
    .swiper-button-prev,
    .swiper-button-next {

      top: 50%;
      transform: translateY(-50%);

      &:after {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        margin: auto;
        height: 30px;
        width: 30px;

        @include mq-up(md) {
          height: 50px;
          width: 50px;
        }
      }
    }
    .swiper-button-disabled {
      visibility: hidden;
    }

    /* 前への矢印カスタマイズ */
    .swiper-button-prev:after {
      background-image: url('/images/arrow_gray.png');
    }
    /* 次への矢印カスタマイズ */
    .swiper-button-next:after {
      background-image: url('/images/arrow_gray.png');
      transform: scaleX(-1);
    }

  }


  &__content {
    margin-top: 30px;
    max-width: 850px;
    margin: 0 auto;

    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }


  }

  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
    color: $color_text_black;
  }

  &__previous {
    position: absolute;
    bottom: 1px;
    left: 1rem;
    color: $color_text_black;
    @include mq-up('md') {
      left: 0;
    }
  }

  &__next {
    position: absolute;
    bottom: 1px;
    right: 1rem;
    color: $color_text_black;
    @include mq-up('md') {
      right: 0;
    }
  }



}