.l-main {
  margin: 0 auto;
  //margin-top: 90px;

  /* パララックス */
  &__parallax {
    position: fixed;
    background-attachment: fixed; /*--背景画像の固定--*/
    background: url('/images/home_eventinfo.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    z-index: -999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }


  &__logo {
    position: absolute;
    background-image: url('/images/home_logo.png');
    background-size: contain;
    top: calc(44px + 1rem);
    left: 1rem;
    width: 150px;
    height: 152px;
    z-index: 1;

    @include mq-up(md) {
      top: calc(44px + 35px);
      left: 65px;
      width: 225px;
      height: 152px;
    }
  }

  &__body {
    text-align: left;
    padding: 2rem 1rem;
    padding-bottom: 150px;
    //background-color: rgba($color_white, 0.8);
    background-color: $color_white;
    margin-top: 150px;

    @include mq-up('md') {
      width: 100%;
      margin: 0 auto;
      //margin-top: calc(70px + 10rem);
      margin-top: 260px;
      //padding-bottom: 12rem;
      //padding-bottom: 230px;
      padding-bottom: 260px;
      //padding-bottom: calc(300px + 125px);
    }
  }

  &__breadcrumbs {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    color: #4b4b4b;
    font-size: 0.8rem;
    text-align: left;

    img {
      width: 0.8rem;
      margin-right: 5px;
      vertical-align: baseline;
    }

    span a {
      color: #4b4b4b;
      font-size: 0.8rem;
    }

  }

}
