@charset "UTF-8";
/*
 * foundation
 */
/* 利用しているもの */
/* base color */
/* 背景 */
/* 文字 */
/* リンク newsのカラーをベースに */
/* リンクをホバー */
/* visitedリンク */
/* ******************** */
/* メニュー */
/* ******************** */
/* use */
/* home columnの日付 */
/* ******************** */
/* フォームの「必須」で使用 */
/* RESERVE背景 */
/* VIEW MOREボタン押下前 */
/* ネット予約ボタン押下前 */
/* コースの番号と罫線 */
/* コースの番号と罫線 */
/* 以下未確認 */
/* 流れる文字 */
/* タイトルやメニュー名見出しが6C6966 */
/*
$font-family-base: '游ゴシック Medium', 'Helvetica Neue', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', meiryo, sans-serif;
*/
/* font-size イラレ通り */
/* ページ共通 */
/* 標準サイズ */
/* トップの文字 */
/* ページタイトル */
/* 見出し */
/* menu */
/* cafe kitchen bar */
/* ボタンのテキスト*/
/* つかってない */
/* つかわない */
/* つかわない */
/* つかわない */
/* つかわない */
/* font-size 調整 */
/*
$font-size_s: 12;
$font-size_m: 15; // 16
$font-size_l: 30; //36
$font-size_xl: 47; //
$font-size_base: $font-size_m;
*/
/* md以上のboxサイズ */
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; }

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  height: 100%;
  font-size: 16px;
  line-height: 1.7; }

body {
  /* メトリクスカーニングを指定する */
  height: 100%;
  font-family: "Noto Sans JP", "游ゴシック Medium", "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", meiryo, sans-serif;
  font-size: 400;
  color: #322E2E;
  /* letter-spacing: .2rem; */
  background-color: #FFFFFF;
  font-kerning: normal;
  /* アンチエイリアスを指定する */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.35;
  color: inherit; }

ul,
ol {
  padding: 0; }

li > ul,
li > ol {
  margin-bottom: 0; }

dd {
  margin-left: 0; }

table {
  width: 100%; }

th {
  text-align: left; }

a {
  color: #3285c1;
  text-decoration: none;
  transition: .2s; }
  a:visited {
    color: auto; }
  a:hover {
    color: auto; }

/**
 * フルードイメージにします。
 */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

/**
 * IEでSVGがフルードイメージにならない不具合があります。
 * 属性値が`.svg`で終わる要素に適応します。
 */
[src$='.svg'],
[data$='.svg'] {
  width: 100%; }

/**
 * 日本語では斜体を使用しないためリセットします。
 */
i,
cite,
em,
address,
dfn {
  font-style: normal; }

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
  margin-top: 0;
  margin-bottom: 0; }

figure {
  margin: 0; }

/*
 * layout
 */
.l-footer {
  text-align: center;
  position: relative;
  background-color: #1e1e1e;
  color: #FFFFFF;
  padding-bottom: 55px; }
  @media screen and (max-width: 768px) {
    .l-footer {
      display: block;
      padding-bottom: 30px; } }

.l-header {
  position: relative;
  width: 100%;
  top: 0;
  z-index: 999;
  /*
  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 100%;
  }
  */ }
  @media screen and (min-width: 769px) {
    .l-header {
      display: flex;
      justify-content: center; } }
  .l-header.is-scroll {
    /*
    @include mq-up(md) {
      position: fixed;
      height: fit-content;
    }
    */ }
  @media screen and (min-width: 1261px) {
    .l-header {
      justify-content: center; } }

.l-inner {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 92.1875%;
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto; }

.l-main {
  margin: 0 auto;
  /* パララックス */ }
  .l-main__parallax {
    position: fixed;
    background-attachment: fixed;
    /*--背景画像の固定--*/
    background: url("/images/home_eventinfo.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    z-index: -999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7; }
  .l-main__logo {
    position: absolute;
    background-image: url("/images/home_logo.png");
    background-size: contain;
    top: calc(44px + 1rem);
    left: 1rem;
    width: 150px;
    height: 152px;
    z-index: 1; }
    @media screen and (min-width: 769px) {
      .l-main__logo {
        top: calc(44px + 35px);
        left: 65px;
        width: 225px;
        height: 152px; } }
  .l-main__body {
    text-align: left;
    padding: 2rem 1rem;
    padding-bottom: 150px;
    background-color: #FFFFFF;
    margin-top: 150px; }
    @media screen and (min-width: 769px) {
      .l-main__body {
        width: 100%;
        margin: 0 auto;
        margin-top: 260px;
        padding-bottom: 260px; } }
  .l-main__breadcrumbs {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    color: #4b4b4b;
    font-size: 0.8rem;
    text-align: left; }
    .l-main__breadcrumbs img {
      width: 0.8rem;
      margin-right: 5px;
      vertical-align: baseline; }
    .l-main__breadcrumbs span a {
      color: #4b4b4b;
      font-size: 0.8rem; }

/*
 * object.
 */
.c-button {
  display: inline-block;
  padding: .75em 1em;
  margin: 0 0;
  font-size: .9rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #68684d;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: background-color .25s ease-out, color .25s ease-out;
  transition: .3s linear;
  -webkit-appearance: none;
  /* VIEW MORE */
  /* 黒文字黒枠白背景 */
  /*
  &__normal {
    display: inline-block;
    border: 1px solid $color_black;
    background-color: $color_white;
    color: $color_black;
    padding: 0.5rem 2rem;
    transition: background-color .5s;

    &:hover {
      background-color: $color_black;
      color: $color_white;
      cursor: pointer;
    }
  }
  */
  /* 黒文字黒枠白背景 */
  /* グレー背景黒文字 */
  /* 白文字白枠背景無し */
  /* 浮かびあがっているボタン */
  /* 枠線アニメーションベース */
  /* 背景白(デフォルト) */
  /* 背景グレー */ }
  .c-button:hover {
    background-color: #1c1814; }
  .c-button__more {
    display: inline-block;
    position: relative;
    overflow: hidden;
    color: #322E2E;
    border: 1px solid #000000;
    padding: 10px 0;
    text-align: center;
    transition: ease .2s;
    width: 100%;
    max-width: 140px;
    height: 44px;
    border-radius: 22px;
    background-color: #FFFFFF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /*hoverした際の形状*/ }
    @media screen and (min-width: 769px) {
      .c-button__more {
        max-width: 185px;
        height: 60px;
        border-radius: 35px; } }
    .c-button__more > span {
      position: relative;
      z-index: 3;
      /*z-indexの数値をあげて文字を背景よりも手前に表示*/
      font-size: 14px;
      font-size: 0.875rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      font-weight: bold; }
      @media screen and (min-width: 769px) {
        .c-button__more > span {
          font-size: 18px;
          font-size: 1.125rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
    .c-button__more::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #000000;
      width: 100%;
      height: 100%;
      /* animation */
      transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top; }
    .c-button__more:hover > span {
      color: #FFFFFF; }
    .c-button__more:hover::before {
      transform-origin: left top;
      transform: scale(1, 1.1); }
  .c-button__normal {
    display: inline-block;
    border: 1px solid #F2F2F2;
    color: #322E2E;
    padding: 0.5rem 2rem;
    transition: background-color .5s;
    background-color: #F2F2F2;
    font-family: "Roboto Slab", serif;
    font-size: 20px;
    font-size: 1.25rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    border-radius: 25px; }
    .c-button__normal:hover {
      background-color: unset;
      color: #322E2E;
      border: 1px solid #322E2E;
      cursor: pointer; }
  .c-button__reserve {
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #FFFFFF !important;
    padding: 0.5rem 2rem;
    transition: background-color .5s;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 20px;
    font-size: 1.25rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    border-radius: 25px;
    width: 360px;
    text-align: center; }
    .c-button__reserve:hover {
      background-color: unset;
      border: 1px solid #FFFFFF;
      cursor: pointer; }
  .c-button__white {
    display: inline-block;
    border: 1px solid #FFFFFF;
    color: #FFFFFF !important;
    padding: 0.5rem 2rem;
    transition: background-color .5s; }
    .c-button__white:hover {
      background-color: #FFFFFF;
      color: #322E2E !important;
      border-color: #322E2E; }
  .c-button__3d {
    position: relative;
    background-color: #FFFFFF;
    color: #322E2E;
    border: 1px solid #322E2E;
    padding: 0.5rem 1rem;
    display: inline-block; }
    .c-button__3d:before {
      top: 5px;
      right: -10px;
      width: 10px;
      height: calc(100%  + 0px);
      background-color: #322E2E;
      transform: skewY(45deg);
      position: absolute;
      content: ''; }
    .c-button__3d:after {
      left: 5px;
      bottom: -10px;
      width: calc(100%  + 0px);
      height: 10px;
      background-color: #322E2E;
      transform: skewX(45deg);
      position: absolute;
      content: ''; }
  .c-button__enclose {
    width: 184px;
    height: 50px;
    position: relative;
    display: inline-block; }
    .c-button__enclose--border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #322E2E;
      border-radius: 25px; }
      .c-button__enclose--border:before {
        display: block;
        position: absolute;
        background-color: #FFFFFF;
        content: '';
        top: 0;
        left: 0;
        width: calc(100%);
        height: calc(100%); }
      .c-button__enclose--border:after {
        display: block;
        position: absolute;
        background-color: #FFFFFF;
        content: '';
        top: 5px;
        left: -2px;
        width: calc(100% - 20px);
        height: calc(100%); }
      .c-button__enclose--border:hover::before {
        top: calc(100% + 2px);
        left: calc(100% - 20px);
        width: 20px;
        height: 0;
        transition: width 0.05s ease-out, left 0.05s ease-out, height 0.05s ease-out 0.05s, top 0.05s ease-out 0.05s; }
      .c-button__enclose--border:hover::after {
        left: 0;
        width: 20px;
        height: 0;
        transition: width 0.05s ease-out 0.1s, height 0.05s ease-out 0.15s; }
    .c-button__enclose--content {
      z-index: 2;
      position: relative;
      display: flex;
      border-radius: 25px;
      color: #322E2E;
      background-color: #F2F2F2;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      margin: 1px;
      font-family: "Roboto Slab", serif;
      font-size: 20px;
      font-size: 1.25rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      border-radius: 25px;
      justify-content: center;
      align-items: center;
      transition: background-color 0.15s; }
      .c-button__enclose--content:hover {
        background-color: #FFFFFF;
        cursor: pointer; }
  .c-button__enclose3 {
    width: 184px;
    height: 50px;
    position: relative;
    display: inline-block;
    width: 360px; }
    .c-button__enclose3--border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border-radius: 25px; }
      .c-button__enclose3--border:before {
        display: block;
        position: absolute;
        background-color: #322E2E;
        content: '';
        top: 0;
        left: 0;
        width: calc(100%);
        height: calc(100%); }
      .c-button__enclose3--border:after {
        display: block;
        position: absolute;
        background-color: #322E2E;
        content: '';
        top: 5px;
        left: -2px;
        width: calc(100% - 20px);
        height: calc(100%); }
      .c-button__enclose3--border:hover::before {
        top: calc(100% + 2px);
        left: calc(100% - 20px);
        width: 20px;
        height: 0;
        transition: width 0.05s ease-out, left 0.05s ease-out, height 0.05s ease-out 0.05s, top 0.05s ease-out 0.05s; }
      .c-button__enclose3--border:hover::after {
        left: 0;
        width: 20px;
        height: 0;
        transition: width 0.05s ease-out 0.1s, height 0.05s ease-out 0.15s; }
    .c-button__enclose3--content {
      z-index: 2;
      position: relative;
      display: flex;
      border-radius: 25px;
      color: #FFFFFF;
      background-color: #6F6D6D;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      margin: 1px;
      font-family: "Roboto Slab", serif;
      font-size: 20px;
      font-size: 1.25rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      border-radius: 25px;
      justify-content: center;
      align-items: center;
      transition: background-color 0.15s; }
      .c-button__enclose3--content:hover {
        background-color: #322E2E;
        cursor: pointer; }
    .c-button__enclose3--content {
      font-family: "Noto Sans JP", "游ゴシック Medium", "Helvetica Neue", "Hiragino Sans", "Hiragino Kaku Gothic ProN", meiryo, sans-serif !important; }

.c-cursor__follower {
  border-radius: 50%;
  position: absolute;
  /*bodyの一番左上になるように配置する*/
  top: 0;
  left: 0;
  pointer-events: none;
  /*他の要素がクリックできなくならないようにする*/
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /*カーソル要素よりも一つ下になるようにする*/
  transition: transform ease-out 0.2s;
  text-align: center;
  width: 100px;
  height: 100px; }
  @media screen and (max-width: 768px) {
    .c-cursor__follower {
      display: none; } }

.c-label {
  /* カテゴリ表示 */ }
  .c-label__category {
    width: 5rem;
    display: inline-block;
    text-align: center; }

.c-loader,
.c-loader::after {
  width: 2rem;
  height: 2rem;
  border-radius: 50%; }

.c-loader {
  position: relative;
  margin: 1rem auto;
  font-size: 10px;
  font-size: 0.625rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
  text-indent: -9999em;
  border-top: 0.3rem solid rgba(153, 153, 153, 0.2);
  border-right: 0.3rem solid rgba(153, 153, 153, 0.2);
  border-bottom: 0.3rem solid rgba(153, 153, 153, 0.2);
  border-left: .3rem solid #999;
  transform: translateZ(0);
  animation: load8 .8s infinite linear; }
  .c-loader--small {
    margin: 0 auto; }

@keyframes load8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* NO IMAGEの表示 */
.c-noimage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.2rem;
  color: #666;
  background-color: #FFFFFF; }
  .c-noimage:after {
    content: '';
    background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 150px;
    background-position: center; }

.c-pager {
  padding: 1rem;
  margin: 0 auto;
  text-align: center;
  /* 選択されていないリンク */
  /* 選択中のページ数 */ }
  @media screen and (min-width: 769px) {
    .c-pager {
      width: 760px; } }
  .c-pager a {
    color: #6e6e6e;
    padding: 5px 1px !important;
    border: none !important;
    margin: 0 15px !important; }
  .c-pager span {
    color: #1e1e1e;
    padding: 5px 1px !important;
    border: none !important;
    border-bottom: 1px solid #6e6e6e !important;
    margin: 0 15px !important; }

/* 文字関連のもの */
/* 子ページ上部タイトル */
.c-typo__headings1 {
  display: inline-block;
  text-align: center;
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  padding: 1rem;
  font-size: 50px;
  font-size: 3.125rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
  @media screen and (min-width: 769px) {
    .c-typo__headings1 {
      font-size: 65px;
      font-size: 4.0625rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }

/* 見出し(黒文字) */
/* 見出し サブタイトル */
/* 説明文 */
.c-typo__intro {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-size: 1.125rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
  font-weight: bold; }

/* HOME 見出し */
.c-typo__yellow {
  font-size: 45px;
  font-size: 2.8125rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  position: relative;
  display: inline-block;
  padding: 0 0 0 32px;
  line-height: 66px;
  outline: none;
  z-index: 0;
  margin-left: -32px; }
  @media screen and (max-width: 768px) {
    .c-typo__yellow {
      font-size: 30px;
      font-size: 1.875rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      padding: 0 0 0 20px;
      line-height: 42px;
      margin-left: -20px; } }
  @media screen and (min-width: 769px) {
    .c-typo__yellow {
      font-size: 60px;
      font-size: 3.75rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
  .c-typo__yellow:before {
    content: '';
    position: absolute;
    left: 0;
    z-index: -1;
    /*丸の形状*/
    width: 64px;
    height: 64px;
    border-radius: calc(64px / 2);
    /*アニメーションの指定*/
    transition: .3s ease-out; }
    @media screen and (max-width: 768px) {
      .c-typo__yellow:before {
        background: #ffff00;
        width: 40px;
        height: 40px;
        border-radius: calc(40px / 2); } }
  .c-typo__yellow--long.c-typo__yellow:before {
    width: calc(100% + 32px); }
    @media screen and (max-width: 768px) {
      .c-typo__yellow--long.c-typo__yellow:before {
        width: calc(100% + 20px); } }

.c-typo {
  /*

  &__headings2 {
    @extend %headings2;
  }

  &__headings3 {
    @extend %headings3;
  }
  */
  /* 戻るリンク */
  /* 前へリンク */
  /* 次へリンク */ }
  .c-typo__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid #322E2E;
    color: #322E2E; }
  .c-typo__previous {
    position: absolute;
    bottom: 1px;
    left: 1rem;
    color: #322E2E; }
    @media screen and (min-width: 769px) {
      .c-typo__previous {
        left: 0; } }
  .c-typo__next {
    position: absolute;
    bottom: 1px;
    right: 1rem;
    color: #322E2E; }
    @media screen and (min-width: 769px) {
      .c-typo__next {
        right: 0; } }
  .c-typo__asahikawa {
    color: #386990; }
    .c-typo__asahikawa--bg {
      background-color: #386990;
      color: #FFFFFF; }
      .c-typo__asahikawa--bg svg path {
        fill: #FFFFFF; }
    .c-typo__asahikawa--bg04 {
      background-color: rgba(56, 105, 144, 0.4); }
    .c-typo__asahikawa--border {
      border-color: #386990 !important; }
  .c-typo__aibetsu {
    color: #38bdc9; }
    .c-typo__aibetsu--bg {
      background-color: #38bdc9; }
      .c-typo__aibetsu--bg svg path {
        fill: #322E2E; }
    .c-typo__aibetsu--bg04 {
      background-color: rgba(56, 189, 201, 0.4); }
    .c-typo__aibetsu--border {
      border-color: #38bdc9 !important; }
  .c-typo__pippu {
    color: #79b453; }
    .c-typo__pippu--bg {
      background-color: #79b453; }
      .c-typo__pippu--bg svg path {
        fill: #322E2E; }
    .c-typo__pippu--bg04 {
      background-color: rgba(121, 180, 83, 0.4); }
    .c-typo__pippu--border {
      border-color: #79b453 !important; }
  .c-typo__takasu {
    color: #c8ab45; }
    .c-typo__takasu--bg {
      background-color: #c8ab45; }
      .c-typo__takasu--bg svg path {
        fill: #322E2E; }
    .c-typo__takasu--bg04 {
      background-color: rgba(200, 171, 69, 0.4); }
    .c-typo__takasu--border {
      border-color: #c8ab45 !important; }
  .c-typo__higashikagura {
    color: #b86fad; }
    .c-typo__higashikagura--bg {
      background-color: #b86fad;
      color: #FFFFFF; }
      .c-typo__higashikagura--bg svg path {
        fill: #FFFFFF; }
    .c-typo__higashikagura--bg04 {
      background-color: rgba(184, 111, 173, 0.4); }
    .c-typo__higashikagura--border {
      border-color: #b86fad !important; }
  .c-typo__tohma {
    color: #1d955c; }
    .c-typo__tohma--bg {
      background-color: #1d955c;
      color: #FFFFFF; }
      .c-typo__tohma--bg svg path {
        fill: #FFFFFF; }
    .c-typo__tohma--bg04 {
      background-color: rgba(29, 149, 92, 0.4); }
    .c-typo__tohma--border {
      border-color: #1d955c !important; }
  .c-typo__higashikawa {
    color: #f1a0b6; }
    .c-typo__higashikawa--bg {
      background-color: #f1a0b6; }
      .c-typo__higashikawa--bg svg path {
        fill: #322E2E; }
    .c-typo__higashikawa--bg04 {
      background-color: rgba(241, 160, 182, 0.4); }
    .c-typo__higashikawa--border {
      border-color: #f1a0b6 !important; }
  .c-typo__kamikawa {
    color: #f59942; }
    .c-typo__kamikawa--bg {
      background-color: #f59942; }
      .c-typo__kamikawa--bg svg path {
        fill: #322E2E; }
    .c-typo__kamikawa--bg04 {
      background-color: rgba(245, 153, 66, 0.4); }
    .c-typo__kamikawa--border {
      border-color: #f59942 !important; }
  .c-typo__biei {
    color: #d87e87; }
    .c-typo__biei--bg {
      background-color: #d87e87; }
      .c-typo__biei--bg svg path {
        fill: #322E2E; }
    .c-typo__biei--bg04 {
      background-color: rgba(216, 126, 135, 0.4); }
    .c-typo__biei--border {
      border-color: #d87e87 !important; }

/* WYSIWYGエディタで入力した箇所の表示 */
.c-wysiwyg {
  line-height: 1.7; }
  .c-wysiwyg address, .c-wysiwyg blockquote, .c-wysiwyg dl, .c-wysiwyg fieldset, .c-wysiwyg figure, .c-wysiwyg h1, .c-wysiwyg h2, .c-wysiwyg h3, .c-wysiwyg h4, .c-wysiwyg h5, .c-wysiwyg h6, .c-wysiwyg hr, .c-wysiwyg ol, .c-wysiwyg p, .c-wysiwyg pre, .c-wysiwyg table, .c-wysiwyg ul {
    margin: 1rem 0; }
  .c-wysiwyg ul {
    margin-left: 1.5rem; }
  .c-wysiwyg pre {
    white-space: pre-wrap; }

.p-footer {
  /* トップへ戻る */
  /* フッターメイン */
  /*
  &__main {


    >div {
      width: 100%;
      margin: 0 auto;
      padding: 2rem;

      @include mq-up('md') {
        width: $box-width_md;
        display: flex;
        justify-content: space-between;
        padding: 2rem 0;
        white-space: nowrap;
      }

      >img {
        object-fit: contain;
        width: 125px;
        object-position: bottom;

        @include mq-down('md') {
          display: block;
          margin: 0 auto;
          margin-top: 2rem;
        }
      }
    }
  }
  */ }
  .p-footer__main {
    position: relative;
    background-color: #1E1E1E;
    /*
    background-image: url('/images/footer.png');
    background-repeat: no-repeat;
    background-size: cover;
    */
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1rem;
    padding-bottom: 30px; }
    .p-footer__main:before {
      content: '';
      position: absolute;
      width: 100%;
      padding-top: 52px;
      top: -50px;
      left: 0;
      background-image: url("/images/footer_top.png");
      background-repeat: repeat-x;
      background-size: contain;
      background-position-x: center;
      background-position-y: bottom; }
      @media screen and (min-width: 769px) {
        .p-footer__main:before {
          padding-top: 125px;
          top: -120px; } }
    @media screen and (min-width: 769px) {
      .p-footer__main {
        flex-direction: row-reverse;
        align-items: center;
        padding: 4rem 45px;
        padding-bottom: calc(90px - 55px - 24px); } }
    @media screen and (min-width: 1000px) {
      .p-footer__main {
        padding: 4rem 97px; } }
    .p-footer__main--menu ul {
      display: flex;
      justify-content: center;
      list-style: none;
      flex-wrap: wrap;
      max-width: 600px; }
      @media screen and (min-width: 769px) {
        .p-footer__main--menu ul {
          justify-content: flex-end; } }
      .p-footer__main--menu ul li {
        margin: 5px; }
        @media screen and (min-width: 769px) {
          .p-footer__main--menu ul li {
            margin: 0.2rem 0.5rem;
            margin-right: 0; }
            .p-footer__main--menu ul li:first-of-type {
              margin-left: 0; } }
        .p-footer__main--menu ul li a {
          color: #FFFFFF;
          display: block;
          padding: 0.5rem; }
          @media screen and (min-width: 769px) {
            .p-footer__main--menu ul li a {
              padding: 0.5rem 1rem; } }
          .p-footer__main--menu ul li a:hover {
            opacity: 0.7; }
    .p-footer__main--logo {
      margin-top: 2rem;
      text-align: center; }
      @media screen and (min-width: 769px) {
        .p-footer__main--logo {
          margin-top: 0;
          text-align: left;
          min-width: 380px; } }
      .p-footer__main--logo img {
        max-width: 300px; }
      .p-footer__main--logo address {
        margin-top: 32px;
        text-align: center;
        font-size: 0.9rem; }
        @media screen and (min-width: 769px) {
          .p-footer__main--logo address {
            font-size: 1rem;
            text-align: left; } }
        .p-footer__main--logo address > span {
          text-align: left;
          display: flex;
          justify-content: center; }
          @media screen and (min-width: 769px) {
            .p-footer__main--logo address > span {
              display: block; } }
  .p-footer__pagetop {
    z-index: 2;
    position: absolute;
    margin-top: -125px;
    right: 1rem; }
    @media screen and (min-width: 769px) {
      .p-footer__pagetop {
        margin-top: -230px;
        right: 47px; } }
    .p-footer__pagetop span {
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (min-width: 769px) {
        .p-footer__pagetop span {
          width: 74px;
          height: 95px; } }
      .p-footer__pagetop span:hover {
        opacity: 0.7;
        cursor: pointer; }
  .p-footer__copyright {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #FFFFFF;
    font-size: 13px;
    font-size: 0.8125rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
    @media screen and (min-width: 769px) {
      .p-footer__copyright {
        font-size: 14px;
        font-size: 0.875rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }

.p-header {
  width: 100%;
  /*
  @include mq-up(md) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 80px;
    width: 780px;
    height: 80px;
  }
  */
  /* ロゴ */
  /*
  &__logo {

    width: 270px;
    transition: .3s;

    @include mq-down(md) {
      margin: 1rem;
    }

    .is-slidedown & {
      position: fixed;
      z-index: 9;
      transform: translateY(0)!important;
    }

    a {
      display: inline-block;
      text-indent: -9999px;
      //background-image: url('/images/logo.png');
      background-position: left;
      background-size: contain;
      width: 100%;
      height: 45px;

    }
  }
  */
  /* メニュー */
  /* メニューボタン */
  /* スクロール時 */ }
  .p-header__right {
    display: none; }
  .p-header__top {
    /*
      ヘッダーサイズ
      PC: 86px
      SP: 66px
      一時サイズ: 44px
      homeと_mainのcssも変更必要
    */
    height: 44px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 1; }
    @media screen and (min-width: 769px) {
      .p-header__top {
        height: 44px; } }
    .p-header__top > a {
      color: #FFFFFF;
      margin-right: 20px;
      display: block; }
      .p-header__top > a:first-child {
        padding-left: 1rem; }
      .p-header__top > a:last-child {
        margin-right: 1rem; }
      .p-header__top > a:hover {
        opacity: 0.7; }
      @media screen and (min-width: 769px) {
        .p-header__top > a {
          margin-right: 35px; }
          .p-header__top > a:last-child {
            margin-right: 46px; } }
    .p-header__top--language img {
      height: 30px; }
    .p-header__top--search img {
      height: 25px; }
  .p-header__nav {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    background-color: #141414;
    padding: 1rem;
    padding-top: 80px;
    position: fixed;
    visibility: hidden;
    max-height: 100%;
    overflow-y: auto;
    transition: .5s;
    top: 0;
    right: 0;
    opacity: 0;
    height: auto;
    width: 100%;
    /* メニューリスト */
    /* スマホメニューオープン時 */
    /* 各メニューのリンク */
    /* 言語選択 */
    /* SNS */ }
    @media screen and (min-width: 769px) {
      .p-header__nav {
        width: auto;
        padding: 5rem; } }
    .p-header__nav > ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      max-width: 550px;
      min-width: 330px;
      /*
      @include mq-up(md) {
        flex-direction: unset;
        justify-content: flex-end;
      }
      */ }
      .p-header__nav > ul > li {
        width: 100%; }
        @media screen and (min-width: 426px) {
          .p-header__nav > ul > li {
            width: 50%; } }
        .p-header__nav > ul > li a {
          display: flex;
          width: 100%;
          align-items: center;
          margin-top: .2rem;
          color: #FFFFFF;
          font-size: 18px;
          font-size: 1.125rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.05rem;
          padding: 1rem;
          padding-right: 0; }
          @media screen and (min-width: 426px) {
            .p-header__nav > ul > li a {
              padding: 1rem;
              margin-top: 1rem; } }
          .p-header__nav > ul > li a:hover {
            opacity: 0.7; }
    .is-slidedown .p-header__nav {
      display: block;
      position: fixed;
      right: 0;
      z-index: 2;
      opacity: 1;
      visibility: visible; }
      .is-slidedown .p-header__nav-item {
        opacity: 1;
        transform: translateY(0); }
    .p-header__nav-item {
      opacity: 0;
      transition: 0.3s ease-out;
      transform: translateY(1rem);
      padding-right: 1rem; }
      @media screen and (min-width: 769px) {
        .p-header__nav-item {
          padding-right: 2rem; } }
    .p-header__nav--bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }
    .p-header__nav--language {
      margin-top: 2rem;
      padding-left: 1rem; }
      .p-header__nav--language img {
        margin-bottom: 0.5rem; }
      .p-header__nav--language select {
        background-color: #FFFFFF; }
    .p-header__nav--sns {
      display: flex;
      margin-left: 2rem; }
      .p-header__nav--sns li {
        margin-right: 1.5rem; }
        .p-header__nav--sns li a {
          color: #FFFFFF; }
  .p-header__button {
    background-color: #1e1e1e;
    transition: .3s;
    position: fixed;
    top: calc(44px + 1rem);
    right: 1rem;
    z-index: 3;
    display: inline-block;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: .5rem;
    cursor: pointer;
    border: none;
    border-radius: 100%; }
    @media screen and (min-width: 769px) {
      .p-header__button {
        top: calc(44px + 35px);
        right: 46px;
        width: 74px;
        height: 74px; } }
    .p-header__button span {
      position: relative;
      display: inline-block;
      width: 25px;
      height: 2px;
      background-color: #FFFFFF;
      opacity: 1;
      transition: .3s;
      vertical-align: middle; }
      @media screen and (min-width: 769px) {
        .p-header__button span {
          width: 34px; } }
      .p-header__button span::before {
        position: absolute;
        top: calc(-2px + -6px);
        left: 0;
        display: inline-block;
        width: 25px;
        height: 2px;
        content: '';
        background-color: #FFFFFF;
        transition: .3s; }
        @media screen and (min-width: 769px) {
          .p-header__button span::before {
            top: calc(-2px + -8px);
            width: 34px; } }
      .p-header__button span::after {
        position: absolute;
        top: calc(2px + 6px);
        left: 0;
        display: inline-block;
        width: 25px;
        height: 2px;
        content: '';
        background-color: #FFFFFF;
        transition: .3s; }
        @media screen and (min-width: 769px) {
          .p-header__button span::after {
            top: calc(2px + 8px);
            width: 34px; } }
  .p-header__button.active {
    filter: none;
    background-color: #FFFFFF; }
  .p-header__button.active span {
    background-color: transparent; }
    .p-header__button.active span::before {
      transform: translateY(7px) rotate(-45deg);
      background-color: #141414; }
      @media screen and (min-width: 769px) {
        .p-header__button.active span::before {
          transform: translateY(9px) rotate(-45deg); } }
    .p-header__button.active span::after {
      transform: translateY(-9px) rotate(45deg);
      background-color: #141414; }
      @media screen and (min-width: 769px) {
        .p-header__button.active span::after {
          transform: translateY(-11px) rotate(45deg); } }
  .is-scroll .p-header {
    /*
    @include mq-down(md) {

      &__logo {
        transform: translateY(-800px);
      }
    }
    */
    /*
    @include mq-up(md) {
      width: 100%;
      margin-top: 0;
      justify-content: flex-end;
      height: fit-content;
      background-color: $color_header_scroll;

      >.p-header__logo {
        display: none;
      }

      >nav {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;

        // メニューリスト
        >ul {

          width: auto;

          >li {
            >a {
              color: $color_text_black;
              margin-bottom: 1rem;
            }

            &.p-header__nav-item--reserve {
              margin-left: 2rem;
              background: $color_header_reserve;

              >a {
                margin-right: 1rem;
                color: $color_text_white;
              }
            }
          }
        }
      }

      // 装飾
      >.p-header__right {
        display: block;
        width: 10rem;
        height: 52px;
        background-color: $color_red;

        &:before {
          content: '';
          display: inline-block;
          width: 3rem;
          height: 52px;
          background-color: green;
        }

        &:after {
          content: '';
          display: inline-block;
          width: 3rem;
          height: 52px;
          background-color: yellow;
        }
      }
    }
    */ }
    .is-scroll .p-header__button {
      top: 1rem;
      transition: .3s; }
      @media screen and (min-width: 769px) {
        .is-scroll .p-header__button {
          top: 35px; } }
  .p-header__sns, .p-header__language, .p-header__search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 80px 20px;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    box-sizing: border-box;
    z-index: 100; }
    @media screen and (min-width: 769px) {
      .p-header__sns, .p-header__language, .p-header__search {
        padding: 80px 20px; } }
    .p-header__sns.active, .p-header__language.active, .p-header__search.active {
      opacity: 1;
      visibility: visible; }
    .p-header__sns--inner, .p-header__language--inner, .p-header__search--inner {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      max-width: 500px; }
    .p-header__sns--close, .p-header__language--close, .p-header__search--close {
      align-items: center;
      width: 45px;
      height: 45px;
      padding: 0.5rem;
      cursor: pointer;
      border: none;
      border-radius: 100%;
      background-color: #787878;
      position: absolute;
      top: 1rem;
      right: 1rem; }
      .p-header__sns--close span, .p-header__language--close span, .p-header__search--close span {
        position: relative;
        top: -4px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 2px;
        opacity: 1;
        -webkit-transition: .3s;
        transition: .3s; }
        .p-header__sns--close span:before, .p-header__language--close span:before, .p-header__search--close span:before {
          position: absolute;
          top: -7px;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 2px;
          content: '';
          -webkit-transition: .3s;
          transition: .3s;
          transform: translateY(7px) rotate(-45deg);
          background-color: #141414; }
        .p-header__sns--close span:after, .p-header__language--close span:after, .p-header__search--close span:after {
          position: absolute;
          top: 7px;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 2px;
          content: '';
          transition: .3s;
          transform: translateY(-7px) rotate(45deg);
          background-color: #141414; }
    .p-header__sns--title, .p-header__language--title, .p-header__search--title {
      text-align: left;
      margin-bottom: 0.5rem; }
    .p-header__sns--content, .p-header__language--content, .p-header__search--content {
      background-color: #322E2E;
      color: #FFFFFF;
      text-align: center;
      padding: 70px 35px; }
      @media screen and (min-width: 769px) {
        .p-header__sns--content, .p-header__language--content, .p-header__search--content {
          padding: 70px 100px; } }
      .p-header__sns--content ul, .p-header__language--content ul, .p-header__search--content ul {
        list-style: none;
        display: flex; }
        .p-header__sns--content ul a, .p-header__language--content ul a, .p-header__search--content ul a {
          color: #FFFFFF;
          font-size: 2.5rem;
          margin: 1rem; }
      .p-header__sns--content input[type=text], .p-header__language--content input[type=text], .p-header__search--content input[type=text] {
        background-color: #FFFFFF;
        color: #322E2E;
        border-radius: 5px;
        border: none;
        padding: 0 1rem; }
      .p-header__sns--content button, .p-header__language--content button, .p-header__search--content button {
        border: none; }
        .p-header__sns--content button:hover, .p-header__language--content button:hover, .p-header__search--content button:hover {
          cursor: pointer; }
  .p-header__select {
    position: relative; }
    .p-header__select:after {
      content: '▼';
      display: inline-block;
      font-size: 10px;
      font-size: 0.625rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      line-height: 1;
      color: #322E2E;
      width: 0;
      z-index: 0;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      pointer-events: none; }
    .p-header__select select {
      background-color: #FFFFFF;
      color: #322E2E;
      border-radius: 5px;
      border: none;
      appearance: none;
      padding: 0 1rem;
      padding-right: 3rem; }
  .p-header__mask {
    display: none; }
    .is-slidedown .p-header__mask {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      opacity: .5;
      z-index: 0; }

/* モーダル用 */
/*
div.modal {
  position: fixed;
  z-index: 10;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
}
*/
.p-nav__next {
  display: none; }

.p-nav__next-button {
  display: block;
  width: 100%;
  padding: .25rem .5rem;
  margin-top: 1rem;
  color: brown;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid brown; }
  .p-nav__next-button:hover {
    color: #fff;
    background-color: brown; }

.s-activities__body {
  max-width: 1130px;
  width: 100%;
  margin: 0 auto; }

.s-activities__inner {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  .s-activities__inner h1 {
    position: relative; }
    @media screen and (min-width: 769px) {
      .s-activities__inner h1::before {
        position: absolute;
        content: '';
        background-image: url("/images/activity_left.png");
        width: 208px;
        height: 99px;
        left: -234px;
        bottom: 22px; }
      .s-activities__inner h1::after {
        position: absolute;
        content: '';
        background-image: url("/images/activity_right.png");
        width: 199px;
        height: 90px;
        right: -203px;
        bottom: 21px; } }

.s-activities__search {
  text-align: center;
  margin-top: 40px; }

.s-activities__area {
  margin-right: 20px;
  margin-bottom: 1rem; }

.s-activities__area, .s-activities__genre {
  position: relative;
  display: inline-block;
  width: 100%; }
  @media screen and (min-width: 769px) {
    .s-activities__area, .s-activities__genre {
      width: 287px;
      max-width: 100%; } }
  .s-activities__area:after, .s-activities__genre:after {
    content: '▼';
    display: inline-block;
    font-size: 10px;
    font-size: 0.625rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    line-height: 1;
    color: #322E2E;
    width: 0;
    z-index: 0;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    pointer-events: none; }
  .s-activities__area select, .s-activities__genre select {
    display: inline-block;
    background-color: #FFFFFF;
    padding: 0.2rem 1rem;
    padding-right: 3rem;
    font-size: 22px;
    font-size: 1.375rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    font-weight: bold;
    border: 1.2px solid #322E2E;
    appearance: none;
    width: 100%; }
    @media screen and (min-width: 769px) {
      .s-activities__area select, .s-activities__genre select {
        width: 287px;
        max-width: 100%; } }
    .s-activities__area select option, .s-activities__genre select option {
      background-color: #322E2E;
      color: #FFFFFF; }

.s-activities__list {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  text-align: left; }
  .s-activities__list li {
    width: 100%;
    margin-bottom: 2rem; }
    @media screen and (min-width: 769px) {
      .s-activities__list li {
        width: calc((100% - 68px) / 2);
        margin-left: 68px; }
        .s-activities__list li:nth-child(2n + 1) {
          margin-left: 0; } }
    @media screen and (min-width: 1000px) {
      .s-activities__list li {
        width: calc((100% - 136px) / 3);
        margin-left: 68px; }
        .s-activities__list li:nth-child(2n + 1) {
          margin-left: 68px; }
        .s-activities__list li:nth-child(3n + 1) {
          margin-left: 0; } }
    .s-activities__list li:hover {
      opacity: 0.8; }
    .s-activities__list li a {
      color: #322E2E; }
    .s-activities__list li figure {
      position: relative;
      width: 100%; }
      .s-activities__list li figure:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 100%; }
      .s-activities__list li figure img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .s-activities__list li dl {
      font-size: 14px;
      font-size: 0.875rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      color: #000; }
      .s-activities__list li dl:last-of-type {
        margin-bottom: 0; }
      .s-activities__list li dl dt {
        display: inline; }
      .s-activities__list li dl dd {
        display: inline-block; }
  .s-activities__list--title {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: .5rem;
    color: #1e1e1e; }
  .s-activities__list--area {
    display: inline-block;
    padding: 0 0.5rem;
    color: #FFFFFF;
    font-size: 0.8rem;
    font-weight: normal;
    margin-right: 0.2rem;
    margin-bottom: 0; }
  .s-activities__list--genre {
    display: inline-block;
    padding: 0 .5rem;
    color: #322E2E;
    font-size: .8rem;
    font-weight: normal;
    margin-right: .2rem;
    margin-bottom: 0;
    border: 1px solid #dcdcdc;
    border-top-right-radius: 10px;
    background-color: #FFFFFF; }
  .s-activities__list--tag {
    display: inline-block;
    font-size: .8rem;
    font-weight: normal;
    margin-right: .5rem;
    margin-bottom: 0;
    color: #3285c1; }
  .s-activities__list--price {
    color: #fa4646;
    font-size: 1rem;
    font-weight: normal;
    font-family: 'Inter' sans-serif;
    margin-bottom: .5rem;
    line-height: 1.3; }

.s-activity {
  /*
  &__body {

    position: relative;
    margin: 2rem;
    padding-bottom: 4rem;

    @include mq-up('md') {
      width: 780px;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }
  */
  /*
  &__date {
    text-align: right;
  }
  */
  /*
  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
  }

  &__previous {
    position: absolute;
    bottom: .2rem;
    left: 1rem;
    @include mq-up('md') {
      left: 0;
    }
  }

  &__next {
    position: absolute;
    bottom: .2rem;
    right: 1rem;
    @include mq-up('md') {
      right: 0;
    }
  }
  */
  /* 周辺で人気のアクティビティ*/ }
  .s-activity__body {
    width: 100%;
    max-width: 1130px;
    margin: 0 auto; }
  .s-activity__inner {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: left;
    position: relative; }
  .s-activity__area {
    text-align: left;
    color: #FFFFFF;
    list-style: none;
    display: inline-block;
    margin-top: 4rem; }
    .s-activity__area li {
      padding: 0 .5rem;
      display: inline-block;
      font-size: 0.8rem;
      font-weight: normal;
      margin-bottom: 0;
      margin-right: .2rem; }
  .s-activity__genre {
    text-align: left;
    color: #322E2E;
    list-style: none;
    display: inline-block; }
    .s-activity__genre li {
      background-color: #FFFFFF;
      margin-right: .2rem;
      margin-bottom: 0;
      display: inline-block;
      font-size: 0.8rem;
      font-weight: normal;
      border: 1px solid gainsboro;
      border-top-right-radius: 10px; }
      @media screen and (min-width: 769px) {
        .s-activity__genre li {
          margin-bottom: 0; } }
      .s-activity__genre li a {
        padding: 0 .5rem;
        color: #322E2E; }
  .s-activity__title {
    text-align: left;
    margin: 1rem 0; }
  .s-activity__tag {
    text-align: left;
    list-style: none;
    display: flex;
    flex-wrap: wrap; }
    .s-activity__tag li {
      font-size: .8rem;
      font-weight: normal;
      margin-right: .5rem; }
  .s-activity__pics {
    position: relative;
    padding: 0 3rem;
    margin-top: 30px;
    margin-bottom: 2rem;
    /* 前へ次への矢印カスタマイズ */
    /* 前への矢印カスタマイズ */
    /* 次への矢印カスタマイズ */ }
    @media screen and (min-width: 769px) {
      .s-activity__pics {
        padding: 0 5rem;
        margin-bottom: 105px;
        margin-top: 50px; } }
    .s-activity__pics--swiper {
      box-sizing: content-box;
      margin: 0 58px;
      overflow: hidden;
      position: relative; }
      .s-activity__pics--swiper:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.27178%; }
      .s-activity__pics--swiper .swiper-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0; }
      .s-activity__pics--swiper .swiper-slide {
        text-align: center;
        width: auto;
        max-width: calc(100% - 40px - 20px); }
        @media screen and (min-width: 769px) {
          .s-activity__pics--swiper .swiper-slide {
            max-width: calc(100% - 80px - 40px); } }
        .s-activity__pics--swiper .swiper-slide img {
          width: auto;
          height: 100%;
          object-fit: cover; }
    .s-activity__pics .swiper-pagination-bullets {
      bottom: -28px !important; }
      @media screen and (min-width: 769px) {
        .s-activity__pics .swiper-pagination-bullets {
          bottom: -50px !important; } }
      .s-activity__pics .swiper-pagination-bullets .swiper-pagination-bullet {
        background-color: #FFFFFF;
        opacity: 1;
        border: 1px solid #787878; }
      .s-activity__pics .swiper-pagination-bullets .swiper-pagination-bullet-active {
        background-color: #15b7ff;
        border: none; }
    .s-activity__pics .swiper-button-prev,
    .s-activity__pics .swiper-button-next {
      height: 30px;
      width: 30px;
      margin-top: 0; }
      @media screen and (min-width: 769px) {
        .s-activity__pics .swiper-button-prev,
        .s-activity__pics .swiper-button-next {
          height: 50px;
          width: 50px; } }
    .s-activity__pics .swiper-button-prev,
    .s-activity__pics .swiper-button-next {
      top: 50%;
      transform: translateY(-50%); }
      .s-activity__pics .swiper-button-prev:after,
      .s-activity__pics .swiper-button-next:after {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        margin: auto;
        height: 30px;
        width: 30px; }
        @media screen and (min-width: 769px) {
          .s-activity__pics .swiper-button-prev:after,
          .s-activity__pics .swiper-button-next:after {
            height: 50px;
            width: 50px; } }
    .s-activity__pics .swiper-button-disabled {
      visibility: hidden; }
    .s-activity__pics .swiper-button-prev:after {
      background-image: url("/images/arrow_gray.png"); }
    .s-activity__pics .swiper-button-next:after {
      background-image: url("/images/arrow_gray.png");
      transform: scaleX(-1); }
  .s-activity__list {
    background-color: #FFFFFF;
    position: relative; }
    @media screen and (max-width: 768px) {
      .s-activity__list {
        width: calc(100% + 2rem);
        margin-left: -1rem; } }
    .s-activity__list--inner {
      padding: 1rem 0rem;
      width: 100%;
      margin: 0 auto; }
      @media screen and (min-width: 769px) {
        .s-activity__list--inner {
          padding: 3rem 0rem;
          max-width: 850px; } }
    .s-activity__list dl {
      border-top: 1px solid #CDCDCD;
      /*
      @include mq-down(md) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
      }
      */ }
      .s-activity__list dl:last-child {
        border-bottom: 1px solid #CDCDCD; }
      @media screen and (min-width: 769px) {
        .s-activity__list dl {
          display: flex; } }
    .s-activity__list dt {
      font-weight: bold;
      background-color: #E6E6E6;
      padding: 1rem; }
      @media screen and (min-width: 769px) {
        .s-activity__list dt {
          padding: 30px 0; } }
      @media screen and (min-width: 769px) {
        .s-activity__list dt {
          font-weight: normal;
          width: 185px;
          padding-left: 40px;
          flex-shrink: 0; } }
    .s-activity__list dd {
      position: relative;
      text-align: left;
      min-height: 1rem;
      padding: 1rem; }
      @media screen and (min-width: 769px) {
        .s-activity__list dd {
          padding: 30px 0; } }
      @media screen and (min-width: 769px) {
        .s-activity__list dd {
          margin-left: 40px;
          margin-right: 40px;
          width: calc(100% - 185px); } }
  .s-activity__title {
    font-size: 24px;
    font-size: 1.5rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    font-weight: bold; }
  .s-activity__detail {
    position: relative;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 850px; }
    .s-activity__detail--inner {
      display: inline-block;
      text-align: left;
      /*
      line-height: 1.7;

      address,
      blockquote,
      dl,
      fieldset,
      figure,
      h1, h2, h3, h4, h5, h6, hr, ol, p, pre, table, ul {
        margin: 1rem 0;
      }
      */ }
      .s-activity__detail--inner h1:not(:first-child), .s-activity__detail--inner h2:not(:first-child), .s-activity__detail--inner h3:not(:first-child), .s-activity__detail--inner h4:not(:first-child), .s-activity__detail--inner h5:not(:first-child), .s-activity__detail--inner h6:not(:first-child) {
        margin-top: 2rem; }
  .s-activity__link {
    text-align: center;
    margin: 4rem 0; }
    .s-activity__link a {
      position: relative;
      z-index: 0;
      display: inline-block;
      color: #322E2E; }
      .s-activity__link a:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: #000000;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        z-index: -1; }
      .s-activity__link a span {
        position: relative;
        z-index: 2;
        display: block;
        padding: 1rem 3rem;
        background-color: #FFFFFF;
        border-radius: 35px;
        border: 2px solid #000000;
        transition: all 0.2s ease; }
        .s-activity__link a span:hover {
          color: #FFFFFF;
          background-color: #505050;
          border-color: #505050;
          transform: translate(5px, 5px); }
  .s-activity__related {
    margin-top: 60px;
    padding-top: 60px;
    text-align: center;
    border-top: 1px solid #322e2e; }
    .s-activity__related h3 {
      font-weight: bold;
      font-size: 20px;
      font-size: 1.25rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.s-area {
  /*
  &__search {
    margin: 2rem auto;
    text-align: center;

    select {
      background-color: $color_white;
      color: $color_black;
    }
  }
  */ }
  .s-area .l-main__body {
    position: relative;
    background-image: url(/images/guide_bg.jpg);
    background-repeat: repeat; }
  .s-area__body {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto; }
  .s-area__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center; }
  .s-area__text {
    margin-bottom: 1rem; }
  .s-area__intro {
    display: inline-block;
    text-align: left;
    margin: 0;
    margin-bottom: 60px;
    max-width: 940px; }
  .s-area__map {
    width: 100%;
    position: relative;
    display: none;
    /*
    background-size: contain;
    background-image: url('/images/area_top_sp.png');

    @include mq-up(md) {
      background-image: url('/images/area_top.png');
    }

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (590 / 665) * 100%;

      @include mq-up(md) {
        padding-top: (746 / 1381) * 100%;
      }
    }
    */ }
    @media screen and (max-width: 768px) {
      .s-area__map--coords {
        display: none; }
        .s-area__map--coords area {
          display: none; } }
  .s-area__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-start;
    margin-top: 30px; }
    @media screen and (min-width: 769px) {
      .s-area__list {
        display: none; } }
    .s-area__list li {
      width: calc((100% - (5px * 1)) / 2);
      margin-bottom: 5px;
      margin-left: 5px;
      /* 左端にくるもののmargin-leftを消す */
      /*
      @include mq-up(md) {
        width: calc((100% - (2rem * 3)) / 4);
      }

      @include mq-up(lg) {
        //width: calc((100% - (2rem * 7)) / 8);
      }
      */ }
      .s-area__list li:nth-child(odd) {
        margin-left: 0; }
      @media screen and (min-width: 426px) {
        .s-area__list li {
          width: calc((100% - (5px * 2)) / 3);
          margin-bottom: 5px;
          /* margin-leftリセット */
          /* 左端にくるもののmargin-leftを消す */ }
          .s-area__list li:nth-child(odd) {
            margin-left: 5px; }
          .s-area__list li:nth-child(3n + 1) {
            margin-left: 0; } }
      .s-area__list li:hover {
        opacity: 0.7; }
      .s-area__list li a {
        display: block;
        width: 100%;
        height: 100%;
        color: #322E2E;
        padding: 11px 5px; }
        .s-area__list li a div {
          margin: 0 auto;
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.6;
          display: inline-block;
          /*
          @include mq-up(md) {
            font-size: 2rem;
          }
          */ }
        .s-area__list li a svg {
          width: .8rem;
          /*
          @include mq-up(md) {
            width: 2rem;
          }
          */ }
  .s-area__center {
    list-style: none; }
    .s-area__center > li {
      border-bottom: 1px solid gray;
      padding: 30px 0; }
      @media screen and (min-width: 769px) {
        .s-area__center > li {
          padding: 125px 0; } }
      .s-area__center > li:last-child {
        border-bottom: none;
        padding-bottom: 0; }
      .s-area__center > li h2 {
        font-weight: 900;
        font-size: 22px;
        font-size: 1.375rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        margin-bottom: 30px; }
        @media screen and (min-width: 769px) {
          .s-area__center > li h2 {
            font-size: 31px;
            font-size: 1.9375rem;
            /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
            margin-bottom: 50px; } }
  .s-area__pics {
    position: relative;
    padding: 0 3rem;
    margin-bottom: 25px;
    /*
    .swiper-pagination-bullets {

      bottom: -28px!important;

      @include mq-up(md) {
        bottom: -50px!important;
      }

      .swiper-pagination-bullet {
        background-color: $color_white;
        opacity: 1;
        border: 1px solid #787878;
      }
      .swiper-pagination-bullet-active {
        background-color: #15b7ff;
        border: none;
      }
    }
    */
    /* 前へ次への矢印カスタマイズ */
    /* 前への矢印カスタマイズ */
    /* 次への矢印カスタマイズ */ }
    @media screen and (min-width: 769px) {
      .s-area__pics {
        padding: 0 5rem;
        margin-bottom: 105px; } }
    .s-area__pics--swiper {
      margin: 0 58px;
      overflow: hidden;
      position: relative; }
      .s-area__pics--swiper:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.27178%; }
      .s-area__pics--swiper .swiper-wrapper {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0; }
      .s-area__pics--swiper .swiper-slide {
        width: auto;
        max-width: calc(100% - 40px - 20px); }
        @media screen and (min-width: 769px) {
          .s-area__pics--swiper .swiper-slide {
            max-width: calc(100% - 80px - 40px); } }
        .s-area__pics--swiper .swiper-slide img {
          width: auto;
          height: 100%;
          object-fit: cover; }
    .s-area__pics .swiper-button-prev,
    .s-area__pics .swiper-button-next {
      height: 30px;
      width: 30px;
      margin-top: 0; }
      @media screen and (min-width: 769px) {
        .s-area__pics .swiper-button-prev,
        .s-area__pics .swiper-button-next {
          height: 50px;
          width: 50px; } }
    .s-area__pics .swiper-button-prev,
    .s-area__pics .swiper-button-next {
      top: 50%;
      transform: translateY(-50%); }
      .s-area__pics .swiper-button-prev:after,
      .s-area__pics .swiper-button-next:after {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        margin: auto;
        height: 30px;
        width: 30px; }
        @media screen and (min-width: 769px) {
          .s-area__pics .swiper-button-prev:after,
          .s-area__pics .swiper-button-next:after {
            height: 50px;
            width: 50px; } }
    .s-area__pics .swiper-button-disabled {
      visibility: hidden; }
    .s-area__pics .swiper-button-prev {
      left: 0; }
      .s-area__pics .swiper-button-prev::after {
        background-image: url("/images/arrow.png"); }
    .s-area__pics .swiper-button-next {
      right: 0; }
      .s-area__pics .swiper-button-next:after {
        background-image: url("/images/arrow.png");
        transform: scaleX(-1); }
  .s-area__can {
    width: 100%;
    margin: 0 auto;
    text-align: left; }
    @media screen and (min-width: 769px) {
      .s-area__can {
        max-width: 850px;
        margin-bottom: 25px; } }
    .s-area__can dt {
      font-weight: bold;
      border-left: 2px solid #323232;
      padding-left: 0.5rem;
      color: #323232;
      font-size: 18px;
      font-size: 1.125rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      margin-bottom: .5rem; }
      @media screen and (min-width: 769px) {
        .s-area__can dt {
          border-left: 5px solid #323232;
          font-size: 24px;
          font-size: 1.5rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
          margin-bottom: 1.5rem; } }
  .s-area__sns {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media screen and (min-width: 769px) {
      .s-area__sns {
        max-width: 850px;
        margin-bottom: 25px; } }
    .s-area__sns li {
      display: inline-block;
      margin-right: 1rem;
      width: 30px; }
      @media screen and (min-width: 769px) {
        .s-area__sns li {
          width: auto; } }
      .s-area__sns li:hover {
        opacity: 0.7; }
  .s-area__googlemap {
    width: 100%;
    margin: 0 auto;
    text-align: center; }
    @media screen and (min-width: 769px) {
      .s-area__googlemap {
        max-width: 1130px;
        margin-bottom: 25px; } }

.s-column {
  /* 周辺で人気のアクティビティ*/ }
  .s-column__body {
    width: 100%;
    margin: 0 auto; }
    @media screen and (min-width: 769px) {
      .s-column__body {
        width: 850px;
        padding: 0; } }
  .s-column__inner {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 4rem; }
    .s-column__inner .attachment-post-thumbnail {
      display: block;
      margin: 0 auto 2rem; }
  .s-column__date {
    margin-bottom: 1rem;
    font-size: .9rem;
    display: block; }
  .s-column__title {
    margin-top: 60px;
    margin-bottom: 2rem;
    font-size: 24px;
    font-size: 1.5rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    font-weight: bold; }
  .s-column__content h1:not(:first-child), .s-column__content h2:not(:first-child), .s-column__content h3:not(:first-child), .s-column__content h4:not(:first-child), .s-column__content h5:not(:first-child), .s-column__content h6:not(:first-child) {
    margin-top: 2rem; }
  .s-column__related {
    margin-top: 60px;
    padding-top: 60px;
    text-align: center;
    border-top: 1px solid #322e2e; }
    .s-column__related h3 {
      font-weight: bold;
      font-size: 20px;
      font-size: 1.25rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
  .s-column__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid #322E2E;
    color: #322E2E; }
  .s-column__previous {
    position: absolute;
    bottom: 1px;
    left: 1rem;
    color: #322E2E; }
    @media screen and (min-width: 769px) {
      .s-column__previous {
        left: 0; } }
  .s-column__next {
    position: absolute;
    bottom: 1px;
    right: 1rem;
    color: #322E2E; }
    @media screen and (min-width: 769px) {
      .s-column__next {
        right: 0; } }

.s-columns__body {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto; }

.s-columns__inner {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  .s-columns__inner h1 {
    position: relative; }
    @media screen and (min-width: 769px) {
      .s-columns__inner h1::before {
        position: absolute;
        content: '';
        background-image: url("/images/column_left.png");
        width: 232px;
        height: 88px;
        left: -239px;
        bottom: 31px; }
      .s-columns__inner h1::after {
        position: absolute;
        content: '';
        background-image: url("/images/column_right.png");
        width: 240px;
        height: 93px;
        right: -247px;
        bottom: 29px; } }

.s-columns__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  margin: 0 auto;
  margin-top: 60px; }
  .s-columns__list li {
    width: 100%;
    margin-bottom: 2rem;
    text-align: left; }
    @media screen and (min-width: 426px) {
      .s-columns__list li {
        width: calc((100% - 20px) / 2);
        margin-left: 20px; }
        .s-columns__list li:nth-child(2n + 1) {
          margin-left: 0; } }
    @media screen and (min-width: 769px) {
      .s-columns__list li {
        width: calc((100% - 40px) / 3);
        margin-left: 20px; }
        .s-columns__list li:nth-child(2n + 1) {
          margin-left: 20px; }
        .s-columns__list li:nth-child(3n + 1) {
          margin-left: 0; } }
    .s-columns__list li a {
      color: #322E2E; }
      .s-columns__list li a h2 {
        font-size: 1rem;
        font-weight: normal; }
      .s-columns__list li a p {
        font-size: .9rem;
        font-weight: normal; }
      .s-columns__list li a:hover {
        opacity: 0.7; }
    .s-columns__list li figure {
      position: relative;
      width: 100%; }
      .s-columns__list li figure:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 86.35294%; }
      .s-columns__list li figure img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%; }

.s-contact {
  /* 完了画面 */ }
  .s-contact__body {
    max-width: 850px;
    width: 100%;
    margin: 0 auto; }
  .s-contact__inner {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    position: relative; }
    .s-contact__inner ul {
      list-style: none;
      margin-top: 60px;
      text-align: left; }
      .s-contact__inner ul li {
        margin-bottom: 1.5rem;
        margin-top: 5px; }
      .s-contact__inner ul input[type=text],
      .s-contact__inner ul input[type=email],
      .s-contact__inner ul input[type=tel],
      .s-contact__inner ul textarea {
        width: 100%;
        border: 2px solid gray;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 5px; }
  .s-contact__required {
    font-size: 15px;
    font-size: 0.9375rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    background-color: #ff3f2e;
    color: #FFFFFF;
    padding: 0 1rem;
    margin-left: 1rem; }
  .s-contact__recaptcha {
    text-align: center;
    margin-bottom: 2rem; }
    .s-contact__recaptcha .recaptcha {
      display: inline-block; }
  .s-contact__agreement {
    text-align: center;
    margin-bottom: 2rem; }
    .s-contact__agreement label {
      margin-left: 1rem; }
  .s-contact__submit {
    text-align: center;
    /* 送信 ローディング */ }
    .s-contact__submit .wpcf7-spinner {
      margin: 10px auto;
      position: absolute;
      left: 0;
      right: 0; }
    .s-contact__submit--inner {
      position: relative;
      z-index: 0;
      display: inline-block; }
      .s-contact__submit--inner:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: #000000;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        z-index: -1; }
      .s-contact__submit--inner input {
        position: relative;
        z-index: 2;
        display: block;
        padding: 1rem 3rem;
        background-color: #FFFFFF;
        border-radius: 35px;
        border: 2px solid #000000;
        transition: all 0.2s ease; }
        .s-contact__submit--inner input:hover {
          color: #FFFFFF;
          background-color: #505050;
          border-color: #505050;
          transform: translate(5px, 5px);
          cursor: pointer; }
  .s-contact__complete {
    text-align: center; }
    .s-contact__complete figure {
      margin-top: 35px;
      margin-bottom: 25px; }
      @media screen and (min-width: 769px) {
        .s-contact__complete figure {
          margin-top: 20px; } }
    .s-contact__complete--title {
      font-size: 22px;
      font-size: 1.375rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      font-weight: bold;
      color: #505050; }
      @media screen and (min-width: 769px) {
        .s-contact__complete--title {
          font-size: 38px;
          font-size: 2.375rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
    .s-contact__complete--message {
      font-size: 18px;
      font-size: 1.125rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      color: #505050;
      margin-top: 25px;
      margin-bottom: 40px; }
      @media screen and (min-width: 769px) {
        .s-contact__complete--message {
          margin-top: 25px;
          margin-bottom: 85px; } }
    .s-contact__complete--back {
      background: #FFFFFF;
      border: 1px solid #000000;
      border-radius: 25px;
      transition: 0.3s;
      display: inline-block; }
      .s-contact__complete--back a {
        display: block;
        padding: 0.5rem 2rem; }
        .s-contact__complete--back a span {
          color: #322E2E; }
      .s-contact__complete--back:hover {
        background-color: #000000;
        transition: 0.3s; }
        .s-contact__complete--back:hover span {
          color: #FFFFFF; }

.s-goods_detail__body {
  width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 769px) {
    .s-goods_detail__body {
      max-width: 850px;
      padding: 0; } }

.s-goods_detail__inner {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 4rem; }

.s-goods_detail__title {
  margin-top: 60px;
  margin-bottom: 2rem;
  font-size: 24px;
  font-size: 1.5rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
  font-weight: bold; }

.s-goods_detail__pic {
  text-align: center; }

.s-goods_detail__content {
  margin-top: 30px; }
  .s-goods_detail__content h1:not(:first-child), .s-goods_detail__content h2:not(:first-child), .s-goods_detail__content h3:not(:first-child), .s-goods_detail__content h4:not(:first-child), .s-goods_detail__content h5:not(:first-child), .s-goods_detail__content h6:not(:first-child) {
    margin-top: 2rem; }

.s-goods_detail__back {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid #322E2E;
  color: #322E2E; }

.s-goods {
  /*
  &__list {

    width: 100%;
    //max-width: 780px;
    margin: 0 auto;
    list-style: none;
    padding: 0 1rem;
    background-color: #f0f0f0;
    padding-top: 60px;
    padding-bottom: 60px;

    @include mq-up(md) {
      //display: flex;
      list-style: none;

      padding-top: 140px;
      padding-bottom: 140px;
    }

    li {
      width: 100%;
      max-width: 1105px;
      margin: 0 auto;
      margin-bottom: 4rem;
      //padding: 1rem;
      display: block;
      text-align: center;

      &:last-of-type() {
        margin-bottom: 0;
      }

      @include mq-up(md) {
        display: flex;
        text-align: left;
        margin-bottom: 2rem;

        &:last-of-type() {
          margin-bottom: 0;
        }
      }

      >figure {
        position: relative;
        width: 100%;

        @include mq-up(md) {
          width: 30%;
        }

        &:before {
          display: block;
          content: "";
          width: 100%;
          //padding-top: (279 / 337) * 100%;
          padding-top: (313 / 426) * 100%;
        }
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      >div {
        text-align: left;
        width: 100%;

        @include mq-up(md) {
          width: 70%;
        }

        p {
          font-weight: bold;
          @include font-size(18);
          margin-bottom: 1rem;

          @include mq-up(md) {
            @include font-size(22);
          }
        }


        @include mq-up(md) {
          padding-left: 2rem;
          font-weight: bold;
        }
      }
    }
  }

  */ }
  .s-goods__body {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto; }
  .s-goods__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center; }
    .s-goods__inner h1 {
      position: relative; }
    .s-goods__inner > section {
      margin-top: 60px;
      margin-bottom: calc(140px - 127px); }
      .s-goods__inner > section > h2 {
        margin-bottom: 46px;
        font-size: 24px;
        font-size: 1.5rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        font-weight: bold;
        display: inline-block;
        text-align: left; }
  .s-goods__intro {
    margin-bottom: 50px; }
    @media screen and (min-width: 769px) {
      .s-goods__intro {
        margin-bottom: calc(140px - 25px); } }
    .s-goods__intro dt {
      border: 1px solid #322E2E;
      padding: .3rem 1rem;
      margin-bottom: 1rem;
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      font-size: 1.125rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
    .s-goods__intro dd {
      margin-bottom: 25px;
      display: inline-block;
      text-align: left; }
      @media screen and (min-width: 769px) {
        .s-goods__intro dd {
          display: block;
          text-align: center; } }
  .s-goods__list {
    position: relative;
    list-style: none;
    width: 100%; }
    @media screen and (min-width: 769px) {
      .s-goods__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        /* 1商品のみ */ }
        .s-goods__list.single_item {
          justify-content: center; }
          .s-goods__list.single_item > li {
            width: 100%;
            max-width: 500px; }
            .s-goods__list.single_item > li figure {
              width: 100%; } }
    .s-goods__list--li {
      position: relative;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid #000; }
      @media screen and (min-width: 769px) {
        .s-goods__list--li {
          width: 50%;
          padding-top: 0;
          border-bottom: none;
          padding-bottom: calc(127px / 2);
          margin-bottom: calc(127px / 2);
          /* 下線 */ }
          .s-goods__list--li:nth-child(even) {
            padding-left: 0; }
            .s-goods__list--li:nth-child(even) > div {
              border-left: 1px solid #000;
              text-align: right; }
          .s-goods__list--li:nth-child(even):not(:last-of-type)::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -100%;
            width: 200%;
            border-bottom: 1px solid #000; } }
    .s-goods__list--inner {
      height: 100%;
      width: 100%;
      text-align: center;
      /* title price detail*/ }
      @media screen and (min-width: 769px) {
        .s-goods__list--inner {
          text-align: left; } }
      .s-goods__list--inner > div {
        width: 100%;
        max-width: 500px;
        text-align: left;
        display: inline-block; }
        @media screen and (min-width: 769px) {
          .s-goods__list--inner > div {
            width: calc(100% - 1rem); } }
    .s-goods__list--pic {
      width: 100%;
      max-width: 500px;
      position: relative;
      display: inline-block; }
      @media screen and (min-width: 769px) {
        .s-goods__list--pic {
          width: calc(100% - 1rem); } }
      .s-goods__list--pic:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 100%; }
      .s-goods__list--pic img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; }
    .s-goods__list--title {
      font-weight: bold;
      display: inline-block;
      margin-right: 1rem;
      font-size: 18px;
      font-size: 1.125rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
      @media screen and (min-width: 769px) {
        .s-goods__list--title {
          font-size: 20px;
          font-size: 1.25rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
    .s-goods__list--price {
      font-weight: bold;
      display: inline-block;
      font-size: 18px;
      font-size: 1.125rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
      @media screen and (min-width: 769px) {
        .s-goods__list--price {
          font-size: 20px;
          font-size: 1.25rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
    .s-goods__list--detail {
      margin-bottom: 1rem; }
    .s-goods__list--button {
      text-align: center; }

.s-guide .l-main__body {
  position: relative;
  background-image: url(/images/guide_bg.jpg);
  background-repeat: repeat;
  overflow: hidden; }
  .s-guide .l-main__body::before, .s-guide .l-main__body::after {
    content: '';
    background-image: url("/images/guide_bg_obj.png");
    background-size: contain;
    background-repeat: repeat-y;
    top: 0;
    width: calc(525px * 0.5);
    height: 100%;
    position: absolute;
    display: none;
    opacity: 0.3; }
    @media screen and (min-width: 426px) {
      .s-guide .l-main__body::before, .s-guide .l-main__body::after {
        display: block; } }
    @media screen and (min-width: 769px) {
      .s-guide .l-main__body::before, .s-guide .l-main__body::after {
        width: 525px; } }
  .s-guide .l-main__body::after {
    background-position: 0 calc(40px * 0.5);
    left: calc(-100px * 0.5); }
    @media screen and (min-width: 769px) {
      .s-guide .l-main__body::after {
        background-position: 0 40px;
        left: -100px; } }
  .s-guide .l-main__body::before {
    background-position: 0 calc(292px * 0.5);
    right: calc(-100px * 0.5); }
    @media screen and (min-width: 769px) {
      .s-guide .l-main__body::before {
        background-position: 0 292px;
        right: -100px; } }

.s-guide__inner {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  padding-bottom: 170px;
  /*
    h1 {
      position: relative;
      margin-bottom: 1rem;
    }
    */ }

.s-guide__top {
  display: block;
  margin: 2rem 2rem; }
  @media screen and (min-width: 769px) {
    .s-guide__top {
      display: flex;
      flex-direction: row-reverse;
      margin: 2rem 3rem;
      margin-bottom: 3.5rem; } }
  .s-guide__top--img {
    max-width: 400px;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-size: cover;
    margin-bottom: 2rem;
    /*
      &.c-noimage {
        position: relative;
        width: auto;
        height: auto;
      }
      */ }
    .s-guide__top--img:before {
      display: block;
      content: '';
      padding-top: 100%; }
    @media screen and (min-width: 769px) {
      .s-guide__top--img {
        width: 54%;
        margin-bottom: 1rem; } }
  .s-guide__top--text {
    text-align: left;
    display: inline-block;
    margin: 0 auto; }
    @media screen and (min-width: 426px) {
      .s-guide__top--text {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center; } }
    @media screen and (min-width: 769px) {
      .s-guide__top--text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 0;
        padding-right: 2rem;
        width: 46%; } }
    .s-guide__top--text > div {
      min-width: 170px; }
  .s-guide__top--position {
    margin-bottom: 1rem;
    line-height: 1.4; }
  .s-guide__top--title {
    letter-spacing: 2px;
    font-size: 32px;
    font-size: 2rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    font-weight: bold;
    margin-bottom: 1rem; }
    @media screen and (min-width: 769px) {
      .s-guide__top--title {
        font-size: 43px;
        font-size: 2.6875rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        letter-spacing: 6px; } }
  .s-guide__top--illust {
    width: 100%;
    max-width: 185px;
    min-width: 0; }
    @media screen and (min-width: 426px) {
      .s-guide__top--illust {
        max-width: 185px;
        margin-right: 2rem; } }

.s-guide__content {
  position: relative;
  text-align: left;
  /* 最初に表示する画像のmargin-topを0に */ }
  .s-guide__content h1, .s-guide__content h2, .s-guide__content h3, .s-guide__content h4, .s-guide__content h5, .s-guide__content h6 {
    font-family: 'Noto Serif JP', serif; }
    .s-guide__content h1:not(:first-child), .s-guide__content h2:not(:first-child), .s-guide__content h3:not(:first-child), .s-guide__content h4:not(:first-child), .s-guide__content h5:not(:first-child), .s-guide__content h6:not(:first-child) {
      margin-top: 2rem; }
  .s-guide__content h2 {
    font-size: 22px;
    font-size: 1.375rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    margin: 2rem 0;
    text-align: left;
    display: flex;
    justify-content: center; }
  .s-guide__content > figure:first-child,
  .s-guide__content > .wp-block-image:first-child > figure {
    margin-top: 0rem !important; }
  .s-guide__content figure,
  .s-guide__content .wp-block-image > figure {
    margin-top: 3rem; }
    @media screen and (min-width: 769px) {
      .s-guide__content figure,
      .s-guide__content .wp-block-image > figure {
        margin-top: 6rem; } }
  @media screen and (min-width: 769px) {
    .s-guide__content p {
      margin-left: 2rem;
      margin-right: 2rem; } }

.s-guide__topintro {
  position: relative;
  text-align: left;
  margin-bottom: 3rem;
  font-family: 'Noto Serif JP', serif;
  font-weight: 600; }
  .s-guide__topintro h2 {
    font-size: 27px;
    font-size: 1.6875rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    margin: 2rem 0;
    text-align: left;
    display: flex;
    justify-content: center; }
  .s-guide__topintro p {
    font-size: 18px;
    font-size: 1.125rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    font-weight: 600;
    line-height: 1.9; }
    @media screen and (min-width: 769px) {
      .s-guide__topintro p {
        margin-left: 2rem;
        margin-right: 2rem; } }

.s-guide__content {
  position: relative;
  text-align: left;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 2.5rem; }
  @media screen and (min-width: 769px) {
    .s-guide__content {
      padding-top: 5rem;
      padding-bottom: 5rem;
      margin-bottom: 5rem; } }
  .s-guide__content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/images/guide_hr.png");
    background-size: contain;
    background-repeat: repeat-x;
    width: 100%;
    height: 5px; }
  .s-guide__content::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("/images/guide_hr.png");
    background-size: contain;
    background-repeat: repeat-x;
    width: 100%;
    height: 5px; }
  .s-guide__content--tour {
    position: relative;
    margin-top: 3rem;
    padding-top: 55px;
    color: #631a00; }
    .s-guide__content--tour::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("/images/guide_linkhead.png");
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 60px; }
    .s-guide__content--tour a {
      color: #631a00;
      border-bottom: #631a00 solid 1px; }
      @media screen and (min-width: 769px) {
        .s-guide__content--tour a {
          letter-spacing: 5px; } }

.s-guide__bottom {
  width: 100%;
  display: block; }
  @media screen and (min-width: 769px) {
    .s-guide__bottom {
      display: flex; } }
  @media screen and (min-width: 769px) {
    .s-guide__bottom--img {
      width: 44%; } }
  .s-guide__bottom--img img {
    width: 100%;
    border-top-left-radius: 70px; }
  .s-guide__bottom--text {
    text-align: left; }
    @media screen and (min-width: 769px) {
      .s-guide__bottom--text {
        width: 56%;
        padding-left: 2rem; } }
  .s-guide__bottom--position {
    font-weight: bold;
    line-height: 1.4; }
  .s-guide__bottom--name {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 18px;
    font-size: 1.125rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    letter-spacing: 2px; }
  .s-guide__bottom--lisence {
    margin-bottom: 1rem;
    line-height: 1.4;
    font-size: 14px;
    font-size: 0.875rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
  .s-guide__bottom--intro {
    font-size: 14px;
    font-size: 0.875rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.s-guides {
  /*
  &__bg {
    background-image: url('/images/guide_bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: repeat-y;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  */
  /*
  &__intro {
    text-align: left;
    margin: 2rem 0;
    padding: 0 1rem;

    @include mq-up(md) {
      text-align: center;
      padding: 0;
    }
  }
  */ }
  .s-guides .l-main__body {
    position: relative;
    background-image: url(/images/guide_bg.jpg);
    background-repeat: repeat;
    overflow: hidden; }
    .s-guides .l-main__body::before, .s-guides .l-main__body::after {
      content: '';
      background-image: url("/images/guide_bg_obj.png");
      background-size: contain;
      background-repeat: repeat-y;
      top: 0;
      width: calc(525px * 0.5);
      height: 100%;
      position: absolute;
      display: none;
      opacity: 0.5; }
      @media screen and (min-width: 426px) {
        .s-guides .l-main__body::before, .s-guides .l-main__body::after {
          display: block; } }
      @media screen and (min-width: 769px) {
        .s-guides .l-main__body::before, .s-guides .l-main__body::after {
          width: 525px; } }
    .s-guides .l-main__body::after {
      background-position: 0 calc(40px * 0.5);
      left: calc(-100px * 0.5); }
      @media screen and (min-width: 769px) {
        .s-guides .l-main__body::after {
          background-position: 0 40px;
          left: -100px; } }
    .s-guides .l-main__body::before {
      background-position: 0 calc(292px * 0.5);
      right: calc(-100px * 0.5); }
      @media screen and (min-width: 769px) {
        .s-guides .l-main__body::before {
          background-position: 0 292px;
          right: -100px; } }
  .s-guides__inner {
    width: 100%;
    max-width: 1130px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1; }
    .s-guides__inner h1 {
      position: relative; }
      @media screen and (min-width: 769px) {
        .s-guides__inner h1::before {
          position: absolute;
          content: '';
          background-image: url("/images/guide_left.png");
          width: 242px;
          height: 94px;
          left: -247px;
          bottom: 28px; }
        .s-guides__inner h1::after {
          position: absolute;
          content: '';
          background-image: url("/images/guide_right.png");
          width: 191px;
          height: 107px;
          right: -197px;
          bottom: 27px; } }
  .s-guides__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: -2rem;
    text-align: left; }
    .s-guides__list li {
      width: 100%;
      margin-top: 90px;
      text-align: center;
      /*
      >img {
        width: 100%;
        background-color: gray;
        //border-radius: 100%;
      }
      */ }
      @media screen and (min-width: 426px) {
        .s-guides__list li {
          width: calc((100% - 30px) / 2);
          margin-left: 30px; }
          .s-guides__list li:nth-child(2n + 1) {
            margin-left: 0; } }
      @media screen and (min-width: 769px) {
        .s-guides__list li {
          width: calc((100% - (138px * 2)) / 3);
          margin-left: 138px; }
          .s-guides__list li:nth-child(2n + 1) {
            margin-left: 138px; }
          .s-guides__list li:nth-child(3n + 1) {
            margin-left: 0; } }
      .s-guides__list li h2 {
        display: inline;
        font-size: 24px;
        font-size: 1.5rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        font-weight: bold; }
        .s-guides__list li h2::before {
          content: '';
          padding-top: 30px;
          display: block; }
        .s-guides__list li h2::after {
          content: '';
          padding-bottom: 16px;
          display: block; }
      .s-guides__list li div {
        text-align: left; }
      .s-guides__list li a:hover {
        opacity: 0.7; }
      .s-guides__list li a h2 {
        color: #322E2E;
        border-bottom: 1px solid #322E2E; }
  .s-guides__img {
    width: 100%;
    max-width: 337px;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-size: cover; }
    .s-guides__img:before {
      display: block;
      content: '';
      padding-top: 100%; }
    .s-guides__img.c-noimage {
      position: relative;
      width: auto;
      height: auto; }
  .s-guides__license {
    font-size: 16px;
    font-size: 1rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
    text-align: center;
    margin-bottom: 1rem; }
  .s-guides__message {
    font-size: 14px;
    font-size: 0.875rem;
    /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.s-home {
  /* swiper */
  /* snow-live */
  /*
  &__booking {
    text-align: center;
    padding: 4rem 1rem;
    //padding-bottom: calc(4rem + 125px);
    //padding: 2rem;
    //padding-top: 4rem;
    //padding-bottom: 200px;
    //z-index: 0;
    padding-top: 118px;
    //padding-bottom: calc(160px + 125px);
    padding-bottom: 125px;

    background-color: $color_white;
    position: relative;

    @include mq-up(md) {
      background-image: linear-gradient(45deg,
      $color_white 42%,
      #b4b4b4 15%,
      #b4b4b4 50%,
      $color_white 50%,
      $color_white 92%,
      #b4b4b4 92%);
      background-size: 9px 9px;
      background-repeat: repeat;
      padding: 4rem 2rem;
      padding-bottom: calc(230px + 2rem);
    }

    &--bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: $color_white;
      //clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
      clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
      z-index: 0;


    }

    &--inner {
      position: relative;
      z-index: 1;
    }


    &--head {
      width: 100%;

      @include mq-up(md) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 66px;
      }

      >span {
        display: block;
        color: #505050;
        @include font-size(18);
        line-height: 1.5;
        font-weight: 600;
        margin-top: 1rem;

        @include mq-up(md) {
          text-align: left;
          display: inline-block;
          margin-top: 0;
          margin-left: 3rem;
        }
      }
    }

    ul {
      margin: 0 auto;
      margin-top: 72px;
      //max-width: 1140px;
      max-width: 944px;
      list-style: none;
      display: flex;
      flex-direction: column;

      @include mq-up(md) {
        flex-wrap: wrap;
        flex-direction: row;
      }

      li {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 50px;
        position: relative;
        z-index: 1;
        height: 180px;

        @include mq-up(md) {
          width: 50%;
          height: auto;
          padding: 25px;


          &:nth-child(even) {
            padding-left: 1.5rem;
            padding-right: 0;
          }
        }


        a {
          position: relative;
          //ボタンの形状
          text-decoration: none;
          display: inline-block;

          text-align: center;
          background: transparent;
          border-radius: 10px;
          //border: solid 1px $color_theme;
          outline: none;
          //アニメーションの指定
          transition: all 0.2s ease;

          width: 100%;
          height: 100%;

          >div {
            display: flex;
            background-color: $color_white;
            justify-content: center;
            align-items: center;
            height: 100%;
            //padding: 2rem;
            padding: 10px 31px;

            border: 5px solid $color_theme;
            border-radius: 10px;
            position: relative;
            transition: all 0.2s ease;

            @include mq-up(md) {
              padding: 37px 31px;
            }

            &:hover {
              background-color: $color_theme;
              color: $color_white;
              transform: translate(4px, 4px);


              .s-home__booking--icon {
                path,circle {
                  fill: $color_white;
                  stroke: $color_theme;
                }
              }
              .s-home__booking--arrow {
                path {
                  stroke: $color_white;
                }
              }
            }
          }

          &:before {
            content: "";
            //絶対配置で影の位置を決める
            position: absolute;
            //z-index: -1;
            top: 4px;
            left: 4px;
            //影の形状
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background-color: $color_theme;
          }
        }
      }
    }

    &--icon {

      margin-right: 0;
      height: 100%;

      @include mq-up(md) {
        margin-right: 1.5rem;
        height: auto;
      }

      &.cycle {
        max-width: 183px;
      }

      &.ski {
        max-width: 131px;
      }

      &.ticket {
        max-width: 167px;
      }

      &.guide {
        max-width: 129px;
      }

      svg {
        width: 100%;
        height: 100%;
        path,circle {
          fill: $color_theme;
        }
      }
    }

    &--title {
      @include font-size(16);
      width: 9rem;
      flex-shrink: 0;

      @include mq-up(md) {
        @include font-size(19);
        //width: 10rem;
        width: 152px;
      }

    }

    &--arrow {
      //width: 1rem;
      margin-left: 1rem;
      path {
        stroke: $color_text_black;
      }

      svg {
        width: 17px;
        height: 23px;
      }
    }

  }
  */ }
  .s-home .swiper-button-prev {
    width: 35px;
    left: 15px; }
    @media screen and (min-width: 769px) {
      .s-home .swiper-button-prev {
        width: 46px;
        left: 46px; } }
  .s-home .swiper-button-next {
    width: 35px;
    right: 10px; }
    @media screen and (min-width: 769px) {
      .s-home .swiper-button-next {
        width: 46px;
        right: 46px; } }
  .s-home .swiper-button-disabled {
    visibility: hidden; }
  .s-home .swiper-button-prev:after,
  .s-home .swiper-button-next:after {
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    margin: auto;
    height: 35px;
    width: 35px; }
    @media screen and (min-width: 769px) {
      .s-home .swiper-button-prev:after,
      .s-home .swiper-button-next:after {
        height: 46px;
        width: 46px; } }
  .s-home .swiper-button-prev:after {
    background-image: url("/images/arrow.png"); }
  .s-home .swiper-button-next:after {
    background-image: url("/images/arrow.png");
    transform: scaleX(-1); }
  .s-home__body {
    overflow: hidden; }
    .s-home__body > section:last-of-type {
      padding-bottom: calc(125px + 2rem); }
      @media screen and (min-width: 769px) {
        .s-home__body > section:last-of-type {
          padding-bottom: calc(230px + 2rem); } }
  .s-home__top {
    width: 100%;
    /*
    &--head {
      background-color: rgba($color_theme, 0.7);
      height: 70px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      >a {
        color: $color_text_white;
        margin-right: 1rem;
      }
    }
    */ }
    .s-home__top--video {
      width: 100vw;
      height: calc(100vh - 44px);
      min-height: 500px;
      overflow: hidden;
      position: relative;
      /*
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      */ }
      .s-home__top--video:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-image: radial-gradient(black 35%, transparent 36%), radial-gradient(black 35%, transparent 36%);
        background-size: 3px 3px;
        background-position: 0 0,1.5px 0;
        background-repeat: repeat;
        opacity: 0.5; }
      @media screen and (min-width: 769px) {
        .s-home__top--video {
          height: calc(100vh - 44px); } }
      .s-home__top--video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative; }
    .s-home__top--logo {
      position: absolute;
      top: calc(44px + 1rem);
      left: 1rem;
      width: 150px;
      max-width: 259px;
      z-index: 1; }
      @media screen and (min-width: 769px) {
        .s-home__top--logo {
          top: calc(44px + 41px);
          left: 85px;
          width: auto; } }
  .s-home__intro {
    background-color: #99cc00;
    position: relative;
    text-align: center;
    /*
    &--title {

      @include font-size(18);
      font-family: $font-family-base;
      font-weight: 900;
      //margin-top: 2rem;
      //margin-bottom: 1rem;
      //margin-bottom: 3.5rem;
      margin-top: 92px;
      margin-bottom: 25px;
      margin-left: -15px;
      padding: 0 0 0 15px;
      line-height: 50px;

      &:before {
        width: 50px;
        height: 50px;
      }

      &.c-typo__yellow--long:before {
        width: calc(100% + 15px);
      }

      @include mq-up(md) {
        @include font-size(2);
        margin-left: 0;
      }
    }
    */ }
    .s-home__intro--inner {
      padding: 0 1rem;
      padding-bottom: 30px; }
      @media screen and (min-width: 769px) {
        .s-home__intro--inner {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;
          padding-bottom: 60px;
          margin: 0 auto; } }
      @media screen and (min-width: 769px) {
        .s-home__intro--inner img {
          margin-top: 36px;
          object-fit: contain;
          max-width: 686px;
          width: calc(100% - 440px); } }
      @media screen and (min-width: 1000px) {
        .s-home__intro--inner img {
          width: calc(100% - 535px); } }
      .s-home__intro--inner > div {
        padding-top: 40px; }
        @media screen and (min-width: 769px) {
          .s-home__intro--inner > div {
            display: inline;
            padding-top: 70px;
            /*
          width: 50%;
          padding: 0 35px;
          */
            padding-left: 35px;
            padding-right: 25px;
            text-align: left;
            flex-shrink: 0; } }
        @media screen and (min-width: 1000px) {
          .s-home__intro--inner > div {
            padding-right: 60px; } }
    .s-home__intro--detail {
      margin-bottom: 2rem;
      text-align: left;
      display: inline-block; }
      .s-home__intro--detail p {
        display: inline;
        background-color: #fffe7b;
        font-feature-settings: "palt";
        font-weight: bold;
        color: #1e1e1e;
        padding: 0 10px;
        line-height: 2.4;
        letter-spacing: 1.5px;
        font-size: 4.3vmin; }
        html[lang=en] .s-home__intro--detail p {
          font-size: 3.5vmin; }
        @media screen and (min-width: 426px) {
          .s-home__intro--detail p {
            font-size: 4.8vmin; }
            html[lang=en] .s-home__intro--detail p {
              font-size: 4vmin; } }
        @media screen and (min-width: 769px) {
          .s-home__intro--detail p {
            letter-spacing: 2px;
            line-height: 2.8;
            margin-bottom: 0;
            font-size: 1.3rem;
            padding: 5px 10px; }
            html[lang=en] .s-home__intro--detail p {
              font-size: 1.2rem !important; } }
        @media screen and (min-width: 1000px) {
          .s-home__intro--detail p {
            font-size: 1.5rem !important; } }
  .s-home__activity {
    background-color: #99cc00;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    /*
    h2 {
      display: inline-block;
      //border-top: 8px solid $color_text_black;
      @include font-size(60);
      font-family: 'Anton', sans-serif;
      font-weight: normal;

      @include mq-up(md) {
        //border-top: 8px solid $color_text_black;
        @include font-size(60);

        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/home_activity_left.png');
          width: 206px;
          height: 96px;
          left: -247px;
          bottom: 0px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/home_activity_right.png');
          width: 182px;
          height: 84px;
          right: -223px;
          bottom: 6px;
        }
      }
    }
    */
    /*
    &--arrow {
      position: absolute;
      content: "";
      width: 35px;
      height: 35px;
      bottom: -12px;
      right: -12px;
      background-image: url('/images/home_right.png');
      background-size: contain;
      transition:.3s;

      a:hover & {
        right: -24px;
        transition:.3s;
      }

      @include mq-up(md) {
        width: 45px;
        height: 45px;
      }
    }
    */ }
    @media screen and (min-width: 769px) {
      .s-home__activity {
        padding-top: 96px;
        padding-bottom: 100px; } }
    .s-home__activity h2 {
      position: relative;
      display: inline-block; }
    .s-home__activity--head {
      display: inline-block;
      text-align: center;
      position: relative; }
    @media screen and (min-width: 769px) {
      .s-home__activity--headleft {
        position: absolute;
        display: inline-block;
        background-image: url("/images/home_activity_left.png");
        width: 206px;
        height: 96px;
        left: -247px;
        bottom: -6px; } }
    @media screen and (min-width: 769px) {
      .s-home__activity--headright {
        position: absolute;
        display: inline-block;
        background-image: url("/images/home_activity_right.png");
        width: 182px;
        height: 84px;
        right: -223px;
        bottom: -2px; } }
    .s-home__activity ul {
      list-style: none;
      max-width: 974px;
      margin: 0 auto;
      display: block;
      margin-top: 30px; }
      @media screen and (min-width: 426px) {
        .s-home__activity ul {
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; } }
      @media screen and (min-width: 769px) {
        .s-home__activity ul {
          padding: 0; } }
      .s-home__activity ul li {
        position: relative;
        margin: 0 auto;
        margin-bottom: 40px;
        width: 80%;
        /*
        &:after {
          position: absolute;
          content: "";
          width: 45px;
          height: 45px;
          bottom: -12px;
          right: -12px;
          background-image: url('/images/home_right.png');
        }
        */
        /*
        &::before {
          content: "";
          padding-top: 100%;
          display: block;
        }
        */
        /*
        a {

          &:hover {
            opacity: 0.8;
          }

          figure {
            position: relative;
          }

          figcaption {
            color: $color_text_white;
            position: absolute;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            @include font-size(18);

            @include mq-up(md) {
              @include font-size(34);
            }
          }
        }
        */ }
        @media screen and (min-width: 426px) {
          .s-home__activity ul li {
            width: calc((100% - 4rem) / 2); } }
        @media screen and (min-width: 769px) {
          .s-home__activity ul li {
            max-width: 270px;
            width: calc(100% / 3);
            margin-bottom: 84px;
            /*
          &:nth-child(2n + 1) {
            margin-bottom: 0px;
          }
          */
            /*
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          */ }
            .s-home__activity ul li:nth-child(3n + 2) {
              margin-bottom: 37px;
              margin-top: 47px; } }
        .s-home__activity ul li:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 96.41434%; }
        .s-home__activity ul li a {
          display: block;
          position: absolute;
          padding: 1rem;
          width: 100%;
          height: 100%;
          top: 0;
          /*
          &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (242 / 251) * 100%;
          }
          */ }
          .s-home__activity ul li a:hover figure img {
            transition: 1s;
            transform: scale(1.15, 1.15); }
          @media screen and (min-width: 769px) {
            .s-home__activity ul li a {
              padding: 22px 24px;
              padding-top: 25px; } }
          .s-home__activity ul li a figure {
            position: relative;
            width: 100%;
            height: 100%;
            /*
            &:hover {
              width: 130%;
              height: 130%;
              transition: .3s;
            }
            */
            /*
            &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: (242 / 251) * 100%;
            }
            */ }
            .s-home__activity ul li a figure img {
              object-fit: contain;
              width: 100%;
              height: 100%;
              transition: 1s;
              object-position: bottom;
              margin-bottom: 1rem;
              transform-origin: bottom; }
            .s-home__activity ul li a figure p {
              position: relative;
              margin-bottom: 1rem; }
          .s-home__activity ul li a figcaption {
            color: #322E2E;
            color: #323232;
            font-weight: bold;
            display: inline-flex;
            align-items: flex-start;
            height: 48px;
            line-height: 1;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-size: 1rem;
            /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
            width: auto;
            text-align: left; }
            @media screen and (min-width: 769px) {
              .s-home__activity ul li a figcaption {
                font-size: 20px;
                font-size: 1.25rem;
                /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
                line-height: 1.35; } }
    .s-home__activity--list {
      width: 100%;
      padding: 0 2rem; }
  .s-home__column {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    background-color: #FFFFFF;
    /*
    @include mq-up(md) {
      background-image: linear-gradient(135deg,
      $color_white 35%,
      #b4b4b4 15%,
      #b4b4b4 50%,
      $color_white 50%,
      $color_white 85%,
      #b4b4b4 85%);
      background-size: 10px 10px;
      background-repeat: repeat;
    }
    */
    /*
    @include mq-up(md) {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 75%;
        background-image: url('/images/home_column--left.png');
        background-position: right 0 center;
        background-repeat: no-repeat;
        //margin-top: -2rem;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 75%;
        background-image: url('/images/home_column--right.png');
        background-position: left 0 center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
    }*/
    /*
    background-image:
     url('/images/home_column--left.png'),
     url('/images/home_column--right.png');

    background-position:
     left 0 center,
     right 0 center;

    background-repeat: no-repeat;
    */
    /*
    h2 {

      @include font-size(65);
      font-family: 'Anton', sans-serif;
      font-weight: normal;

      position: relative;
      display: inline-block;
      padding:0 0 0 15px;
      line-height: 50px;
        outline: none;

      &:before {
        content:'';
        position:absolute;
        left:0;
        z-index: -1;
        width:50px;
        height:50px;
        background:#ffff00;
        border-radius:25px;

          transition:.3s ease-out;
      }
      &:hover::before{
        width:212px;
      }
    }
    */ }
    @media screen and (min-width: 769px) {
      .s-home__column {
        background-image: linear-gradient(135deg, #FFFFFF 42%, #b4b4b4 15%, #b4b4b4 50%, #FFFFFF 50%, #FFFFFF 92%, #b4b4b4 92%);
        background-size: 9px 9px;
        background-repeat: repeat;
        padding-top: 123px;
        padding-bottom: 147px; } }
    .s-home__column--bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      clip-path: polygon(27% 0, 93% 0, 73% 100%, 7% 100%);
      z-index: 0; }
    .s-home__column--inner {
      position: relative;
      z-index: 1; }
    .s-home__column--head {
      display: inline-block;
      text-align: center;
      position: relative; }
      @media screen and (min-width: 769px) {
        .s-home__column--head {
          margin-bottom: 64px; } }
    @media screen and (min-width: 769px) {
      .s-home__column--headleft {
        display: inline-block;
        position: absolute;
        content: '';
        background-image: url("/images/column_left.png");
        width: 232px;
        height: 88px;
        left: -265px;
        top: -18px; } }
    @media screen and (min-width: 769px) {
      .s-home__column--headright {
        display: inline-block;
        position: absolute;
        content: '';
        background-image: url("/images/column_right.png");
        width: 240px;
        height: 93px;
        right: -265px;
        top: -27px; } }
    .s-home__column--headsub {
      margin-top: 10px;
      color: #505050;
      font-weight: bold;
      font-size: 12px;
      font-size: 0.75rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
      @media screen and (min-width: 769px) {
        .s-home__column--headsub {
          font-size: 18px;
          font-size: 1.125rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
    .s-home__column--list {
      position: relative;
      width: 100%;
      max-width: calc(1129px + (46px * 6));
      padding: 0 calc(35px * 2);
      text-align: center;
      margin: 0 auto;
      margin-bottom: 70px;
      margin-top: 30px;
      z-index: 1; }
      @media screen and (min-width: 769px) {
        .s-home__column--list {
          margin-top: 0;
          padding: 0 calc(46px * 3); } }
      .s-home__column--list .swiper {
        position: relative;
        width: 100%; }
      .s-home__column--list .swiper-slide {
        text-align: center; }
        .s-home__column--list .swiper-slide > a {
          display: block;
          color: #322E2E; }
    .s-home__column--img {
      width: 100%;
      margin-bottom: 14px;
      overflow: hidden;
      position: relative; }
      .s-home__column--img:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 86.35294%; }
      .s-home__column--img img {
        transition: 1s all;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%; }
        .s-home__column--img img:hover {
          transform: scale(1.2, 1.2);
          transition: 1s all; }
      .s-home__column--img p {
        transition: 1s all; }
        .s-home__column--img p:hover {
          transform: scale(1.2, 1.2);
          transition: 1s all; }
    .s-home__column--title {
      text-align: left; }
      .s-home__column--title h3 {
        display: inline;
        font-weight: normal;
        font-size: 16px;
        font-size: 1rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        margin-right: 13px; }
      .s-home__column--title span {
        font-size: .9rem;
        display: block;
        /*
        background-color: $color_bg_darkgray;
        padding: 0 0.5rem;
        color: $color_text_white;
        */ }
  .s-home__eventinfo {
    position: relative;
    text-align: center;
    padding-top: 52px;
    padding-bottom: 60px;
    /*固定する背景*/
    /*
    > div {
      box-sizing: border-box;
      color: #FFF;
      font-size: 5rem;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      height: 800px;
      padding: 5%;
    }
    */ }
    @media screen and (min-width: 769px) {
      .s-home__eventinfo {
        padding-top: 178px;
        padding-bottom: 209px; } }
    .s-home__eventinfo--parallax {
      position: fixed;
      z-index: -999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      /*--背景画像の固定--*/ }
      .s-home__eventinfo--parallax::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("/images/home_eventinfo.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.7; }
  .s-home__event {
    background: rgba(255, 255, 255, 0.7);
    padding-top: 111px;
    padding-bottom: 113px; }
    .s-home__event--head {
      width: 100%; }
      @media screen and (min-width: 769px) {
        .s-home__event--head {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 66px; } }
      .s-home__event--head > span {
        display: block;
        color: #505050;
        font-size: 18px;
        font-size: 1.125rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        line-height: 1.5;
        font-weight: 600;
        margin-top: 1rem; }
        @media screen and (min-width: 769px) {
          .s-home__event--head > span {
            text-align: left;
            display: inline-block;
            margin-top: 0;
            margin-left: 3rem; } }
    .s-home__event--list {
      position: relative;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      padding: 0 calc(35px * 2);
      margin-bottom: 60px;
      margin-top: 30px; }
      @media screen and (min-width: 769px) {
        .s-home__event--list {
          max-width: calc(1129px + (46px * 6));
          padding: 0 calc(46px * 3);
          margin-top: 0; } }
      .s-home__event--list .swiper {
        position: relative;
        width: 100%; }
      .s-home__event--list .swiper-slide {
        text-align: center; }
    .s-home__event--img {
      width: 100%;
      margin-bottom: 14px;
      overflow: hidden; }
      .s-home__event--img img {
        width: 100%;
        transition: 1s all; }
        .s-home__event--img img:hover {
          transform: scale(1.2, 1.2);
          transition: 1s all; }
    .s-home__event--title {
      text-align: left; }
      .s-home__event--title h3 {
        display: inline-block;
        font-size: 16px;
        font-size: 1rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
      .s-home__event--title span {
        display: block;
        font-size: 16px;
        font-size: 1rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        /*
        @include mq-up(md) {
          //display: inline;
          //margin-left: 1rem;
          //padding: 0 0.5rem;
        }
        */ }
  .s-home__info {
    width: 100%;
    padding: 0 1rem; }
    .s-home__info--inner {
      margin: 0 auto;
      max-width: 897px;
      background: rgba(255, 255, 255, 0.6);
      padding: 15px 1rem;
      padding-bottom: 25px; }
      @media screen and (min-width: 769px) {
        .s-home__info--inner {
          padding: 50px 117px;
          padding-bottom: 50px; } }
      .s-home__info--inner h2 {
        font-size: 34px;
        font-size: 2.125rem;
        /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
        font-family: 'Anton', sans-serif;
        font-weight: normal;
        display: inline-block;
        border-bottom: 4px solid #322E2E; }
        @media screen and (min-width: 769px) {
          .s-home__info--inner h2 {
            font-size: 40px;
            font-size: 2.5rem;
            /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
      .s-home__info--inner ol {
        list-style: none;
        margin-top: 28px;
        margin-bottom: 26px; }
        .s-home__info--inner ol li {
          padding-bottom: 14px;
          margin-bottom: 14px;
          text-align: left;
          font-size: 18px;
          font-size: 1.125rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
          .s-home__info--inner ol li:not(:last-child) {
            border-bottom: 1px dashed #000000; }
          .s-home__info--inner ol li a {
            font-weight: bold;
            color: #322E2E; }
            @media screen and (min-width: 769px) {
              .s-home__info--inner ol li a {
                display: flex; } }
            .s-home__info--inner ol li a span {
              display: block;
              font-weight: normal; }
              @media screen and (min-width: 769px) {
                .s-home__info--inner ol li a span {
                  display: inline;
                  margin-left: 2rem; } }
  .s-home__rental {
    text-align: center;
    padding: 4rem 1rem;
    padding-top: 40px;
    background-image: url("/images/home_rental_bg.jpg");
    background-repeat: repeat;
    position: relative; }
    @media screen and (min-width: 769px) {
      .s-home__rental {
        padding: 120px 1rem; } }
    .s-home__rental h2 {
      position: relative;
      display: inline-block; }
    .s-home__rental--head {
      display: inline-block;
      text-align: center;
      position: relative; }
    @media screen and (min-width: 769px) {
      .s-home__rental--headleft {
        position: absolute;
        top: 0px;
        display: inline-block;
        background-image: url("/images/home_rental_left.png");
        background-size: contain;
        height: 87px;
        width: 170px;
        left: -199px;
        /*
        //border-top: 8px solid $color_text_black;
        @include font-size(60);

        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/home_rental_left.png');
          width: 206px;
          height: 96px;
          left: -230px;
          bottom: 0px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/home_rental_right.png');
          width: 182px;
          height: 84px;
          right: -210px;
          bottom: 6px;
        }
        */ } }
  @media screen and (min-width: 769px) and (min-width: 1000px) {
    .s-home__rental--headleft {
      width: 227px;
      left: -283px; } }
    @media screen and (min-width: 769px) {
      .s-home__rental--headright {
        position: absolute;
        top: -6px;
        display: inline-block;
        background-image: url("/images/home_rental_right.png");
        background-size: contain;
        height: 101px;
        width: 103px;
        right: -144px; } }
  @media screen and (min-width: 769px) and (min-width: 1000px) {
    .s-home__rental--headright {
      width: 136px;
      right: -190px; } }
    .s-home__rental--headsub {
      margin-top: 10px;
      color: #505050;
      font-weight: bold;
      font-size: 16px;
      font-size: 1rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
      @media screen and (min-width: 769px) {
        .s-home__rental--headsub {
          font-size: 18px;
          font-size: 1.125rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
    .s-home__rental--inner {
      margin: 0 auto;
      max-width: 375px; }
      @media screen and (min-width: 769px) {
        .s-home__rental--inner {
          max-width: 917px; } }
    .s-home__rental ul {
      list-style: none;
      margin: 35px auto;
      max-width: 974px; }
      @media screen and (min-width: 769px) {
        .s-home__rental ul {
          display: flex;
          justify-content: space-between;
          margin: 53px 0;
          margin-bottom: 111px;
          width: calc(100% - 10px); } }
      .s-home__rental ul li {
        position: relative;
        max-width: calc(430px + 10px);
        margin: 0 auto;
        margin-bottom: 35px;
        padding-right: 10px; }
        @media screen and (min-width: 769px) {
          .s-home__rental ul li {
            padding-right: 0;
            margin: 0;
            width: 45%;
            max-width: calc(430px + 10px);
            max-height: calc(251px + 10px); } }
        .s-home__rental ul li a {
          display: block;
          background-color: #FFFFFF;
          border-top-right-radius: 40px;
          border: 5px solid #323232;
          color: #323232;
          position: relative;
          margin: 0 auto;
          margin-bottom: 0;
          width: 100%;
          height: 100%;
          padding: 10px; }
          .s-home__rental ul li a > img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
            max-height: 150px;
            border-top-right-radius: 20px; }
            @media screen and (min-width: 769px) {
              .s-home__rental ul li a > img {
                object-fit: contain;
                max-height: unset; } }
          .s-home__rental ul li a > div {
            width: 100%;
            position: absolute;
            bottom: 20px;
            font-weight: bold;
            font-size: 14px;
            font-size: 0.875rem;
            /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }
            @media screen and (min-width: 769px) {
              .s-home__rental ul li a > div {
                font-size: 26px;
                font-size: 1.625rem;
                /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
          .s-home__rental ul li a:hover {
            transform: translate(10px, 10px); }
        .s-home__rental ul li:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 10px;
          left: 10px;
          width: calc(100% - 10px);
          height: 100%;
          background-color: #323232;
          border-top-right-radius: 40px; }
          @media screen and (min-width: 769px) {
            .s-home__rental ul li:before {
              width: 100%; } }
    .s-home__rental--guide {
      max-width: 1370px;
      display: block;
      position: relative;
      background-size: contain; }
      .s-home__rental--guide:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 77.84615%; }
      @media screen and (min-width: 769px) {
        .s-home__rental--guide:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 32.74021%; } }
      .s-home__rental--guide:hover {
        opacity: 0.7; }
  .s-home__snow-live {
    text-align: center;
    padding: 4rem 1rem;
    padding-top: 40px;
    background-image: url("/images/bg.png");
    background-repeat: repeat;
    background-blend-mode: multiply;
    position: relative;
    /*
    h2 {
      position: relative;
      display: inline-block;
      margin-bottom: 30px;
    }
    */
    /*
    &--text {
      font-size: 0.9rem;
      text-align: center;
      margin-bottom: -30px;

      @include mq-up(md) {
        margin: 0 60px;
        margin-bottom: -30px;
      }

      >p {
        display: inline-block;
        text-align: left;

        @include mq-up(md) {
          text-align: center;
        }
      }
    }
    */ }
    @media screen and (min-width: 769px) {
      .s-home__snow-live {
        padding: 120px 1rem;
        padding-top: 0; } }
    .s-home__snow-live h2 {
      position: relative;
      display: inline-block; }
    .s-home__snow-live--head {
      display: inline-block;
      text-align: center;
      position: relative; }
    @media screen and (min-width: 769px) {
      .s-home__snow-live--headleft {
        position: absolute;
        display: inline-block;
        background-image: url("/images/snow-live_left.png");
        background-size: contain;
        /*
        top: -14px;
        height: 74px;
        width: 196px;
        left: -154px;
        */
        top: -15px;
        height: 87px;
        width: 230px;
        left: -217px;
        /*
        @include mq-up(lg) {
          width: 196px;
        }
        */ } }
    @media screen and (min-width: 769px) {
      .s-home__snow-live--headright {
        position: absolute;
        display: inline-block;
        background-image: url("/images/snow-live_right.png");
        background-size: contain;
        top: -9px;
        height: 86px;
        width: 200px;
        right: -190px;
        /*
        @include mq-up(lg) {
          width: 164px;
        }
        */ } }
    .s-home__snow-live--headsub {
      margin-top: 10px;
      color: #505050;
      font-weight: normal;
      text-align: left;
      font-size: 0.9rem; }
      @media screen and (min-width: 769px) {
        .s-home__snow-live--headsub {
          font-size: 1rem;
          text-align: center; } }
    .s-home__snow-live--list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin-top: -30px; }
    .s-home__snow-live--item {
      padding: 1rem;
      width: calc(100% / 2);
      text-align: left; }
      @media screen and (min-width: 769px) {
        .s-home__snow-live--item {
          width: calc(100% / 3); } }
      .s-home__snow-live--item .underconstruction {
        position: absolute;
        top: 0;
        left: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Noto Sans JP";
        font-weight: bold;
        font-size: 1.2rem;
        color: #666; }
        .s-home__snow-live--item .underconstruction:before {
          content: '';
          background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
          background-size: contain;
          position: absolute;
          width: 100%;
          height: 100%;
          max-width: 150px;
          background-position: center;
          opacity: 0.1; }
    .s-home__snow-live--pic {
      position: relative;
      background-color: #FFFFFF; }
      .s-home__snow-live--pic:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 62%; }
      .s-home__snow-live--pic figure {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .s-home__snow-live--pic figure img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover; }
    .s-home__snow-live--iframe {
      position: relative;
      overflow: hidden;
      height: 250px;
      background-color: #FFFFFF;
      /*
      >div {
        padding-top:calc(100% - 2vw);
      }
      */ }
      .s-home__snow-live--iframe iframe {
        width: 100%;
        height: 100%; }
      .s-home__snow-live--iframe a {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2; }
      .s-home__snow-live--iframe .fancybox-iframe {
        padding-top: 1rem; }
    .s-home__snow-live--questionnaire {
      display: inline-block;
      width: 100%;
      margin-top: 30px;
      background-image: url("/images/home_questionnaire-sp.png");
      background-size: contain;
      padding-top: 90.10417%; }
      @media screen and (min-width: 480px) {
        .s-home__snow-live--questionnaire {
          width: calc(100% - 2rem); } }
      @media screen and (min-width: 769px) {
        .s-home__snow-live--questionnaire {
          background-image: url("/images/home_questionnaire.png");
          max-width: 940px;
          padding-top: 51.59574%; } }
      @media screen and (min-width: 1200px) {
        .s-home__snow-live--questionnaire {
          height: 485px;
          padding-top: 0; } }
      .s-home__snow-live--questionnaire:hover {
        opacity: 0.7;
        cursor: pointer; }

.s-information__body {
  width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 769px) {
    .s-information__body {
      max-width: 1130px;
      padding: 0; } }

.s-information__inner {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 4rem; }
  .s-information__inner .attachment-post-thumbnail {
    display: block;
    margin: 0 auto 2rem; }

.s-information__date {
  margin-bottom: 1rem;
  font-size: .9rem;
  display: inline-block; }

.s-information__title {
  margin-top: 60px;
  margin-bottom: 2rem;
  font-size: 24px;
  font-size: 1.5rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
  font-weight: bold; }

.s-information__pics {
  position: relative;
  padding: 0 3rem;
  margin-top: 30px;
  margin-bottom: 2rem;
  /* 前へ次への矢印カスタマイズ */
  /* 前への矢印カスタマイズ */
  /* 次への矢印カスタマイズ */ }
  @media screen and (min-width: 769px) {
    .s-information__pics {
      padding: 0 5rem;
      margin-bottom: 105px;
      margin-top: 50px; } }
  .s-information__pics--swiper {
    box-sizing: content-box;
    margin: 0 58px;
    overflow: hidden;
    position: relative; }
    .s-information__pics--swiper:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 56.27178%; }
    .s-information__pics--swiper .swiper-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0; }
    .s-information__pics--swiper .swiper-slide {
      text-align: center;
      width: auto;
      max-width: calc(100% - 40px - 20px); }
      @media screen and (min-width: 769px) {
        .s-information__pics--swiper .swiper-slide {
          max-width: calc(100% - 80px - 40px); } }
      .s-information__pics--swiper .swiper-slide img {
        width: auto;
        height: 100%;
        object-fit: cover; }
  .s-information__pics .swiper-pagination-bullets {
    bottom: -28px !important; }
    @media screen and (min-width: 769px) {
      .s-information__pics .swiper-pagination-bullets {
        bottom: -50px !important; } }
    .s-information__pics .swiper-pagination-bullets .swiper-pagination-bullet {
      background-color: #FFFFFF;
      opacity: 1;
      border: 1px solid #787878; }
    .s-information__pics .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: #15b7ff;
      border: none; }
  .s-information__pics .swiper-button-prev,
  .s-information__pics .swiper-button-next {
    height: 30px;
    width: 30px;
    margin-top: 0; }
    @media screen and (min-width: 769px) {
      .s-information__pics .swiper-button-prev,
      .s-information__pics .swiper-button-next {
        height: 50px;
        width: 50px; } }
  .s-information__pics .swiper-button-prev,
  .s-information__pics .swiper-button-next {
    top: 50%;
    transform: translateY(-50%); }
    .s-information__pics .swiper-button-prev:after,
    .s-information__pics .swiper-button-next:after {
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      margin: auto;
      height: 30px;
      width: 30px; }
      @media screen and (min-width: 769px) {
        .s-information__pics .swiper-button-prev:after,
        .s-information__pics .swiper-button-next:after {
          height: 50px;
          width: 50px; } }
  .s-information__pics .swiper-button-disabled {
    visibility: hidden; }
  .s-information__pics .swiper-button-prev:after {
    background-image: url("/images/arrow_gray.png"); }
  .s-information__pics .swiper-button-next:after {
    background-image: url("/images/arrow_gray.png");
    transform: scaleX(-1); }

.s-information__content {
  margin-top: 30px;
  max-width: 850px;
  margin: 0 auto; }
  .s-information__content h1:not(:first-child), .s-information__content h2:not(:first-child), .s-information__content h3:not(:first-child), .s-information__content h4:not(:first-child), .s-information__content h5:not(:first-child), .s-information__content h6:not(:first-child) {
    margin-top: 2rem; }

.s-information__back {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid #322E2E;
  color: #322E2E; }

.s-information__previous {
  position: absolute;
  bottom: 1px;
  left: 1rem;
  color: #322E2E; }
  @media screen and (min-width: 769px) {
    .s-information__previous {
      left: 0; } }

.s-information__next {
  position: absolute;
  bottom: 1px;
  right: 1rem;
  color: #322E2E; }
  @media screen and (min-width: 769px) {
    .s-information__next {
      right: 0; } }

.s-informations__body {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  @media screen and (min-width: 769px) {
    .s-informations__body {
      width: 850px;
      padding: 0; } }
  .s-informations__body h1 {
    position: relative; }
    @media screen and (min-width: 769px) {
      .s-informations__body h1::before {
        position: absolute;
        content: '';
        background-image: url("/images/information_left.png");
        width: 129px;
        height: 70px;
        left: -143px;
        bottom: 26px; }
      .s-informations__body h1::after {
        position: absolute;
        content: '';
        background-image: url("/images/information_right.png");
        width: 127px;
        height: 76px;
        right: -141px;
        bottom: 24px; } }
  .s-informations__body ul {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 61px;
    text-align: left; }
    .s-informations__body ul li {
      position: relative;
      list-style: none;
      border-bottom: 1px solid #322E2E;
      /*
        &:first-child {
          border-top: 1px solid $color_black;
        }
        */ }
      .s-informations__body ul li:last-child {
        border-bottom: none; }
      .s-informations__body ul li a {
        display: block;
        padding: 26px 0;
        color: #322E2E;
        /*
          >img {
            width: 100%;
            object-fit: cover;

            @include mq-up(md) {
              width: 165px;
              height: 117px;
            }
          }
          */ }
        @media screen and (min-width: 769px) {
          .s-informations__body ul li a {
            display: flex;
            justify-content: flex-start;
            align-items: center; } }
        .s-informations__body ul li a:hover {
          opacity: 0.7; }
        .s-informations__body ul li a > figure {
          position: relative;
          margin: 0; }
          @media screen and (min-width: 769px) {
            .s-informations__body ul li a > figure {
              width: 190px; } }
          .s-informations__body ul li a > figure:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 66.84211%; }
          .s-informations__body ul li a > figure img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .s-informations__body ul li a > div {
          width: 100%; }
          @media screen and (min-width: 769px) {
            .s-informations__body ul li a > div {
              padding-left: 47px;
              width: calc(100% - 190px); } }
        .s-informations__body ul li a h2 {
          font-weight: normal;
          font-size: 16px;
          font-size: 1rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.s-informations__back {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid #322E2E; }

.s-informations__previous {
  position: absolute;
  bottom: .2rem;
  left: 1rem; }
  @media screen and (min-width: 769px) {
    .s-informations__previous {
      left: 0; } }

.s-informations__next {
  position: absolute;
  bottom: .2rem;
  right: 1rem; }
  @media screen and (min-width: 769px) {
    .s-informations__next {
      right: 0; } }

.s-menu {
  /* fancybox data-caption */ }
  .s-menu__head {
    position: relative;
    height: 150px; }
    @media screen and (min-width: 769px) {
      .s-menu__head {
        height: auto;
        min-height: 360px; } }
    .s-menu__head > img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover; }
      @media screen and (min-width: 769px) {
        .s-menu__head > img {
          height: auto;
          min-height: 360px; } }
    .s-menu__head > h1 {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%); }
      @media screen and (min-width: 769px) {
        .s-menu__head > h1 {
          bottom: 20px; } }
  .s-menu__body {
    margin-bottom: 2rem; }
    @media screen and (min-width: 769px) {
      .s-menu__body {
        max-width: 1028px;
        margin: 4rem auto;
        /*margin-: 2rem;*/ } }
  .s-menu__date {
    text-align: center;
    margin-top: 2rem; }
    @media screen and (min-width: 769px) {
      .s-menu__date {
        margin-top: 0; } }
  .s-menu__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center; }
    .s-menu__list > li {
      width: 100%;
      padding: 1rem;
      position: relative; }
      @media screen and (min-width: 426px) {
        .s-menu__list > li {
          width: calc(100% / 2); } }
      @media screen and (min-width: 769px) {
        .s-menu__list > li {
          width: calc(100% / 3); } }
      .s-menu__list > li > a {
        display: block;
        background-color: pink;
        height: 100%;
        position: relative;
        padding-bottom: 3rem;
        text-align: center; }
        .s-menu__list > li > a:hover {
          background-color: #FFFFFF;
          filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3)); }
        .s-menu__list > li > a > div {
          margin-top: 1rem; }
      .s-menu__list > li:after {
        content: '▼';
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%); }
    .s-menu__list--eng {
      font-size: 24px;
      font-size: 1.5rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      font-family: "EB Garamond", serif; }
  .s-menu .fancybox-caption__body {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100%);
    min-height: 44px;
    display: flex;
    padding: 0 88px;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3); }

.s-no-template__body {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 12rem; }

.s-no-template__inner {
  width: 100%;
  position: relative;
  text-align: center; }

.s-no-template__detail {
  margin-top: 60px;
  text-align: left; }

.s-privacy__body {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 12rem; }

.s-privacy__inner {
  width: 100%;
  position: relative;
  text-align: center;
  word-wrap: break-word; }

.s-privacy__detail {
  margin-top: 60px;
  text-align: left; }
  .s-privacy__detail h2:nth-child(n + 1) {
    margin-top: 2rem; }

.s-rental {
  /*
  &__ski {
    h2 {
      color: #466496;
      font-weight: 900;
      text-align: left;
      margin-bottom: 2rem;
      @include mq-up(30);
    }
    h3 {
      background-color: #466496;
      border-radius: 10px;
      color: $color_text_white;
      padding: 0.5rem 1rem;
    }
  }
  */
  /*
  &__intro {
    text-align: left;
    margin: 2rem 0;
    padding: 0 1rem;

    @include mq-up(md) {
      text-align: center;
      padding: 0;
    }
  }
  */ }
  .s-rental__image {
    width: 100%;
    margin-bottom: 0; }
    .s-rental__image > img {
      width: 100%; }
  .s-rental__body {
    width: 100%;
    margin: 0 auto; }
    .s-rental__body .breadcrumbs {
      max-width: 1130px; }
  .s-rental__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center; }
    .s-rental__inner h1 {
      position: relative; }
      @media screen and (min-width: 769px) {
        .s-rental__inner h1::before {
          position: absolute;
          content: '';
          background-image: url("/images/rental_left.png");
          width: 261px;
          height: 103px;
          left: -276px;
          bottom: 4px; }
        .s-rental__inner h1::after {
          position: absolute;
          content: '';
          background-image: url("/images/rental_right.png");
          width: 280px;
          height: 89px;
          right: -291px;
          bottom: 14px; } }
  .s-rental__cycle, .s-rental__ski {
    margin-top: 60px;
    width: calc(100% + 2rem);
    margin-left: -1rem; }
    .s-rental__cycle h2, .s-rental__ski h2 {
      font-weight: 900;
      text-align: left;
      font-size: 25px;
      font-size: 1.5625rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      margin: 0 auto;
      margin-bottom: 2rem;
      padding: 0 1rem;
      max-width: calc(1130px + 2rem); }
      @media screen and (min-width: 769px) {
        .s-rental__cycle h2, .s-rental__ski h2 {
          font-size: 30px;
          font-size: 1.875rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
    .s-rental__cycle h3, .s-rental__ski h3 {
      border-radius: 10px;
      color: #FFFFFF;
      padding: 0.5rem 1rem;
      display: inline-block;
      font-size: 18px;
      font-size: 1.125rem;
      /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
      margin-bottom: 1rem; }
      @media screen and (min-width: 769px) {
        .s-rental__cycle h3, .s-rental__ski h3 {
          font-size: 22px;
          font-size: 1.375rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
  .s-rental__cycle {
    margin-bottom: 60px; }
    @media screen and (min-width: 769px) {
      .s-rental__cycle {
        margin-bottom: 140px; } }
    .s-rental__cycle h2 {
      color: #1e9c9d; }
    .s-rental__cycle h3 {
      background-color: #1e9c9d; }
  .s-rental__ski h2 {
    color: #466496; }
  .s-rental__ski h3 {
    background-color: #466496; }
  .s-rental__ski ul {
    padding-bottom: 260px;
    margin-bottom: -260px; }
  .s-rental__list {
    width: 100%;
    margin: 0 auto;
    list-style: none;
    padding: 0 1rem;
    background-color: #f0f0f0;
    padding-top: 60px;
    padding-bottom: 60px; }
    @media screen and (min-width: 769px) {
      .s-rental__list {
        list-style: none;
        padding-top: 140px;
        padding-bottom: 140px; } }
    .s-rental__list li {
      width: 100%;
      max-width: 1130px;
      margin: 0 auto;
      margin-bottom: 4rem;
      display: block;
      text-align: center; }
      .s-rental__list li:last-of-type {
        margin-bottom: 0; }
      @media screen and (min-width: 769px) {
        .s-rental__list li {
          display: flex;
          text-align: left;
          margin-bottom: 2rem; }
          .s-rental__list li:last-of-type {
            margin-bottom: 0; } }
      .s-rental__list li > figure {
        position: relative;
        width: 100%; }
        @media screen and (min-width: 769px) {
          .s-rental__list li > figure {
            width: 30%; } }
        .s-rental__list li > figure:before {
          display: block;
          content: "";
          width: 100%; }
        .s-rental__list li > figure img {
          object-fit: contain;
          width: 100%;
          /*
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          */ }
      .s-rental__list li > div {
        text-align: left;
        width: 100%; }
        @media screen and (min-width: 769px) {
          .s-rental__list li > div {
            width: 70%; } }
        .s-rental__list li > div p {
          font-weight: bold;
          font-size: 18px;
          font-size: 1.125rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
          margin-bottom: 1rem; }
          @media screen and (min-width: 769px) {
            .s-rental__list li > div p {
              font-size: 22px;
              font-size: 1.375rem;
              /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ } }
        @media screen and (min-width: 769px) {
          .s-rental__list li > div {
            padding-left: 2rem;
            font-weight: bold; } }

.s-search__body {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  @media screen and (min-width: 769px) {
    .s-search__body {
      width: 850px;
      padding: 0; } }
  .s-search__body ul {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 61px; }
    .s-search__body ul li {
      position: relative;
      list-style: none;
      border-bottom: 1px solid #322E2E; }
      .s-search__body ul li:first-child {
        border-top: 1px solid #322E2E; }
      .s-search__body ul li a {
        display: block;
        padding: 17px;
        text-align: left; }
        @media screen and (min-width: 769px) {
          .s-search__body ul li a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 26px; } }
        .s-search__body ul li a h2 {
          font-weight: normal;
          font-size: 16px;
          font-size: 1rem;
          /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
          margin-top: 0.5rem; }
          @media screen and (min-width: 769px) {
            .s-search__body ul li a h2 {
              margin-top: 0; } }

.s-search__category {
  background-color: #000000;
  color: #FFFFFF;
  padding: 0 1rem;
  margin-right: 1rem; }

.s-search__back {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid #322E2E; }

.s-search__previous {
  position: absolute;
  bottom: .2rem;
  left: 1rem; }
  @media screen and (min-width: 769px) {
    .s-search__previous {
      left: 0; } }

.s-search__next {
  position: absolute;
  bottom: .2rem;
  right: 1rem; }
  @media screen and (min-width: 769px) {
    .s-search__next {
      right: 0; } }

.s-snow-live_hobanttaihi {
  /*
  &__intro {
    text-align: left;
    margin: 2rem 0;
    padding: 0 1rem;

    @include mq-up(md) {
      text-align: center;
      padding: 0;
    }
  }
  */
  /*
  &__img {
    width: 100%;
    max-width: 337px;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-size: cover;

    &:before {
      display: block;
      content: '';
      padding-top: 100%;
    }

  }
  */ }
  .s-snow-live_hobanttaihi .l-main__body {
    position: relative;
    overflow: hidden;
    margin-top: 0;
    padding: 0;
    padding-top: 1rem; }
  .s-snow-live_hobanttaihi__logo {
    position: fixed;
    top: 1rem;
    left: 1rem;
    /* filter: drop-shadow(3px 3px 1px gainsboro); */ }
    .s-snow-live_hobanttaihi__logo img {
      height: auto;
      max-width: 100%;
      vertical-align: bottom;
      width: 110px;
      height: 75px; }
  .s-snow-live_hobanttaihi__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1; }
    .s-snow-live_hobanttaihi__inner h1 {
      position: relative;
      font-size: 2rem;
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 2rem; }
  .s-snow-live_hobanttaihi__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 1rem;
    text-align: left;
    justify-content: space-between; }
    .s-snow-live_hobanttaihi__list li {
      width: 100%;
      text-align: left;
      flex-basis: calc(100% / 3 - 0.5rem);
      margin-bottom: 1rem;
      position: relative;
      transition: all 0.3s linear;
      border: solid 1px #333;
      border-radius: 5px;
      box-shadow: 5px 5px 3px gainsboro; }
      .s-snow-live_hobanttaihi__list li:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none; }
      .s-snow-live_hobanttaihi__list li > a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem; }
      .s-snow-live_hobanttaihi__list li h2 {
        margin-bottom: 0.5rem;
        color: #322E2E; }
      .s-snow-live_hobanttaihi__list li div {
        text-align: left; }
  .s-snow-live_hobanttaihi__detail {
    display: flex;
    gap: 0;
    margin: 5rem 1rem 1rem 5rem;
    align-items: flex-start; }
    .s-snow-live_hobanttaihi__detail--pic {
      flex-basis: 60%;
      overflow: hidden;
      position: relative; }
      .s-snow-live_hobanttaihi__detail--pic > figure {
        height: 100%;
        margin: 0; }
        .s-snow-live_hobanttaihi__detail--pic > figure > img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .s-snow-live_hobanttaihi__detail--iframe {
      flex-basis: 40%;
      position: relative;
      overflow: hidden;
      padding-top: 60vh;
      /*
        >div {
          padding-top:calc(100% - 2vw);
        }
        */ }
      .s-snow-live_hobanttaihi__detail--iframe iframe {
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
  .s-snow-live_hobanttaihi .underconstruction {
    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans JP";
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    /*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */ }
  .s-snow-live_hobanttaihi .underconstruction:before {
    content: '';
    background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 150px;
    background-position: center;
    opacity: 0.1; }
  .s-snow-live_hobanttaihi__back {
    width: 100%;
    text-align: center; }
    .s-snow-live_hobanttaihi__back > a {
      display: inline-block;
      border-radius: 5px;
      background-color: white;
      border: solid 1px #333;
      color: #333;
      font-weight: bold;
      box-shadow: 5px 5px 3px gainsboro;
      padding: calc(0.667em + 2px) calc(1.333em + 2px);
      font-size: 1.125em;
      width: 50%; }
      .s-snow-live_hobanttaihi__back > a:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none; }
  .s-snow-live_hobanttaihi .p-footer__main {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: white;
    z-index: 0; }
    .s-snow-live_hobanttaihi .p-footer__main:before {
      top: -50px; }
  .s-snow-live_hobanttaihi .p-footer__copyright,
  .s-snow-live_hobanttaihi .p-footer__main--menu,
  .s-snow-live_hobanttaihi .p-footer__main--logo {
    display: none; }

.s-snow-live {
  /*
  &__intro {
    text-align: left;
    margin: 2rem 0;
    padding: 0 1rem;

    @include mq-up(md) {
      text-align: center;
      padding: 0;
    }
  }
  */
  /*
  &__img {
    width: 100%;
    max-width: 337px;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-size: cover;

    &:before {
      display: block;
      content: '';
      padding-top: 100%;
    }

  }
  */ }
  .s-snow-live .l-main__body {
    position: relative;
    overflow: hidden;
    margin-top: 0;
    padding: 0;
    padding-top: 1rem; }
  .s-snow-live__logo {
    position: fixed;
    top: 1rem;
    left: 1rem;
    /* filter: drop-shadow(3px 3px 1px gainsboro); */ }
    .s-snow-live__logo img {
      height: auto;
      max-width: 100%;
      vertical-align: bottom;
      width: 110px;
      height: 75px; }
  .s-snow-live__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1; }
    .s-snow-live__inner h1 {
      position: relative;
      font-size: 2rem;
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 2rem; }
  .s-snow-live__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 1rem;
    text-align: left;
    justify-content: space-between; }
    .s-snow-live__list li {
      width: 100%;
      text-align: left;
      flex-basis: calc(100% / 3 - 0.5rem);
      margin-bottom: 1rem;
      position: relative;
      transition: all 0.3s linear;
      border: solid 1px #333;
      border-radius: 5px;
      box-shadow: 5px 5px 3px gainsboro; }
      .s-snow-live__list li:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none; }
      .s-snow-live__list li > a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem; }
      .s-snow-live__list li h2 {
        margin-bottom: 0.5rem;
        color: #322E2E; }
      .s-snow-live__list li div {
        text-align: left; }
  .s-snow-live__detail {
    display: flex;
    gap: 0;
    margin: 5rem 1rem 1rem 5rem;
    align-items: flex-start; }
    .s-snow-live__detail--pic {
      flex-basis: 60%;
      overflow: hidden;
      position: relative; }
      .s-snow-live__detail--pic > figure {
        height: 100%;
        margin: 0; }
        .s-snow-live__detail--pic > figure > img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .s-snow-live__detail--iframe {
      flex-basis: 40%;
      position: relative;
      overflow: hidden;
      padding-top: 60vh;
      /*
        >div {
          padding-top:calc(100% - 2vw);
        }
        */ }
      .s-snow-live__detail--iframe iframe {
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
  .s-snow-live .underconstruction {
    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans JP";
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    /*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */ }
  .s-snow-live .underconstruction:before {
    content: '';
    background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 150px;
    background-position: center;
    opacity: 0.1; }
  .s-snow-live__back {
    width: 100%;
    text-align: center; }
    .s-snow-live__back > a {
      display: inline-block;
      border-radius: 5px;
      background-color: white;
      border: solid 1px #333;
      color: #333;
      font-weight: bold;
      box-shadow: 5px 5px 3px gainsboro;
      padding: calc(0.667em + 2px) calc(1.333em + 2px);
      font-size: 1.125em;
      width: 50%; }
      .s-snow-live__back > a:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none; }
  .s-snow-live .p-footer__main {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: white;
    z-index: 0; }
    .s-snow-live .p-footer__main:before {
      top: -50px; }
  .s-snow-live .p-footer__copyright,
  .s-snow-live .p-footer__main--menu,
  .s-snow-live .p-footer__main--logo {
    display: none; }

.s-snow-lives_taihi .l-main__body {
  position: relative;
  overflow: hidden;
  margin-top: 0;
  padding-top: 1rem;
  padding-bottom: 0; }

.s-snow-lives_taihi__logo {
  position: fixed;
  top: 1rem;
  left: 1rem;
  /* filter: drop-shadow(3px 3px 1px gainsboro); */ }
  .s-snow-lives_taihi__logo img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
    width: 110px;
    height: 75px; }

.s-snow-lives_taihi__inner {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1; }
  .s-snow-lives_taihi__inner h1 {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem; }
    @media screen and (min-width: 769px) {
      .s-snow-lives_taihi__inner h1::before {
        content: '';
        position: absolute;
        top: -9px;
        display: inline-block;
        background-image: url("/images/snow-live_left.png");
        background-size: contain;
        height: 60px;
        width: 196px;
        left: -172px; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives_taihi__inner h1::after {
        content: '';
        position: absolute;
        top: -6px;
        display: inline-block;
        background-image: url("/images/snow-live_right.png");
        background-size: contain;
        height: 60px;
        width: 164px;
        right: -182px; } }

.s-snow-lives_taihi__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 1rem;
  text-align: left;
  justify-content: space-between; }
  .s-snow-lives_taihi__list li {
    width: 100%;
    text-align: left;
    flex-basis: calc(100%);
    margin-bottom: 1rem;
    position: relative;
    transition: all 0.3s linear;
    border: solid 1px #333;
    border-radius: 5px;
    box-shadow: 5px 5px 3px gainsboro;
    padding: 1rem 0.5rem;
    /*
      &:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none;
      }
      */
    /*
      >a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
      }
      */ }
    @media screen and (min-width: 426px) {
      .s-snow-lives_taihi__list li {
        flex-basis: calc(100% / 2 - 0.5rem);
        display: flex;
        flex-direction: column;
        background-color: purple; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives_taihi__list li {
        flex-basis: calc(100% / 3 - 0.5rem); } }
    @media screen and (min-width: 1200px) {
      .s-snow-lives_taihi__list li {
        height: 30vh; } }
    .s-snow-lives_taihi__list li h2 {
      margin-bottom: 0.5rem;
      color: #322E2E; }
    .s-snow-lives_taihi__list li div {
      text-align: left; }

.s-snow-lives_taihi__detail {
  display: flex;
  gap: 0;
  margin: 0;
  align-items: normal !important;
  height: 100%;
  overflow: hidden; }
  .s-snow-lives_taihi__detail--pic {
    flex-basis: 55%;
    overflow: hidden;
    position: relative; }
    .s-snow-lives_taihi__detail--pic:hover {
      opacity: 0.7;
      cursor: pointer; }
    .s-snow-lives_taihi__detail--pic > figure {
      height: 100%;
      margin: 0; }
      .s-snow-lives_taihi__detail--pic > figure > img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .s-snow-lives_taihi__detail--iframe {
    flex-basis: 45%;
    position: relative;
    overflow: hidden; }
    .s-snow-lives_taihi__detail--iframe:hover {
      opacity: 0.7; }
    .s-snow-lives_taihi__detail--iframe > div {
      position: relative;
      width: 100%;
      padding: calc(315 / 560 * 100%) 0 0; }
    .s-snow-lives_taihi__detail--iframe iframe {
      border: none;
      width: calc(100% + 20px);
      position: absolute;
      top: 0;
      left: -10px;
      height: calc(100% + 42px); }
      .s-snow-lives_taihi__detail--iframe iframe:after {
        content: '';
        position: absolute;
        top: 0; }
    .s-snow-lives_taihi__detail--iframe a {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }
    .s-snow-lives_taihi__detail--iframe .fancybox-iframe {
      padding-top: 1rem; }

.s-snow-lives_taihi .underconstruction {
  position: absolute;
  top: 0;
  left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.2rem;
  color: #666;
  /*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */ }

.s-snow-lives_taihi .underconstruction:before {
  content: '';
  background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 150px;
  background-position: center;
  opacity: 0.1; }

.s-snow-lives_taihi .l-footer {
  padding-bottom: 0; }

.s-snow-lives_taihi .p-footer__main {
  padding: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  z-index: 0; }
  .s-snow-lives_taihi .p-footer__main:before {
    top: 0; }
    @media screen and (min-width: 769px) {
      .s-snow-lives_taihi .p-footer__main:before {
        top: -50px; } }

.s-snow-lives_taihi .p-footer__copyright,
.s-snow-lives_taihi .p-footer__main--menu,
.s-snow-lives_taihi .p-footer__main--logo {
  display: none; }

/* SNOW LIVE 編集中のもの */
.s-snow-lives_edit .l-main__body {
  position: relative;
  overflow: hidden;
  margin-top: 0;
  padding-top: 1rem; }
  @media screen and (min-width: 426px) {
    .s-snow-lives_edit .l-main__body {
      padding-bottom: 0; } }

.s-snow-lives_edit__logo {
  position: absolute; }
  @media screen and (min-width: 426px) {
    .s-snow-lives_edit__logo {
      position: fixed;
      top: 1rem;
      left: 1rem;
      /* filter: drop-shadow(3px 3px 1px gainsboro); */ } }
  .s-snow-lives_edit__logo img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
    width: 110px;
    height: 75px; }

.s-snow-lives_edit__inner {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1; }
  .s-snow-lives_edit__inner h1 {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    font-weight: 400;
    display: inline-block;
    margin-top: 1rem;
    font-family: 'Anton', sans-serif; }
    @media screen and (max-width: 425px) {
      .s-snow-lives_edit__inner h1 {
        margin-top: 100px; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives_edit__inner h1::before {
        content: '';
        position: absolute;
        top: -9px;
        display: inline-block;
        background-image: url("/images/snow-live_left.png");
        background-size: contain;
        height: 60px;
        width: 130px;
        left: -136px; } }
    @media screen and (min-width: 1000px) {
      .s-snow-lives_edit__inner h1::before {
        height: 60px;
        width: 196px;
        left: -172px; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives_edit__inner h1::after {
        content: '';
        position: absolute;
        top: -6px;
        display: inline-block;
        background-image: url("/images/snow-live_right.png");
        background-size: contain;
        height: 60px;
        width: 120px;
        right: -131px; } }
    @media screen and (min-width: 1000px) {
      .s-snow-lives_edit__inner h1::after {
        height: 60px;
        width: 164px;
        right: -182px; } }

.s-snow-lives_edit__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: left;
  justify-content: center; }
  .s-snow-lives_edit__list li {
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
    position: relative;
    transition: all 0.3s linear;
    border: solid 1px #333;
    border-radius: 5px;
    box-shadow: 5px 5px 3px gainsboro;
    padding: 1rem 0.5rem;
    /*
      &:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none;
      }
      */
    /*
      >a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
      }
      */ }
    @media screen and (min-width: 426px) {
      .s-snow-lives_edit__list li {
        max-width: calc(100% / 2 - 0.5rem);
        min-width: calc(100% / 3 + 1px);
        width: auto; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives_edit__list li {
        max-width: calc(100% / 3 - 0.5rem);
        min-width: calc(100% / 4 + 1px);
        width: auto; } }
    .s-snow-lives_edit__list li h2 {
      margin-bottom: 0.5rem;
      color: #322E2E; }
    .s-snow-lives_edit__list li div {
      text-align: left; }

.s-snow-lives_edit__detail {
  gap: 0;
  margin: 0;
  align-items: normal !important;
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 2rem); }
  .s-snow-lives_edit__detail--pic {
    overflow: hidden;
    position: relative; }
    .s-snow-lives_edit__detail--pic:hover {
      opacity: 0.7;
      cursor: pointer; }
    .s-snow-lives_edit__detail--pic a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative; }
    .s-snow-lives_edit__detail--pic figure {
      margin: 0;
      position: relative;
      max-width: 100%;
      max-height: 100%; }
      .s-snow-lives_edit__detail--pic figure > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover; }
  .s-snow-lives_edit__detail--iframe {
    position: relative;
    overflow: hidden; }
    .s-snow-lives_edit__detail--iframe:hover {
      opacity: 0.7;
      cursor: pointer; }
    .s-snow-lives_edit__detail--iframe a {
      display: block;
      width: 100%;
      height: 100%;
      z-index: 2; }
      .s-snow-lives_edit__detail--iframe a > figure {
        margin: 0;
        position: relative;
        max-width: 100%;
        max-height: 100%; }
        .s-snow-lives_edit__detail--iframe a > figure > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-height: 100%;
          max-width: 100%;
          object-fit: cover;
          object-position: top; }
    .s-snow-lives_edit__detail--iframe .fancybox-iframe {
      padding-top: 1rem; }

.s-snow-lives_edit .underconstruction {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.2rem;
  color: #666;
  /*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */ }

.s-snow-lives_edit .underconstruction:before {
  content: '';
  background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 150px;
  background-position: center;
  opacity: 0.1; }

.s-snow-lives_edit .l-footer {
  padding-bottom: 0; }

@media screen and (min-width: 426px) {
  .s-snow-lives_edit .p-footer__main {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 0; }
    .s-snow-lives_edit .p-footer__main:before {
      top: 0; } }
    @media screen and (min-width: 426px) and (min-width: 769px) {
      .s-snow-lives_edit .p-footer__main:before {
        top: -50px; } }

.s-snow-lives_edit .p-footer__copyright,
.s-snow-lives_edit .p-footer__main--menu,
.s-snow-lives_edit .p-footer__main--logo {
  display: none; }

.s-snow-lives_new .l-main__body {
  position: relative;
  overflow: hidden;
  margin-top: 0;
  padding-top: 1rem;
  padding-bottom: 0; }

.s-snow-lives_new__logo {
  position: relative;
  z-index: 1; }
  @media screen and (min-width: 426px) {
    .s-snow-lives_new__logo {
      position: fixed;
      top: 1rem;
      left: 1rem;
      width: 10%; } }
  @media screen and (min-width: 769px) {
    .s-snow-lives_new__logo {
      width: auto; } }
  .s-snow-lives_new__logo img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
    width: 110px;
    height: auto; }
    @media screen and (min-width: 769px) {
      .s-snow-lives_new__logo img {
        height: 75px; } }

.s-snow-lives_new__inner {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  padding-bottom: 160px; }
  @media screen and (min-width: 426px) {
    .s-snow-lives_new__inner {
      padding-bottom: 0; } }
  .s-snow-lives_new__inner h1 {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    /* 本番は以下コメントアウト */
    font-weight: 400;
    display: inline-block;
    margin-top: 1rem;
    font-family: 'Anton', sans-serif;
    /* 本番は以下コメントアウト */ }
    @media screen and (min-width: 769px) {
      .s-snow-lives_new__inner h1::before {
        content: '';
        position: absolute;
        top: -9px;
        display: inline-block;
        background-image: url("/images/snow-live_left.png");
        background-size: contain;
        height: 60px;
        width: 196px;
        left: -172px; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives_new__inner h1::after {
        content: '';
        position: absolute;
        top: -6px;
        display: inline-block;
        background-image: url("/images/snow-live_right.png");
        background-size: contain;
        height: 60px;
        width: 164px;
        right: -182px; } }

.s-snow-lives_new__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: left;
  justify-content: space-between; }
  .s-snow-lives_new__list li {
    width: 100%;
    text-align: left;
    flex-basis: calc(100%);
    margin-bottom: 1rem;
    position: relative;
    transition: all 0.3s linear;
    border: solid 1px #333;
    border-radius: 5px;
    box-shadow: 5px 5px 3px gainsboro;
    padding: 1rem 0.5rem;
    /*
      &:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none;
      }
      */
    /*
      >a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
      }
      */ }
    @media screen and (min-width: 426px) {
      .s-snow-lives_new__list li {
        flex-basis: calc(100% / 2 - 0.5rem);
        max-height: 16vh;
        margin-bottom: 1rem; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives_new__list li {
        flex-basis: calc(100% / 3 - 0.5rem);
        max-height: 23vmin;
        margin-bottom: 1rem; } }
    .s-snow-lives_new__list li h2 {
      margin-bottom: 0.5rem;
      color: #322E2E;
      font-size: 1.2rem; }
      @media screen and (min-width: 426px) {
        .s-snow-lives_new__list li h2 {
          font-size: 3.2vmin; } }
      @media screen and (min-width: 769px) {
        .s-snow-lives_new__list li h2 {
          font-size: 1.2rem; } }
    .s-snow-lives_new__list li div {
      text-align: left; }

.s-snow-lives_new__detail {
  display: flex;
  gap: 0;
  margin: 0;
  align-items: normal !important;
  height: 100%;
  overflow: hidden; }
  .s-snow-lives_new__detail--pic {
    flex-basis: 55%;
    overflow: hidden;
    position: relative;
    /*
      figure {
        height: 100%;
        margin: 0;


        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          //max-height: 100%;
          //height: 20vh;
        }
      }
      */ }
    .s-snow-lives_new__detail--pic:hover {
      opacity: 0.7;
      cursor: pointer; }
    .s-snow-lives_new__detail--pic figure {
      width: 100%;
      height: 100%;
      position: relative;
      /*
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        */
      /*
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (992 / 1280) * 100%;
        }
        */ }
      .s-snow-lives_new__detail--pic figure img {
        /*
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          //object-fit: contain;
          //object-position: top;
          object-position: center;
          */
        max-height: 100%;
        position: relative;
        height: 100%;
        object-fit: cover;
        object-position: center; }
  .s-snow-lives_new__detail--iframe {
    flex-basis: 45%;
    position: relative;
    overflow: hidden; }
    .s-snow-lives_new__detail--iframe:hover {
      opacity: 0.7; }
    .s-snow-lives_new__detail--iframe > div {
      position: relative;
      width: 100%;
      height: 100%; }
    .s-snow-lives_new__detail--iframe figure {
      width: 100%;
      height: 100%;
      position: relative;
      /*
          &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (992 / 1280) * 100%;
          }
          */ }
      .s-snow-lives_new__detail--iframe figure img {
        /*
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 105%;
            object-fit: cover;
            object-position: top;
            */
        position: relative;
        clip-path: inset(0 0 5% 0);
        height: 105%;
        object-fit: cover;
        object-position: center; }
    .s-snow-lives_new__detail--iframe a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 2; }
    .s-snow-lives_new__detail--iframe .fancybox-iframe {
      padding-top: 1rem; }

.s-snow-lives_new .underconstruction {
  position: absolute;
  top: 0;
  left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.2rem;
  color: #666;
  /*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */ }

.s-snow-lives_new .underconstruction:before {
  content: '';
  background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 150px;
  background-position: center;
  opacity: 0.1; }

.s-snow-lives_new .l-footer {
  padding-bottom: 0; }

.s-snow-lives_new .p-footer__main {
  padding: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  z-index: 0; }
  @media screen and (min-width: 426px) {
    .s-snow-lives_new .p-footer__main {
      position: fixed; } }
  .s-snow-lives_new .p-footer__main:before {
    top: 0; }
    @media screen and (min-width: 769px) {
      .s-snow-lives_new .p-footer__main:before {
        top: -50px; } }

.s-snow-lives_new .p-footer__copyright,
.s-snow-lives_new .p-footer__main--menu,
.s-snow-lives_new .p-footer__main--logo {
  display: none; }

.s-snow-lives_old {
  /*
  &__intro {
    text-align: left;
    margin: 2rem 0;
    padding: 0 1rem;

    @include mq-up(md) {
      text-align: center;
      padding: 0;
    }
  }
  */
  /*
  &__img {
    width: 100%;
    max-width: 337px;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-size: cover;

    &:before {
      display: block;
      content: '';
      padding-top: 100%;
    }

  }
  */ }
  .s-snow-lives_old .l-main__body {
    position: relative;
    overflow: hidden;
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 0; }
  .s-snow-lives_old__logo {
    position: fixed;
    top: 1rem;
    left: 1rem;
    /* filter: drop-shadow(3px 3px 1px gainsboro); */ }
    .s-snow-lives_old__logo img {
      height: auto;
      max-width: 100%;
      vertical-align: bottom;
      width: 110px;
      height: 75px; }
  .s-snow-lives_old__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1; }
    .s-snow-lives_old__inner h1 {
      position: relative;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 2rem; }
  .s-snow-lives_old__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 1rem;
    text-align: left;
    justify-content: space-between; }
    .s-snow-lives_old__list li {
      width: 100%;
      text-align: left;
      flex-basis: calc(100% / 3 - 0.5rem);
      margin-bottom: 1rem;
      position: relative;
      transition: all 0.3s linear;
      border: solid 1px #333;
      border-radius: 5px;
      box-shadow: 5px 5px 3px gainsboro; }
      .s-snow-lives_old__list li:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none; }
      .s-snow-lives_old__list li > a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem; }
      .s-snow-lives_old__list li h2 {
        margin-bottom: 0.5rem;
        color: #322E2E; }
      .s-snow-lives_old__list li div {
        text-align: left; }
  .s-snow-lives_old__detail {
    display: flex;
    gap: 0;
    margin: 0;
    align-items: normal !important; }
    .s-snow-lives_old__detail--pic {
      flex-basis: 55%;
      overflow: hidden;
      position: relative; }
      .s-snow-lives_old__detail--pic > figure {
        height: 100%;
        margin: 0; }
        .s-snow-lives_old__detail--pic > figure > img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .s-snow-lives_old__detail--iframe {
      flex-basis: 45%;
      position: relative;
      overflow: hidden; }
      .s-snow-lives_old__detail--iframe > div {
        padding-top: calc(100% - 2vw); }
      .s-snow-lives_old__detail--iframe iframe {
        border: none;
        width: calc(100% + 20px);
        height: 100%;
        position: absolute;
        top: 0;
        left: -10px;
        z-index: -1; }
  .s-snow-lives_old .underconstruction {
    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans JP";
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    /*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */ }
  .s-snow-lives_old .underconstruction:before {
    content: '';
    background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 150px;
    background-position: center;
    opacity: 0.1; }
  .s-snow-lives_old .p-footer__main {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: white;
    z-index: 0; }
    .s-snow-lives_old .p-footer__main:before {
      top: -50px; }
  .s-snow-lives_old .p-footer__copyright,
  .s-snow-lives_old .p-footer__main--menu,
  .s-snow-lives_old .p-footer__main--logo {
    display: none; }

.s-snow-lives .l-main__body {
  position: relative;
  overflow: hidden;
  margin-top: 0;
  padding-top: 1rem; }
  @media screen and (min-width: 426px) {
    .s-snow-lives .l-main__body {
      padding-bottom: 0; } }

.s-snow-lives__logo {
  position: absolute; }
  @media screen and (min-width: 426px) {
    .s-snow-lives__logo {
      position: fixed;
      top: 1rem;
      left: 1rem;
      /* filter: drop-shadow(3px 3px 1px gainsboro); */ } }
  .s-snow-lives__logo img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
    width: 110px;
    height: 75px; }

.s-snow-lives__inner {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1; }
  .s-snow-lives__inner h1 {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    font-weight: 400;
    display: inline-block;
    margin-top: 1rem;
    font-family: 'Anton', sans-serif; }
    @media screen and (max-width: 425px) {
      .s-snow-lives__inner h1 {
        margin-top: 100px; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives__inner h1::before {
        content: '';
        position: absolute;
        top: -9px;
        display: inline-block;
        background-image: url("/images/snow-live_left.png");
        background-size: contain;
        height: 60px;
        width: 130px;
        left: -136px; } }
    @media screen and (min-width: 1000px) {
      .s-snow-lives__inner h1::before {
        height: 60px;
        width: 196px;
        left: -172px; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives__inner h1::after {
        content: '';
        position: absolute;
        top: -6px;
        display: inline-block;
        background-image: url("/images/snow-live_right.png");
        background-size: contain;
        height: 60px;
        width: 120px;
        right: -131px; } }
    @media screen and (min-width: 1000px) {
      .s-snow-lives__inner h1::after {
        height: 60px;
        width: 164px;
        right: -182px; } }

.s-snow-lives__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: left;
  justify-content: center; }
  .s-snow-lives__list li {
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
    position: relative;
    transition: all 0.3s linear;
    border: solid 1px #333;
    border-radius: 5px;
    box-shadow: 5px 5px 3px gainsboro;
    padding: 1rem 0.5rem;
    /*
      &:hover {
        margin: 5px -5px calc(-5px + 1rem) 5px;
        box-shadow: none;
      }
      */
    /*
      >a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem 0.5rem;
      }
      */ }
    @media screen and (min-width: 426px) {
      .s-snow-lives__list li {
        max-width: calc(100% / 2 - 0.5rem);
        min-width: calc(100% / 3 + 1px);
        width: auto; } }
    @media screen and (min-width: 769px) {
      .s-snow-lives__list li {
        max-width: calc(100% / 3 - 0.5rem);
        min-width: calc(100% / 4 + 1px);
        width: auto; } }
    .s-snow-lives__list li h2 {
      margin-bottom: 0.5rem;
      color: #322E2E; }
    .s-snow-lives__list li div {
      text-align: left; }

.s-snow-lives__detail {
  gap: 0;
  margin: 0;
  align-items: normal !important;
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 2rem); }
  .s-snow-lives__detail--pic {
    overflow: hidden;
    position: relative; }
    .s-snow-lives__detail--pic:hover {
      opacity: 0.7;
      cursor: pointer; }
    .s-snow-lives__detail--pic a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative; }
    .s-snow-lives__detail--pic figure {
      margin: 0;
      position: relative;
      max-width: 100%;
      max-height: 100%; }
      .s-snow-lives__detail--pic figure > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover; }
  .s-snow-lives__detail--iframe {
    position: relative;
    overflow: hidden; }
    .s-snow-lives__detail--iframe:hover {
      opacity: 0.7;
      cursor: pointer; }
    .s-snow-lives__detail--iframe a {
      display: block;
      width: 100%;
      height: 100%;
      z-index: 2; }
      .s-snow-lives__detail--iframe a > figure {
        margin: 0;
        position: relative;
        max-width: 100%;
        max-height: 100%; }
        .s-snow-lives__detail--iframe a > figure > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-height: 100%;
          max-width: 100%;
          object-fit: cover;
          object-position: top; }
    .s-snow-lives__detail--iframe .fancybox-iframe {
      padding-top: 1rem; }

.s-snow-lives .underconstruction {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.2rem;
  color: #666;
  /*
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: #666;
    */ }

.s-snow-lives .underconstruction:before {
  content: '';
  background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 150px;
  background-position: center;
  opacity: 0.1; }

.s-snow-lives .l-footer {
  padding-bottom: 0; }

@media screen and (min-width: 426px) {
  .s-snow-lives .p-footer__main {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 0; }
    .s-snow-lives .p-footer__main:before {
      top: 0; } }
    @media screen and (min-width: 426px) and (min-width: 769px) {
      .s-snow-lives .p-footer__main:before {
        top: -50px; } }

.s-snow-lives .p-footer__copyright,
.s-snow-lives .p-footer__main--menu,
.s-snow-lives .p-footer__main--logo {
  display: none; }

.s-ticket__body {
  width: 100%;
  max-width: 850px;
  text-align: center;
  margin: 0 auto; }

.s-ticket__inner {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  padding-bottom: 5rem;
  text-align: left; }

.s-ticket__title {
  font-size: 24px;
  font-size: 1.5rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */
  font-weight: bold;
  margin: 2rem 0;
  margin-top: 60px; }

.s-ticket__thumb {
  text-align: center; }

.s-ticket__detail {
  /*
    line-height: 1.7;

    address, blockquote,
    dl, fieldset, figure,
    h1, h2, h3, h4, h5, h6,
    hr, ol, p, pre, table, ul {
      margin: 1rem 0;
    }
    */ }
  .s-ticket__detail h1:not(:first-child), .s-ticket__detail h2:not(:first-child), .s-ticket__detail h3:not(:first-child), .s-ticket__detail h4:not(:first-child), .s-ticket__detail h5:not(:first-child), .s-ticket__detail h6:not(:first-child) {
    margin-top: 2rem; }

.s-tickets__body {
  max-width: 1130px;
  width: 100%;
  margin: 0 auto; }

.s-tickets__inner {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  .s-tickets__inner h1 {
    position: relative; }
    @media screen and (min-width: 769px) {
      .s-tickets__inner h1::before {
        position: absolute;
        content: '';
        background-image: url("/images/ticket_left.png");
        width: 205px;
        height: 68px;
        left: -217px;
        bottom: 19px; }
      .s-tickets__inner h1::after {
        position: absolute;
        content: '';
        background-image: url("/images/ticket_right.png");
        width: 240px;
        height: 62px;
        right: -248px;
        bottom: 19px; } }

.s-tickets__list {
  width: 100%;
  list-style: none;
  margin-top: 60px;
  text-align: left; }
  .s-tickets__list li {
    width: calc(100%);
    padding: 0;
    margin-bottom: 50px; }
    @media screen and (min-width: 769px) {
      .s-tickets__list li {
        margin-bottom: 100px; } }
    .s-tickets__list li:hover {
      opacity: 0.7; }
    .s-tickets__list li a {
      color: #322E2E; }
    .s-tickets__list li figure {
      position: relative;
      margin-top: 15px; }
      @media screen and (min-width: 769px) {
        .s-tickets__list li figure {
          margin-top: 30px; } }
      .s-tickets__list li figure:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 38.0315%; }
      .s-tickets__list li figure img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }

.u-align-left {
  display: block !important;
  margin-right: auto !important;
  margin-left: 0 !important; }

.u-align-center {
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important; }

.u-align-right {
  display: block !important;
  margin-right: 0 !important;
  margin-left: auto !important; }

.u-clear {
  clear: both; }

.u-float-left {
  float: left; }

.u-float-right {
  float: right; }

.u-font10 {
  font-size: 10px;
  font-size: 0.625rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font11 {
  font-size: 11px;
  font-size: 0.6875rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font12 {
  font-size: 12px;
  font-size: 0.75rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font13 {
  font-size: 13px;
  font-size: 0.8125rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font14 {
  font-size: 14px;
  font-size: 0.875rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font15 {
  font-size: 15px;
  font-size: 0.9375rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font16 {
  font-size: 16px;
  font-size: 1rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font17 {
  font-size: 17px;
  font-size: 1.0625rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font18 {
  font-size: 18px;
  font-size: 1.125rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font19 {
  font-size: 19px;
  font-size: 1.1875rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-font20 {
  font-size: 20px;
  font-size: 1.25rem;
  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */ }

.u-mt0 {
  margin-top: 0 !important; }

.u-mt05 {
  margin-top: 0.5rem !important; }

.u-mt1 {
  margin-top: 1rem !important; }

.u-mt2 {
  margin-top: 2rem !important; }

.u-mt3 {
  margin-top: 3rem !important; }

.u-mt4 {
  margin-top: 4rem !important; }

.u-mt5 {
  margin-top: 5rem !important; }

.u-mr0 {
  margin-right: 0 !important; }

.u-mr05 {
  margin-right: 0.5rem !important; }

.u-mr1 {
  margin-right: 1rem !important; }

.u-mr2 {
  margin-right: 2rem !important; }

.u-mr3 {
  margin-right: 3rem !important; }

.u-mb0 {
  margin-bottom: 0 !important; }

.u-mb05 {
  margin-bottom: 0.5rem !important; }

.u-mb1 {
  margin-bottom: 1rem !important; }

.u-mb2 {
  margin-bottom: 2rem !important; }

.u-mb3 {
  margin-bottom: 3rem !important; }

.u-mb4 {
  margin-bottom: 4rem !important; }

.u-mb5 {
  margin-bottom: 5rem !important; }

.u-ml0 {
  margin-left: 0 !important; }

.u-ml05 {
  margin-left: 0.5rem !important; }

.u-ml1 {
  margin-left: 1rem !important; }

.u-ml2 {
  margin-left: 2rem !important; }

.u-ml3 {
  margin-left: 3rem !important; }

.u-pt0 {
  padding-top: 0 !important; }

.u-pt05 {
  padding-top: 0.5rem !important; }

.u-pt1 {
  padding-top: 1rem !important; }

.u-pt2 {
  padding-top: 2rem !important; }

.u-pt3 {
  padding-top: 3rem !important; }

.u-pt4 {
  padding-top: 4rem !important; }

.u-pt5 {
  padding-top: 5rem !important; }

.u-pr0 {
  padding-right: 0 !important; }

.u-pr05 {
  padding-right: 0.5rem !important; }

.u-pr1 {
  padding-right: 1rem !important; }

.u-pr2 {
  padding-right: 2rem !important; }

.u-pr3 {
  padding-right: 3rem !important; }

.u-pb0 {
  padding-bottom: 0 !important; }

.u-pb05 {
  padding-bottom: 0.5rem !important; }

.u-pb1 {
  padding-bottom: 1rem !important; }

.u-pb2 {
  padding-bottom: 2rem !important; }

.u-pb3 {
  padding-bottom: 3rem !important; }

.u-pb4 {
  padding-bottom: 4rem !important; }

.u-pb5 {
  padding-bottom: 5rem !important; }

.u-pl0 {
  padding-left: 0 !important; }

.u-pl05 {
  padding-left: 0.5rem !important; }

.u-pl1 {
  padding-left: 1rem !important; }

.u-pl2 {
  padding-left: 2rem !important; }

.u-pl3 {
  padding-left: 3rem !important; }

.u-percent5 {
  width: 5% !important; }

.u-percent10 {
  width: 10% !important; }

.u-percent15 {
  width: 15% !important; }

.u-percent20 {
  width: 20% !important; }

.u-percent25 {
  width: 25% !important; }

.u-percent30 {
  width: 30% !important; }

.u-percent35 {
  width: 35% !important; }

.u-percent40 {
  width: 40% !important; }

.u-percent45 {
  width: 45% !important; }

.u-percent50 {
  width: 50% !important; }

.u-percent55 {
  width: 55% !important; }

.u-percent60 {
  width: 60% !important; }

.u-percent65 {
  width: 65% !important; }

.u-percent70 {
  width: 70% !important; }

.u-percent75 {
  width: 75% !important; }

.u-percent80 {
  width: 80% !important; }

.u-percent85 {
  width: 85% !important; }

.u-percent90 {
  width: 90% !important; }

.u-percent95 {
  width: 95% !important; }

.u-percent100 {
  width: 100% !important; }

/* 先頭の1文字目を1文字分字下げします。 */
.u-text-indent {
  text-indent: 1em !important; }

/* 2行目以降の1文字目を1文字分字下げします。 */
.u-text-note {
  margin-left: 1em !important;
  text-indent: -1em !important; }

/* テキストを改行させません。 */
.u-text-nowrap {
  white-space: nowrap !important; }

/* テキストを改行させます。 */
.u-text-breakword {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important; }

/* テキストを両端揃えさせます。 */
.u-text-justify {
  text-align: justify !important;
  text-justify: inter-ideograph !important; }

/* 記述した通りに表示します。 */
.u-text-none {
  text-transform: none !important; }

/* すべての英単語を小文字にします。 */
.u-text-lowercase {
  text-transform: lowercase !important; }

/* すべての英単語を大文字にします。 */
.u-text-uppercase {
  text-transform: uppercase !important; }

/* 英単語の先頭を大文字にします。 */
.u-text-capitalize {
  text-transform: capitalize !important; }

/* スモールキャップ（小文字と同じ高さで作られた大文字）にします。 */
.u-text-smallcaps {
  font-variant: small-caps !important; }

/* 縦書きにします。 */
.u-text-vertical {
  text-orientation: mixed !important;
  writing-mode: vertical-rl !important; }

/* 英文も縦書きにする場合はModifierを指定します。 */
.u-text-vertical--upright {
  text-orientation: upright !important; }

.u-text-right {
  text-align: right !important; }

.u-text-center {
  text-align: center !important; }

.u-text-left {
  text-align: left !important; }

.u-text-top {
  vertical-align: top !important; }

.u-text-middle {
  vertical-align: middle !important; }

.u-text-bottom {
  vertical-align: bottom !important; }

.u-v-sp {
  display: block; }
  @media screen and (min-width: 769px) {
    .u-v-sp {
      display: none; } }

.u-v-pc {
  display: none; }
  @media screen and (min-width: 769px) {
    .u-v-pc {
      display: block; } }
