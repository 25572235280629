.s-activities {

  &__body {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
  }

  &__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    h1 {
      position: relative;

      @include mq-up(md) {
        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/activity_left.png');
          width: 208px;
          height: 99px;
          left: -234px;
          bottom: 22px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/activity_right.png');
          width: 199px;
          height: 90px;
          right: -203px;
          bottom: 21px;
        }
      }
    }


  }

  &__search {
    text-align: center;
    margin-top: 40px;
  }

  &__area {
    margin-right: 20px;
    margin-bottom: 1rem;
  }
  &__area,
  &__genre {

    position: relative;
    display: inline-block;
    width: 100%;

    @include mq-up(md) {
      width: 287px;
      max-width: 100%;
    }

    &:after {
      content:'▼';
      display: inline-block;
      @include font-size(10);
      line-height: 1;
      color: $color_black;
      width: 0;
      z-index: 0;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    select {
      display: inline-block;
      background-color: $color_white;
      padding: 0.2rem 1rem;
      padding-right: 3rem;
      @include font-size(22);
      font-weight: bold;
      border: 1.2px solid $color_black;
      appearance: none;
      width: 100%;

      @include mq-up(md) {
        width: 287px;
        max-width: 100%;
      }

      option {
        background-color: $color_black;
        color: $color_text_white;
      }

    }
  }



  &__list {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    //justify-content: space-between;
    text-align: left;

    li {
      width: 100%;
      margin-bottom: 2rem;

      @include mq-up(md) {
        //width: calc((100% / 2) - 10px);
        //width: calc((100% - 20px) / 2);
        width: calc((100% - 68px) / 2);
        //margin-left: 20px;
        margin-left: 68px;

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }

      @include mq-up(lg) {
        //width: calc((100% / 3) - 20px);
        //width: calc((100% - 40px) / 3);
        width: calc((100% - 136px) / 3);
        //margin-left: 20px;
        margin-left: 68px;

        &:nth-child(2n + 1) {
          margin-left: 68px;
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      a {
        color: $color_text_black;
      }

      figure {
        position: relative;
        width: 100%;

        &:before {
          display: block;
          content: "";
          width: 100%;
          //padding-top: (279 / 337) * 100%;
          padding-top: (340 / 340) * 100%;
        }
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      dl {

        &:last-of-type() {
          margin-bottom: 0;
        }

        @include font-size(14);
        color: #000;

        dt {
          //@include mq-up(md) {
            display: inline;
          //}
        }
        dd {
          //@include mq-up(md) {
            display: inline-block;
          //}
        }
      }


    }

    &--title {
      //@include font-size(20);
      //margin-bottom: 12px;
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: .5rem;
      color: #1e1e1e;
    }

    &--area {
      display: inline-block;
      padding: 0 0.5rem;
      color: $color_text_white;
      font-size: 0.8rem;
      font-weight: normal;
      margin-right: 0.2rem;
      margin-bottom: 0;
      //border-width: 1px;
      //border-style: solid;
    }

    &--genre {
      display: inline-block;
      padding: 0 .5rem;
      //color: #1e1e1e;
      color: $color_text_black;
      font-size: .8rem;
      font-weight: normal;
      margin-right: .2rem;
      margin-bottom: 0;
      //border: 1px solid #1e1e1e;
      border: 1px solid #dcdcdc;
      border-top-right-radius: 10px;
      background-color: $color_white;
    }

    &--tag {
      display: inline-block;
      //border: 1px solid $color_black;
      //background-color: $color_white;
      //padding: 0 0.5rem;
      //margin-right: 0.5rem;
      //margin-bottom: 0.5rem;
      font-size: .8rem;
      font-weight: normal;
      margin-right: .5rem;
      margin-bottom: 0;
      color: $color_link;
    }

    &--price {
      //font-weight: bold;
      //@include font-size(25);
      //margin-bottom: 1rem;
      color: #fa4646;
      font-size: 1rem;
      font-weight: normal;
      font-family: 'Inter' sans-serif;
      margin-bottom: .5rem;
      line-height: 1.3;
    }

  }

}