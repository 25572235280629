@mixin font-size($size, $base: $font-size_base) {

  font-size: $size + px;
  font-size: ($size / $base) + rem;

  /*
  @include mq-down('md') {
    font-size: ($size - 2) + px;
    font-size: (($size - 2) / ($base - 2)) + rem;
  }
  */

}
