.p-header {

  width: 100%;

  /*
  @include mq-up(md) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 80px;
    width: 780px;
    height: 80px;
  }
  */

  &__right {
    display: none;
  }

  /* ロゴ */
  /*
  &__logo {

    width: 270px;
    transition: .3s;

    @include mq-down(md) {
      margin: 1rem;
    }

    .is-slidedown & {
      position: fixed;
      z-index: 9;
      transform: translateY(0)!important;
    }

    a {
      display: inline-block;
      text-indent: -9999px;
      //background-image: url('/images/logo.png');
      background-position: left;
      background-size: contain;
      width: 100%;
      height: 45px;

    }
  }
  */
  &__top {
    //background-color: rgba($color_theme, 0.7);
    //background-color: red;
    //height: 70px;
    //background-image: url('/images/bg.png');
    //background-repeat: repeat;
    //background-blend-mode: multiply;

    /*
      ヘッダーサイズ
      PC: 86px
      SP: 66px
      一時サイズ: 44px
      homeと_mainのcssも変更必要
    */
    //height: 66px;
    height: 44px;
    text-align: right;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    //position: absolute;
    //top: 0;
    //left: 0;

    position: relative;
    width: 100%;
    z-index: 1;

    @include mq-up(md) {
      //height: 86px;
      height: 44px;
    }

    >a {
      color: $color_text_white;
      margin-right: 20px;
      display: block;

      &:first-child() {
        padding-left: 1rem;
      }

      &:last-child() {
        margin-right: 1rem;
      }

      &:hover {
        opacity: 0.7;
      }

      @include mq-up(md) {
        margin-right: 35px;

        &:last-child() {
          margin-right: 46px;
        }
      }
    }

    &--language {
      img {
        height: 30px;
      }
    }

    &--search {
      img {
        height: 25px;
      }
    }
  }

  /* メニュー */
  &__nav {

    //@include mq-down(md) {
      //display: block;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      background-color: $color_menu--bg;
      padding: 1rem;
      padding-top: 80px;
      position: fixed;
      visibility: hidden;
      max-height: 100%;
      overflow-y: auto;
      transition: .5s;
      top: 0;
      right: 0;
      opacity: 0;
    //}

    height: auto;
    width: 100%;

    @include mq-up(md) {
      width: auto;
      padding: 5rem;
    }

    /* メニューリスト */
    >ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      //flex-direction: column;
      //height: 380px;
      width: 100%;
      max-width: 550px;
      min-width: 330px;

      //flex-direction: column;

      /*
      @include mq-up(md) {
        flex-direction: unset;
        justify-content: flex-end;
      }
      */



      >li {

        width: 100%;

        @include mq-up(sm) {
          width: 50%;
        }

        a {
          display: flex;
          width: 100%;
          align-items: center;
          //margin-left: 1rem;
          margin-top: .2rem;
          color: $color_text_white;
          @include font-size(18);
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.05rem;
          padding: 1rem;
          padding-right: 0;

          @include mq-up(sm) {
            padding: 1rem;
            margin-top: 1rem;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    /* スマホメニューオープン時 */
    .is-slidedown & {
      display: block;
      position: fixed;
      //top: auto;
      right: 0;
      z-index: 2;
      //transform: translateX(0);
      opacity: 1;
      visibility: visible;

      &-item {
        opacity: 1;
        transform: translateY(0);
      }

    }

    /* 各メニューのリンク */
    &-item {
      opacity: 0;
      transition: 0.3s ease-out;
      transform: translateY(1rem);
      padding-right: 1rem;

      @include mq-up(md) {
        padding-right: 2rem;
      }
    }

    &--bottom {
      display: flex;
      //justify-content: flex-start;
      justify-content: space-between;
      align-items: flex-end;
    }

    /* 言語選択 */
    &--language {
      margin-top: 2rem;
      padding-left: 1rem;

      img {
        margin-bottom: 0.5rem;
      }

      select {
        background-color: $color_text_white;
      }
    }

    /* SNS */
    &--sns {
      display: flex;
      margin-left: 2rem;

      li {
        margin-right: 1.5rem;
        a {
          color: $color_text_white;
        }
      }

    }

  }



  /* メニューボタン */
  &__button {

    background-color: $color_menu_btn--bg;
    transition: .3s;
    position: fixed;
    top: calc(44px + 1rem);
    right: 1rem;
    z-index: 3;
    display: inline-block;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: .5rem;
    cursor: pointer;
    border: none;
    border-radius:100%;

    @include mq-up(md) {
      top: calc(44px + 35px);
      right: 46px;
      width: 74px;
      height: 74px;
    }


    span {
      position: relative;
      //top: -4px;
      //top: -4px;
      //left: 0;
      display: inline-block;
      width: 25px;
      height: 2px;
      background-color: $color_menu_btn--line;
      opacity: 1;
      transition: .3s;
      vertical-align: middle;

      @include mq-up(md) {
        width: 34px;
      }

      &::before {
        position: absolute;
        //top: -7px;
        top: calc(-2px + -6px);
        left: 0;
        display: inline-block;
        width: 25px;
        height: 2px;
        content: '';
        background-color: $color_menu_btn--line;
        transition: .3s;
        @include mq-up(md) {
          top: calc(-2px + -8px);
          width: 34px;
        }
      }

      &::after {
        position: absolute;
        //top: 7px;
        top: calc(2px + 6px);
        left: 0;
        display: inline-block;
        width: 25px;
        height: 2px;
        content: '';
        background-color: $color_menu_btn--line;
        transition: .3s;

        @include mq-up(md) {
          top: calc(2px + 8px);
          width: 34px;
        }
      }
    }

  }

  &__button.active {
    filter: none;
    background-color: $color_menu_btn--activebg;
  }

  &__button.active span {
    background-color: transparent;

    &::before {
      transform: translateY(7px) rotate(-45deg);
      background-color: $color_menu_btn--activeline;

      @include mq-up(md) {
        transform: translateY(9px) rotate(-45deg);
      }
    }

    &::after {
      transform: translateY(-9px) rotate(45deg);
      background-color: $color_menu_btn--activeline;

      @include mq-up(md) {
        transform: translateY(-11px) rotate(45deg);
      }
    }
  }



  /* スクロール時 */
  .is-scroll & {

    &__button {
      top: 1rem;
      transition: .3s;
      @include mq-up(md) {
        top: 35px;
      }
    }


    /*
    @include mq-down(md) {

      &__logo {
        transform: translateY(-800px);
      }
    }
    */


    /*
    @include mq-up(md) {
      width: 100%;
      margin-top: 0;
      justify-content: flex-end;
      height: fit-content;
      background-color: $color_header_scroll;

      >.p-header__logo {
        display: none;
      }

      >nav {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;

        // メニューリスト
        >ul {

          width: auto;

          >li {
            >a {
              color: $color_text_black;
              margin-bottom: 1rem;
            }

            &.p-header__nav-item--reserve {
              margin-left: 2rem;
              background: $color_header_reserve;

              >a {
                margin-right: 1rem;
                color: $color_text_white;
              }
            }
          }
        }
      }

      // 装飾
      >.p-header__right {
        display: block;
        width: 10rem;
        height: 52px;
        background-color: $color_red;

        &:before {
          content: '';
          display: inline-block;
          width: 3rem;
          height: 52px;
          background-color: green;
        }

        &:after {
          content: '';
          display: inline-block;
          width: 3rem;
          height: 52px;
          background-color: yellow;
        }
      }
    }
    */
  }


  &__sns,
  &__language,
  &__search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(255,255,255, 0.7);
    padding: 80px 20px;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    box-sizing: border-box;
    z-index: 100;

    @include mq-up(md) {
      padding: 80px 20px;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &--inner {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      max-width: 500px;
      //width: 90%;
    }

    &--close {
      align-items: center;
      width: 45px;
      height: 45px;
      padding: 0.5rem;
      cursor: pointer;
      border: none;
      border-radius: 100%;
      background-color: #787878;
      position: absolute;
      top: 1rem;
      right: 1rem;

      span {
        position: relative;
        top: -4px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 2px;
        //background-color: #fff;
        opacity: 1;
        -webkit-transition: .3s;
        transition: .3s;

        &:before {
          position: absolute;
          top: -7px;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 2px;
          content: '';
          //background-color: #fff;
          -webkit-transition: .3s;
          transition: .3s;
          transform: translateY(7px) rotate(-45deg);
          background-color: #141414;
        }
        &:after {
          position: absolute;
          top: 7px;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 2px;
          content: '';
          //background-color: #fff;
          transition: .3s;
          transform: translateY(-7px) rotate(45deg);
          background-color: #141414;
        }
      }
    }

    &--title {
      text-align: left;
      margin-bottom: 0.5rem;
    }

    &--content {
      background-color: $color_black;
      color: $color_text_white;
      text-align: center;
      padding: 70px 35px;

      @include mq-up(md) {
        padding: 70px 100px;
      }

      ul {
        list-style: none;
        display: flex;

        a {
          color: $color_white;
          font-size: 2.5rem;
          margin: 1rem;
        }
      }

      input[type=text] {
        background-color: $color_white;
        color: $color_text_black;
        border-radius: 5px;
        border: none;
        padding: 0 1rem;
      }

      button {
        border: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__select {
    position: relative;

    &:after {
      content:'▼';
      display: inline-block;
      @include font-size(10);
      line-height: 1;
      color: $color_black;
      width: 0;
      z-index: 0;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    select {
      background-color: $color_white;
      color: $color_text_black;
      border-radius: 5px;
      border: none;
      appearance: none;
      padding: 0 1rem;
      padding-right: 3rem;
    }
  }

  &__mask {
    display: none;

    .is-slidedown & {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color_white;
      opacity: .5;
      z-index: 0;
    }
  }

}

/* モーダル用 */
/*
div.modal {
  position: fixed;
  z-index: 10;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
}
*/
