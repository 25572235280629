.s-home {

  /* swiper */
  .swiper-button-prev {
    width: 35px;
    left: 15px;

    @include mq-up(md) {
      width: 46px;
      left: 46px;
    }
  }
  .swiper-button-next {
    width: 35px;
    right: 10px;

    @include mq-up(md) {
      width: 46px;
      right: 46px;
    }
  }

  .swiper-button-disabled {
    visibility: hidden;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    margin: auto;
    height: 35px;
    width: 35px;

    @include mq-up(md) {
      height: 46px;
      width: 46px;
    }
  }
  .swiper-button-prev:after {
    background-image: url('/images/arrow.png');
  }
  .swiper-button-next:after {
    background-image: url('/images/arrow.png');
    transform: scaleX(-1);
  }

  &__body {
    overflow: hidden;


    >section {
      &:last-of-type {
        padding-bottom: calc(125px + 2rem);

        @include mq-up(md) {
          padding-bottom: calc(230px + 2rem);
        }
      }

    }
  }

  &__top {

    width: 100%;

    /*
    &--head {
      background-color: rgba($color_theme, 0.7);
      height: 70px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      >a {
        color: $color_text_white;
        margin-right: 1rem;
      }
    }
    */

    &--video {
      width: 100vw;
      height: calc(100vh - 44px);
      min-height: 500px;
      overflow: hidden;
      position: relative;


      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-image: radial-gradient(black 35%,transparent 36%),radial-gradient(black 35%,transparent 36%);
        background-size: 3px 3px;
        background-position: 0 0,1.5px 0;
        background-repeat: repeat;
        opacity: 0.5;
      }


      @include mq-up(md) {
        height: calc(100vh - 44px);
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
      }


      /*
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      */
    }

    &--logo  {
      position: absolute;
      top: calc(44px + 1rem);
      left: 1rem;
      width: 150px;
      max-width: 259px;
      z-index: 1;

      @include mq-up(md) {
        top: calc(44px + 41px);
        left: 85px;
        width: auto;
      }
    }
  }

  &__intro {
    background-color: $color_theme;
    position: relative;
    text-align: center;

    &--inner {
      padding: 0 1rem;
      padding-bottom: 30px;

      @include mq-up(md) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        padding-bottom: 60px;

        //max-width: 1189px;
        //max-width: calc(1129px + 4rem);
        margin: 0 auto;
      }

      img {
        @include mq-up(md) {
          margin-top: 36px;
          object-fit: contain;
          //width: 50%;
          max-width: 686px;
          width: calc(100% - 440px);
        }

        @include mq-up(lg) {
          width: calc(100% - 535px);
        }
      }

      >div {
        padding-top: 40px;

        @include mq-up(md) {
          display: inline;
          padding-top: 70px;
          /*
          width: 50%;
          padding: 0 35px;
          */
          //width: 50%;
          //width: 30%;
          //max-width: calc(100% - 686px);
          //max-width: calc(500px + 35px);
          //padding: 0 35px;
          padding-left: 35px;
          padding-right: 25px;
          text-align: left;
          flex-shrink: 0;
          //display: flex;
          //align-items: center;
          //justify-content: flex-start;
        }

        @include mq-up(lg) {
          padding-right: 60px;
        }
      }
    }

    /*
    &--title {

      @include font-size(18);
      font-family: $font-family-base;
      font-weight: 900;
      //margin-top: 2rem;
      //margin-bottom: 1rem;
      //margin-bottom: 3.5rem;
      margin-top: 92px;
      margin-bottom: 25px;
      margin-left: -15px;
      padding: 0 0 0 15px;
      line-height: 50px;

      &:before {
        width: 50px;
        height: 50px;
      }

      &.c-typo__yellow--long:before {
        width: calc(100% + 15px);
      }

      @include mq-up(md) {
        @include font-size(2);
        margin-left: 0;
      }
    }
    */

    &--detail {

      margin-bottom: 2rem;
      text-align: left;
      display: inline-block;

      p {
        display: inline;
        //background-color: rgba(#fffe7b, 0.7);
        background-color: #fffe7b;
        font-feature-settings: "palt";
        font-weight: bold;
        color: #1e1e1e;
        padding: 0 10px;
        line-height: 2.4;
        letter-spacing: 1.5px;
        //@include font-size(17);
        font-size: 4.3vmin;

        html[lang=en] & {
          font-size: 3.5vmin;
        }

        @include mq-up(sm) {
          //@include font-size(28);
          font-size: 4.8vmin;

          html[lang=en] & {
            font-size: 4vmin;
          }

        }

        @include mq-up(md) {
          letter-spacing: 2px;
          line-height: 2.8;
          margin-bottom: 0;
          //@include font-size(29);
          font-size: 1.3rem;
          padding: 5px 10px;

          html[lang=en] & {
            font-size: 1.2rem!important;
          }
        }

        @include mq-up(lg) {
          font-size: 1.5rem!important;
        }
      }
    }
  }

  &__activity {
    background-color: $color_theme;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    //padding-bottom: calc(125px + 2rem); // 一時的に変更

    @include mq-up(md) {
      padding-top: 96px;
      padding-bottom: 100px;
      //padding-bottom: calc(230px + 2rem); // 一時的に変更
    }

    /*
    h2 {
      display: inline-block;
      //border-top: 8px solid $color_text_black;
      @include font-size(60);
      font-family: 'Anton', sans-serif;
      font-weight: normal;

      @include mq-up(md) {
        //border-top: 8px solid $color_text_black;
        @include font-size(60);

        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/home_activity_left.png');
          width: 206px;
          height: 96px;
          left: -247px;
          bottom: 0px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/home_activity_right.png');
          width: 182px;
          height: 84px;
          right: -223px;
          bottom: 6px;
        }
      }
    }
    */

    h2 {
      position: relative;
      display: inline-block;
    }

    &--head {
      display: inline-block;
      text-align: center;
      position: relative;
    }
    &--headleft {
      @include mq-up(md) {
        position: absolute;
        display: inline-block;
        background-image: url('/images/home_activity_left.png');
        width: 206px;
        height: 96px;
        left: -247px;
        bottom: -6px;
      }
    }

    &--headright {
      @include mq-up(md) {
        position: absolute;
        display: inline-block;
        background-image: url('/images/home_activity_right.png');
        width: 182px;
        height: 84px;
        right: -223px;
        bottom: -2px;
      }
    }


    ul {
      list-style: none;
      max-width: 974px;
      margin: 0 auto;
      display: block;
      margin-top: 30px;

      @include mq-up(sm) {
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @include mq-up(md) {
        padding: 0;
      }
      //@include mq-up(md) {

      //}

      li {
        //padding: 0.5rem;
        //width: calc(100% / 2);
        position: relative;
        //background-color: rgba(#ffffff ,0.5);
        //border-top-right-radius : 60px;
        margin: 0 auto;
        margin-bottom: 40px;
        width: 80%;

        @include mq-up(sm) {
          width: calc((100% - 4rem) / 2);
        }

        @include mq-up(md) {
          //width: calc(100% / 3);
          //padding: 1rem;
          max-width: 270px;
          width: calc(100% / 3);
          //width: calc((100% - 200px) / 3);
          //margin-left: 100px;
          margin-bottom: 84px;



          &:nth-child(3n + 2) {
            margin-bottom: 37px;
            margin-top: 47px;
          }



          /*
          &:nth-child(2n + 1) {
            margin-bottom: 0px;
          }
          */


          /*
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          */
        }


        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (242 / 251) * 100%;
        }


        /*
        &:after {
          position: absolute;
          content: "";
          width: 45px;
          height: 45px;
          bottom: -12px;
          right: -12px;
          background-image: url('/images/home_right.png');
        }
        */



        /*
        &::before {
          content: "";
          padding-top: 100%;
          display: block;
        }
        */

        a {
          display: block;
          position: absolute;
          padding: 1rem;
          width: 100%;
          height: 100%;
          //max-width: 251px;
          top: 0;

          &:hover {
            figure {
              img {
                transition: 1s;
                transform: scale(1.15,1.15);

              }
            }
          }

          @include mq-up(md) {
            //padding: 1rem;
            padding: 22px 24px;
            padding-top: 25px;
          }

          /*
          &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (242 / 251) * 100%;
          }
          */

          figure {
            position: relative;
            width: 100%;
            height: 100%;

            /*
            &:hover {
              width: 130%;
              height: 130%;
              transition: .3s;
            }
            */

            //max-width: 251px;

            /*
            &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: (242 / 251) * 100%;
            }
            */

            img {
              object-fit: contain;

              width: 100%;
              height: 100%;
              //padding: 14px 15px;
              //padding-bottom: 48px;
              transition: 1s;

              object-position: bottom;
              margin-bottom: 1rem;
              transform-origin: bottom;

              @include mq-up(md) {
                //padding: 12px 20px;
                //padding-bottom: calc(48px + 12px);

              }
            }

            p {
              position: relative;
              margin-bottom: 1rem;
            }
          }


          figcaption {
            color: $color_text_black;
            color: #323232;
            font-weight: bold;
            display: inline-flex;
            align-items: flex-start;
            height: 48px;
            line-height: 1;
            align-items: center;
            justify-content: center;
            text-align: center;
            @include font-size(16);
            width: auto;
            text-align: left;

            @include mq-up(md) {
              @include font-size(20);
              line-height: 1.35;
            }
          }


        }

        /*
        a {

          &:hover {
            opacity: 0.8;
          }

          figure {
            position: relative;
          }

          figcaption {
            color: $color_text_white;
            position: absolute;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            @include font-size(18);

            @include mq-up(md) {
              @include font-size(34);
            }
          }
        }
        */
      }
    }

    &--list {
      width: 100%;
      padding: 0 2rem;
    }

    /*
    &--arrow {
      position: absolute;
      content: "";
      width: 35px;
      height: 35px;
      bottom: -12px;
      right: -12px;
      background-image: url('/images/home_right.png');
      background-size: contain;
      transition:.3s;

      a:hover & {
        right: -24px;
        transition:.3s;
      }

      @include mq-up(md) {
        width: 45px;
        height: 45px;
      }
    }
    */
  }

  &__column {
    text-align: center;
    //padding: 4rem 2rem;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    background-color: $color_white;

    /*
    @include mq-up(md) {
      background-image: linear-gradient(135deg,
      $color_white 35%,
      #b4b4b4 15%,
      #b4b4b4 50%,
      $color_white 50%,
      $color_white 85%,
      #b4b4b4 85%);
      background-size: 10px 10px;
      background-repeat: repeat;
    }
    */
    @include mq-up(md) {
      background-image: linear-gradient(135deg,
      $color_white 42%,
      #b4b4b4 15%,
      #b4b4b4 50%,
      $color_white 50%,
      $color_white 92%,
      #b4b4b4 92%);
      background-size: 9px 9px;
      background-repeat: repeat;

      padding-top: 123px;
      padding-bottom: 147px;
    }

    &--bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: $color_white;
      //clip-path: polygon(20% 0, 100% 0%, 80% 100%, 0% 100%);
      //clip-path: polygon(27% 0, 93% 0%, 73% 100%, 0% 140%);
      clip-path: polygon(27% 0,93% 0,73% 100%,7% 100%);
      z-index: 0;
    }

    &--inner {
      position: relative;
      z-index: 1;
    }

    /*
    @include mq-up(md) {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 75%;
        background-image: url('/images/home_column--left.png');
        background-position: right 0 center;
        background-repeat: no-repeat;
        //margin-top: -2rem;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 75%;
        background-image: url('/images/home_column--right.png');
        background-position: left 0 center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
    }*/

    /*
    background-image:
     url('/images/home_column--left.png'),
     url('/images/home_column--right.png');

    background-position:
     left 0 center,
     right 0 center;

    background-repeat: no-repeat;
    */

    &--head {
      //width: 100%;
      //position: relative;
      display: inline-block;
      text-align: center;
      position: relative;

      @include mq-up(md) {
        margin-bottom: 64px;
      }
    }

    &--headleft {
      @include mq-up(md) {
        display: inline-block;
        position: absolute;
        content: '';
        background-image: url('/images/column_left.png');
        width: 232px;
        height: 88px;
        left: -265px;
        top: -18px;
      }
    }

    &--headright {
      @include mq-up(md) {
        display: inline-block;
        position: absolute;
        content: '';
        background-image: url('/images/column_right.png');
        width: 240px;
        height: 93px;
        right: -265px;
        top: -27px;
      }
    }

    &--headsub {
      margin-top: 10px;
      color: #505050;
      font-weight: bold;
      @include font-size(12);

      @include mq-up(md) {
        @include font-size(18);
      }
    }



    /*
    h2 {

      @include font-size(65);
      font-family: 'Anton', sans-serif;
      font-weight: normal;

      position: relative;
      display: inline-block;
      padding:0 0 0 15px;
      line-height: 50px;
        outline: none;

      &:before {
        content:'';
        position:absolute;
        left:0;
        z-index: -1;
        width:50px;
        height:50px;
        background:#ffff00;
        border-radius:25px;

          transition:.3s ease-out;
      }
      &:hover::before{
        width:212px;
      }
    }
    */

    &--list {
      position: relative;
      width: 100%;
      //max-width: 1129px;
      max-width: calc(1129px + (46px * 6));
      padding: 0 calc(35px * 2);
      text-align: center;
      margin: 0 auto;
      margin-bottom: 70px;
      margin-top: 30px;
      //padding: 0 4rem;
      z-index: 1;

      @include mq-up(md) {
        margin-top: 0;
        padding: 0 calc(46px * 3);
      }


      .swiper {
        position: relative;
        width: 100%;
        //margin: 4rem auto;
      }

      .swiper-slide {
        text-align: center;

        >a {
          display: block;
          color: $color_text_black;
        }
      }
    }

    &--img {
      width: 100%;
      margin-bottom: 14px;
      overflow: hidden;
      position: relative;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (734 / 850) * 100%;
      }


      img {
        transition: 1s all;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;

        &:hover {
          transform: scale(1.2, 1.2);
          transition: 1s all;
        }
      }

      p {
        transition: 1s all;
        &:hover {
          transform: scale(1.2, 1.2);
          transition: 1s all;
        }
      }
    }
    &--title {
      text-align: left;
      //padding: 0 1rem;

      h3 {
        display: inline;
        //font-weight: bold;
        font-weight: normal;
        @include font-size(16);
        margin-right: 13px;

      }
      span {
        //@include font-size(14);
        font-size: .9rem;
        display: block;

        /*
        background-color: $color_bg_darkgray;
        padding: 0 0.5rem;
        color: $color_text_white;
        */
      }
    }


  }

  &__eventinfo {

    position: relative;
    text-align: center;
    //padding: 8rem 0;
    padding-top: 52px;
    padding-bottom: 60px;

    @include mq-up(md) {
      padding-top: 178px;
      padding-bottom: 209px;
    }

    /*固定する背景*/
    /*
    > div {
      box-sizing: border-box;
      color: #FFF;
      font-size: 5rem;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      height: 800px;
      padding: 5%;
    }
    */

    &--parallax {
      position: fixed;
      z-index: -999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-attachment: fixed; /*--背景画像の固定--*/

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('/images/home_eventinfo.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.7;
      }
    }
  }
  &__event {
    background: rgba($color_white ,0.7);
    //margin: 2rem 0;
    //padding: 4rem 2rem;
    //padding: 4rem 0rem;
    padding-top: 111px;
    padding-bottom: 113px;

    &--head {
      width: 100%;

      @include mq-up(md) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 66px;
      }

      >span {
        display: block;
        color: #505050;
        @include font-size(18);
        line-height: 1.5;
        font-weight: 600;
        margin-top: 1rem;

        @include mq-up(md) {
          text-align: left;
          display: inline-block;
          margin-top: 0;
          margin-left: 3rem;
        }
      }
    }

    &--list {
      position: relative;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      padding: 0 calc(35px * 2);
      margin-bottom: 60px;
      margin-top: 30px;

      @include mq-up(md) {
        //width: calc(100% - 4rem);
        max-width: calc(1129px + (46px * 6));
        padding: 0 calc(46px * 3);
        margin-top: 0;
      }

      .swiper {
        position: relative;
        width: 100%;
        //margin: 4rem auto;
      }

      .swiper-slide {
        text-align: center;
      }
    }
    &--img {
      width: 100%;
      margin-bottom: 14px;
      overflow: hidden;

      img {
        width: 100%;
        transition: 1s all;

        &:hover {
          transform: scale(1.2, 1.2);
          transition: 1s all;
        }
      }
    }
    &--title {
      text-align: left;
      //padding: 0 1rem;

      h3 {
        display: inline-block;
        @include font-size(16);
      }
      span {
        display: block;
        @include font-size(16);

        /*
        @include mq-up(md) {
          //display: inline;
          //margin-left: 1rem;
          //padding: 0 0.5rem;
        }
        */
      }
    }
  }

  &__info {
    width: 100%;
    //margin-top: 180px;
    //padding: 0 2rem;
    //padding-top: 58px;
    //padding-bottom: 47px;

    padding: 0 1rem;

    &--inner {

      margin: 0 auto;
      max-width: 897px;
      background: rgba($color_white ,0.6);
      //padding: 2rem;
      //padding: 47px 1.5rem;
      padding: 15px 1rem;
      padding-bottom: 25px;


      @include mq-up(md) {
        padding: 50px 117px;
        padding-bottom: 50px;
      }

      h2 {
        @include font-size(34);
        font-family: 'Anton', sans-serif;
        font-weight: normal;
        display: inline-block;
        border-bottom: 4px solid $color_black;

        @include mq-up(md) {
          @include font-size(40);
        }
      }

      ol {
        list-style: none;
        //argin-top: 2rem;
        //margin-bottom: 1rem;
        margin-top: 28px;
        margin-bottom: 26px;

        li {
          padding-bottom: 14px;
          margin-bottom: 14px;
          text-align: left;
          @include font-size(18);

          &:not(:last-child) {
            border-bottom: 1px dashed $color_bg_black;
          }

          a {
            @include mq-up(md) {
              display: flex;
            }
            font-weight: bold;
            color: $color_text_black;

            span {
              display: block;
              font-weight: normal;

              @include mq-up(md) {
                display: inline;
                margin-left: 2rem;
              }
            }
          }

        }
      }
    }


  }


  &__rental {
    text-align: center;
    padding: 4rem 1rem;
    padding-top: 40px;
    //padding-bottom: calc(125px + 2rem);
    background-image: url('/images/home_rental_bg.jpg');
    background-repeat: repeat;
    //background-blend-mode: multiply;
    position: relative;


    @include mq-up(md) {
      padding: 120px 1rem;
      //padding-bottom: calc(230px + 2rem);
    }


    h2 {
      position: relative;
      display: inline-block;
    }

    &--head {
      display: inline-block;
      text-align: center;
      position: relative;
    }

    &--headleft {
      @include mq-up(md) {
        position: absolute;
        top: 0px;
        display: inline-block;
        background-image: url('/images/home_rental_left.png');
        background-size: contain;
        height: 87px;
        width: 170px;
        left: -199px;

        @include mq-up(lg) {
          width: 227px;
          left: -283px;
        }
        /*
        //border-top: 8px solid $color_text_black;
        @include font-size(60);

        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/home_rental_left.png');
          width: 206px;
          height: 96px;
          left: -230px;
          bottom: 0px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/home_rental_right.png');
          width: 182px;
          height: 84px;
          right: -210px;
          bottom: 6px;
        }
        */
      }
    }

    &--headright {
      @include mq-up(md) {
        position: absolute;
        top: -6px;
        display: inline-block;
        background-image: url('/images/home_rental_right.png');
        background-size: contain;
        height: 101px;
        width: 103px;
        right: -144px;

        @include mq-up(lg) {
          width: 136px;
          right: -190px;
        }
      }
    }

    &--headsub {
      margin-top: 10px;
      color: #505050;
      font-weight: bold;
      @include font-size(16);

      @include mq-up(md) {
        @include font-size(18);
      }
    }

    &--inner {
      margin: 0 auto;
      max-width: 375px;

      @include mq-up(md) {
        max-width: 917px;
      }

    }


    ul {
      list-style: none;
      margin: 35px auto;
      max-width: 974px;

      @include mq-up(md) {
        display: flex;
        justify-content: space-between;
        margin: 53px 0;
        margin-bottom: 111px;
        width: calc(100% - 10px);

      }

      li {
        position: relative;
        max-width: calc(430px + 10px);
        margin: 0 auto;
        margin-bottom: 35px;
        padding-right: 10px;

        @include mq-up(md) {
          padding-right: 0;
          margin: 0;
          width: 45%;
          max-width: calc(430px + 10px);
          max-height: calc(251px + 10px);
        }

        a {

          display: block;
          background-color: $color_white;
          border-top-right-radius: 40px;
          border: 5px solid #323232;
          color: #323232;
          position: relative;
          margin: 0 auto;
          margin-bottom: 0;
          width: 100%;
          height: 100%;
          padding: 10px;

          >img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
            max-height: 150px;
            border-top-right-radius: 20px;

            @include mq-up(md) {
              object-fit: contain;
              max-height: unset;
            }
          }

          >div {
            width: 100%;
            position: absolute;
            bottom: 20px;
            font-weight: bold;
            @include font-size(14);

            @include mq-up(md) {
              @include font-size(26);
            }
          }

          &:hover {
            transform: translate(10px, 10px);
          }
        }


        &:before {
          content: "";
          //絶対配置で影の位置を決める
          position: absolute;
          z-index: -1;
          top: 10px;
          left: 10px;
          //影の形状
          width: calc(100% - 10px);
          height: 100%;
          background-color: #323232;
          border-top-right-radius: 40px;

          @include mq-up(md) {
            width: 100%;
          }

        }




      }
    }

    &--guide {

      max-width: 1370px;
      display: block;
      position: relative;
      //background-image: url('/images/home_guide_sp.jpg');
      background-size: contain;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (253 / 325) * 100%;
      }

      @include mq-up(md) {
        //background-image: url('/images/home_guide.jpg');
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (368 / 1124) * 100%;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  /* snow-live */
  &__snow-live {
    text-align: center;
    padding: 4rem 1rem;
    padding-top: 40px;
    //padding-bottom: calc(125px + 2rem);
    //background-image: url('/images/home_rental_bg.jpg');
    background-image: url('/images/bg.png');
    background-repeat: repeat;
    background-blend-mode: multiply;
    position: relative;

    @include mq-up(md) {
      padding: 120px 1rem;
      padding-top: 0;
      //padding-bottom: calc(230px + 2rem);
    }

    /*
    h2 {
      position: relative;
      display: inline-block;
      margin-bottom: 30px;
    }
    */

    h2 {
      position: relative;
      display: inline-block;
    }

    &--head {
      display: inline-block;
      text-align: center;
      position: relative;
    }

    &--headleft {
      @include mq-up(md) {
        position: absolute;
        display: inline-block;
        background-image: url('/images/snow-live_left.png');
        background-size: contain;
        /*
        top: -14px;
        height: 74px;
        width: 196px;
        left: -154px;
        */
        top: -15px;
        height: 87px;
        width: 230px;
        left: -217px;


        /*
        @include mq-up(lg) {
          width: 196px;
        }
        */

      }
    }

    &--headright {
      @include mq-up(md) {
        position: absolute;
        display: inline-block;
        background-image: url('/images/snow-live_right.png');
        background-size: contain;
        //top: -6px;
        //height: 72px;
        //width: 164px;
        //right: -122px;
        top: -9px;
        height: 86px;
        width: 200px;
        right: -190px;

        /*
        @include mq-up(lg) {
          width: 164px;
        }
        */
      }
    }

    &--headsub {
      margin-top: 10px;
      color: #505050;
      //font-weight: bold;
      font-weight: normal;
      text-align: left;

      //@include font-size(12);
      font-size: 0.9rem;

      @include mq-up(md) {
        //@include font-size(18);
        font-size: 1rem;
        text-align: center;
      }

    }

    /*
    &--text {
      font-size: 0.9rem;
      text-align: center;
      margin-bottom: -30px;

      @include mq-up(md) {
        margin: 0 60px;
        margin-bottom: -30px;
      }

      >p {
        display: inline-block;
        text-align: left;

        @include mq-up(md) {
          text-align: center;
        }
      }
    }
    */

    &--list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin-top: -30px;
    }

    &--item {
      padding: 1rem;
      width: calc(100% / 2);
      text-align: left;

      @include mq-up(md) {
        width: calc(100% / 3);
      }

      .underconstruction {
        position: absolute;
        top: 0;
        left: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Noto Sans JP";
        font-weight: bold;
        font-size: 1.2rem;
        color: #666;

        &:before {
          content: '';
          background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
          background-size: contain;
          position: absolute;
          width: 100%;
          height: 100%;
          max-width: 150px;
          background-position: center;
          opacity: 0.1;
        }
      }

    }

    &--pic {
      position: relative;
      background-color: $color_white;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (62 / 100) * 100%;
      }

      figure {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }

    &--iframe {
      position: relative;
      overflow: hidden;
      height: 250px;
      background-color: $color_white;
      /*
      >div {
        padding-top:calc(100% - 2vw);
      }
      */

      iframe {
        width: 100%;
        height: 100%;
      }

      a {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      .fancybox-iframe {
        padding-top: 1rem;
      }
    }


    &--questionnaire {

      display: inline-block;
      width: 100%;
      margin-top: 30px;

      background-image: url('/images/home_questionnaire-sp.png');
      background-size: contain;
      //max-width: 319px;
      //height: 286px;
      padding-top: (692 / 768) * 100%;


      @media screen and (min-width: 480px) {
        width: calc(100% - 2rem);
      }

      @include mq-up(md) {
        background-image: url('/images/home_questionnaire.png');

        max-width: 940px;
        padding-top: (485 / 940) * 100%;
        //height: 485px;
      }

      @include mq-up(xl) {
        height: 485px;
        padding-top: 0;
      }

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }

    }


  }


  // booking 使ってない
  /*
  &__booking {
    text-align: center;
    padding: 4rem 1rem;
    //padding-bottom: calc(4rem + 125px);
    //padding: 2rem;
    //padding-top: 4rem;
    //padding-bottom: 200px;
    //z-index: 0;
    padding-top: 118px;
    //padding-bottom: calc(160px + 125px);
    padding-bottom: 125px;

    background-color: $color_white;
    position: relative;

    @include mq-up(md) {
      background-image: linear-gradient(45deg,
      $color_white 42%,
      #b4b4b4 15%,
      #b4b4b4 50%,
      $color_white 50%,
      $color_white 92%,
      #b4b4b4 92%);
      background-size: 9px 9px;
      background-repeat: repeat;
      padding: 4rem 2rem;
      padding-bottom: calc(230px + 2rem);
    }

    &--bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: $color_white;
      //clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
      clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
      z-index: 0;


    }

    &--inner {
      position: relative;
      z-index: 1;
    }


    &--head {
      width: 100%;

      @include mq-up(md) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 66px;
      }

      >span {
        display: block;
        color: #505050;
        @include font-size(18);
        line-height: 1.5;
        font-weight: 600;
        margin-top: 1rem;

        @include mq-up(md) {
          text-align: left;
          display: inline-block;
          margin-top: 0;
          margin-left: 3rem;
        }
      }
    }

    ul {
      margin: 0 auto;
      margin-top: 72px;
      //max-width: 1140px;
      max-width: 944px;
      list-style: none;
      display: flex;
      flex-direction: column;

      @include mq-up(md) {
        flex-wrap: wrap;
        flex-direction: row;
      }

      li {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 50px;
        position: relative;
        z-index: 1;
        height: 180px;

        @include mq-up(md) {
          width: 50%;
          height: auto;
          padding: 25px;


          &:nth-child(even) {
            padding-left: 1.5rem;
            padding-right: 0;
          }
        }


        a {
          position: relative;
          //ボタンの形状
          text-decoration: none;
          display: inline-block;

          text-align: center;
          background: transparent;
          border-radius: 10px;
          //border: solid 1px $color_theme;
          outline: none;
          //アニメーションの指定
          transition: all 0.2s ease;

          width: 100%;
          height: 100%;

          >div {
            display: flex;
            background-color: $color_white;
            justify-content: center;
            align-items: center;
            height: 100%;
            //padding: 2rem;
            padding: 10px 31px;

            border: 5px solid $color_theme;
            border-radius: 10px;
            position: relative;
            transition: all 0.2s ease;

            @include mq-up(md) {
              padding: 37px 31px;
            }

            &:hover {
              background-color: $color_theme;
              color: $color_white;
              transform: translate(4px, 4px);


              .s-home__booking--icon {
                path,circle {
                  fill: $color_white;
                  stroke: $color_theme;
                }
              }
              .s-home__booking--arrow {
                path {
                  stroke: $color_white;
                }
              }
            }
          }

          &:before {
            content: "";
            //絶対配置で影の位置を決める
            position: absolute;
            //z-index: -1;
            top: 4px;
            left: 4px;
            //影の形状
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background-color: $color_theme;
          }
        }
      }
    }

    &--icon {

      margin-right: 0;
      height: 100%;

      @include mq-up(md) {
        margin-right: 1.5rem;
        height: auto;
      }

      &.cycle {
        max-width: 183px;
      }

      &.ski {
        max-width: 131px;
      }

      &.ticket {
        max-width: 167px;
      }

      &.guide {
        max-width: 129px;
      }

      svg {
        width: 100%;
        height: 100%;
        path,circle {
          fill: $color_theme;
        }
      }
    }

    &--title {
      @include font-size(16);
      width: 9rem;
      flex-shrink: 0;

      @include mq-up(md) {
        @include font-size(19);
        //width: 10rem;
        width: 152px;
      }

    }

    &--arrow {
      //width: 1rem;
      margin-left: 1rem;
      path {
        stroke: $color_text_black;
      }

      svg {
        width: 17px;
        height: 23px;
      }
    }

  }
  */

}
