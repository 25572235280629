/* WYSIWYGエディタで入力した箇所の表示 */
.c-wysiwyg {
  line-height: 1.7;
  address, blockquote, dl, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, ol, p, pre, table, ul {
    margin: 1rem 0;
  }

  ul {
    margin-left: 1.5rem
  }

  pre {
    white-space: pre-wrap;
  }
}