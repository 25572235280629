.p-nav {
  &__next {
    display: none;
  }

  &__next-button {
    display: block;
    width: 100%;
    padding: .25rem .5rem;
    margin-top: 1rem;
    color: brown;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid brown;

    &:hover {
      color: #fff;
      background-color: brown;
    }
  }
}
