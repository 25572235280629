.c-pager {
  padding: 1rem;
  margin: 0 auto;
  text-align: center;

  @include mq-up('md') {
    width: $box-width_md;
  }

  /* 選択されていないリンク */
  a {
    color: #6e6e6e;
    //border-color: $color_black!important;
    padding: 5px 1px!important;
    //background-color: $color_white;
    //border-radius: 10px;
    border: none!important;
    margin: 0 15px!important;
  }

  /* 選択中のページ数 */
  span {
    color: #1e1e1e;
    //background-color: $color_black;
    padding: 5px 1px!important;
    //border-radius: 10px;
    border: none!important;
    border-bottom: 1px solid #6e6e6e!important;
    margin: 0 15px!important;
  }

}
