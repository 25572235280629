.s-search {

  &__body {

    width: 100%;
    margin: 0 auto;
    text-align: center;

    @include mq-up('md') {
      width: 850px;
      padding: 0;
    }


    ul {
      width: 100%;
      margin: 0 auto;
      //padding: 0 1rem;
      position: relative;
      margin-top: 61px;

      li {
        position: relative;
        list-style: none;
        border-bottom: 1px solid $color_black;

        &:first-child {
          border-top: 1px solid $color_black;
        }

        a {
          display: block;
          padding: 17px;
          text-align: left;

          @include mq-up(md) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 26px;
          }

          h2 {
            font-weight: normal;
            @include font-size($font-size_m);

            margin-top: 0.5rem;

            @include mq-up(md) {
              margin-top: 0;
            }
          }
        }
      }
    }

  }


  &__category {
    background-color: $color_bg_black;
    color: $color_text_white;
    padding: 0 1rem;
    margin-right: 1rem;
  }


  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
  }

  &__previous {
    position: absolute;
    bottom: .2rem;
    left: 1rem;
    @include mq-up('md') {
      left: 0;
    }
  }

  &__next {
    position: absolute;
    bottom: .2rem;
    right: 1rem;
    @include mq-up('md') {
      right: 0;
    }
  }



}