.s-column {


  &__body {
    width: 100%;
    margin: 0 auto;

    @include mq-up('md') {
      width: 850px;
      padding: 0;
    }
  }

  &__inner {

    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 4rem;

    // アイキャッチ中央寄せ
    .attachment-post-thumbnail {
      display: block;
      margin: 0 auto 2rem;
    }

  }

  &__date {
    margin-bottom: 1rem;
    font-size: .9rem;
    display: block;
  }

  &__title {
    margin-top: 60px;
    margin-bottom: 2rem;
    @include font-size(24);
    font-weight: bold;

    @include mq-up('md') {
      //@include font-size($font-size_news_title);
    }
  }


  &__content {
    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }


  /* 周辺で人気のアクティビティ*/
  &__related {
    margin-top: 60px;
    padding-top: 60px;
    text-align: center;
    border-top: 1px solid #322e2e;

    h3 {
      font-weight: bold;
      @include font-size(20)
    }
  }

  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
    color: $color_black;
  }

  &__previous {
    position: absolute;
    bottom: 1px;
    left: 1rem;
    color: $color_black;
    @include mq-up('md') {
      left: 0;
    }
  }

  &__next {
    position: absolute;
    bottom: 1px;
    right: 1rem;
    color: $color_black;
    @include mq-up('md') {
      right: 0;
    }
  }



}