.l-header {
  //position: absolute;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 999;
  //height: 80px;

  /*
  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 100%;
  }
  */

  @include mq-up(md) {
    display: flex;
    justify-content: center;
  }


  &.is-scroll {

    /*
    @include mq-up(md) {
      position: fixed;
      height: fit-content;
    }
    */
  }

  @media screen and (min-width: 1261px) {
    justify-content: center;
  }
}
