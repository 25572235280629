.u-align-left {
  display: block !important;
  margin-right: auto !important;
  margin-left: 0 !important;
}

.u-align-center {
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.u-align-right {
  display: block !important;
  margin-right: 0 !important;
  margin-left: auto !important;
}

.u-clear {
  clear: both;
}
