.u-v {
  &-sp {
    display: block;
    @include mq-up(md) {
      display: none;
    }
  }
  &-pc {
    display: none;
    @include mq-up(md) {
      display: block;
    }
  }
}
