.s-goods_detail {

  &__body {
    width: 100%;
    margin: 0 auto;

    @include mq-up('md') {
      max-width: 850px;
      padding: 0;
    }
  }

  &__inner {

    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 4rem;

  }

  &__title {
    margin-top: 60px;
    margin-bottom: 2rem;
    @include font-size(24);
    font-weight: bold;

    @include mq-up('md') {
      //@include font-size($font-size_news_title);
    }
  }

  &__pic {
    text-align: center;
  }

  &__content {
    margin-top: 30px;

    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }

  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
    color: $color_text_black;
  }
}