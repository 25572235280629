.s-contact {

  &__body {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
  }

  &__inner {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    position: relative;

    ul {
      list-style: none;
      margin-top: 60px;
      text-align: left;

      li {
        margin-bottom: 1.5rem;
        margin-top: 5px;
      }

      input[type=text],
      input[type=email],
      input[type=tel],
      textarea {
        width: 100%;
        border: 2px solid gray;
        background: $color_white;
        border-radius: 6px;
        padding: 5px;
      }
    }

  }

  &__required {

    @include font-size(15);
    background-color: #ff3f2e;
    color: $color_white;
    padding: 0 1rem;
    margin-left: 1rem;

  }

  &__recaptcha {
    text-align: center;
    margin-bottom: 2rem;

    .recaptcha {
      display: inline-block;
    }
  }

  &__agreement {
    text-align: center;
    margin-bottom: 2rem;

    label {
      margin-left: 1rem;
    }
  }

  &__submit {
    text-align: center;

    /* 送信 ローディング */
    .wpcf7-spinner {
      //display: block;
      margin: 10px auto;
      position: absolute;
      left: 0;
      right: 0;

    }

    &--inner {

      position: relative;
      z-index: 0;
      display: inline-block;

      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $color_bg_black;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        z-index: -1;
      }

      input {
        position: relative;
        z-index: 2;
        display: block;
        padding: 1rem 3rem;
        background-color: $color_white;
        border-radius: 35px;
        border: 2px solid $color_bg_black;
        transition: all 0.2s ease;

        &:hover {
          color: $color_text_white;
          background-color: #505050;
          border-color: #505050;
          transform: translate(5px, 5px);
          cursor: pointer;
        }
      }
    }
  }



  /* 完了画面 */
  &__complete {
    text-align: center;

    figure {
      margin-top: 35px;
      margin-bottom: 25px;

      @include mq-up(md) {
        margin-top: 20px;
      }
    }

    &--title {
      @include font-size(22);
      font-weight: bold;
      color: #505050;

      @include mq-up(md) {
        @include font-size(38);
      }
    }

    &--message {
      @include font-size(18);
      color: #505050;
      margin-top: 25px;
      margin-bottom: 40px;

      @include mq-up(md) {
        margin-top: 25px;
        margin-bottom: 85px;
      }
    }

    &--back {
      background: $color_white;
      border: 1px solid $color_bg_black;
      border-radius: 25px;
      transition: 0.3s;
      display: inline-block;

      a {
        display: block;
        padding: 0.5rem 2rem;

        span {
          color: $color_black;
        }
      }

      &:hover {
        background-color: $color_bg_black;
        transition: 0.3s;
        span {
          color: $color_text_white;
        }
      }
    }

  }
}