.s-informations {

  &__body {

    width: 100%;
    margin: 0 auto;
    text-align: center;

    @include mq-up('md') {
      width: 850px;
      padding: 0;
    }

    h1 {
      position: relative;

      @include mq-up(md) {
        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/information_left.png');
          width: 129px;
          height: 70px;
          left: -143px;
          bottom: 26px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/information_right.png');
          width: 127px;
          height: 76px;
          right: -141px;
          bottom: 24px;
        }
      }
    }

    ul {
      width: 100%;
      margin: 0 auto;
      //padding: 0 1rem;
      position: relative;
      margin-top: 61px;
      text-align: left;

      li {
        position: relative;
        list-style: none;
        border-bottom: 1px solid $color_black;

        /*
        &:first-child {
          border-top: 1px solid $color_black;
        }
        */
        &:last-child {
          border-bottom: none;
        }

        a {
          display: block;
          padding: 26px 0;
          color: $color_text_black;

          @include mq-up(md) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          &:hover {
            opacity: 0.7;
          }

          /*
          >img {
            width: 100%;
            object-fit: cover;

            @include mq-up(md) {
              width: 165px;
              height: 117px;
            }
          }
          */

          >figure {
            position: relative;
            margin: 0;

            @include mq-up(md) {
              width: 190px;
            }

            &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: (127 / 190) * 100%;
            }
            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

          }

          >div {
            width: 100%;

            @include mq-up(md) {
              padding-left: 47px;
              width: calc(100% - 190px);
            }

          }

          h2 {
            font-weight: normal;
            @include font-size($font-size_m);
          }
        }
      }
    }

  }


  &__back {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid $color_black;
  }

  &__previous {
    position: absolute;
    bottom: .2rem;
    left: 1rem;
    @include mq-up('md') {
      left: 0;
    }
  }

  &__next {
    position: absolute;
    bottom: .2rem;
    right: 1rem;
    @include mq-up('md') {
      right: 0;
    }
  }



}