/* NO IMAGEの表示 */
.c-noimage {
  position: absolute;
  top: 0;
  //left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP";
  font-weight: bold;
  font-size: 1.2rem;
  color: #666;
  background-color: $color_white;

  &:after {
      content: '';
      background-image: url(/cms/wp-content/uploads/2022/12/logo.png);
      background-size: contain;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 150px;
      background-position: center;
      //opacity: 0.1;
  }
}