.s-tickets {

  &__body {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
  }

  &__inner {
    width: 100%;
    margin: 0 auto;
    text-align: center;


    h1 {
      position: relative;

      @include mq-up(md) {
        &::before {
          position: absolute;
          content: '';
          background-image: url('/images/ticket_left.png');
          width: 205px;
          height: 68px;
          left: -217px;
          bottom: 19px;
        }

        &::after {
          position: absolute;
          content: '';
          background-image: url('/images/ticket_right.png');
          width: 240px;
          height: 62px;
          right: -248px;
          bottom: 19px;
        }
      }
    }
  }


  &__list {

    //display: flex;
    //flex-wrap: wrap;
    width: 100%;
    list-style: none;
    margin-top: 60px;
    text-align: left;

    li {
      width: calc(100%);
      padding: 0;
      margin-bottom: 50px;

      @include mq-up(md) {
        margin-bottom: 100px;
      }

      &:hover {
        opacity: 0.7;
      }

      a {
        color: $color_text_black;
      }

      figure {
        position: relative;
        margin-top: 15px;

        @include mq-up(md) {
          margin-top: 30px;
        }


        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (483 / 1270) * 100%;
        }
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

      }


    }
  }
}